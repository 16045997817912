import * as S from './MemorizedListCell.styles';
import { Todo } from '@lib/common/type';
import { addComma, isSameIndex } from '@lib/pc/production_schedule/functions';
import { SelectedProduct } from '@lib/pc/production_schedule/type';

type Props = {
  index: number;
  p: Todo;
  paramsType: Todo;
  idx: number;
  selectIndex: number;
  last: boolean;
  monthlyDateNum: number;
  selected?: SelectedProduct | boolean | null;
};

const MemorizedListCell = ({
  index,
  p,
  paramsType,
  idx,
  selectIndex,
  last,
  monthlyDateNum,
  selected,
}: Props) => {
  const isToday = isSameIndex(idx, selectIndex, monthlyDateNum);
  return (
    // 各数値セル
    <S.ListCellContainer
      key={`${index}-${idx}-${p.id}`}
      last={last}
      highLighten={isToday}
      invalid={selected ? true : false}
      withCheckEnabled={p.productionSchedules[idx]?.checkEnabled}
    >
      {paramsType?.amount || paramsType?.amount === 0
        ? addComma(paramsType?.amount)
        : ''}
    </S.ListCellContainer>
  );
};
export default MemorizedListCell;
