import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import * as S from './TableWithFixedSidebar.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import TagMenu from '@components/modules/common/TagMenu/TagMenu';
import TagListModal from '@components/modules/common/TagListModal/TagListModal';
import DisplaySelectModal from '@components/modules/common/DisplaySelectModal/DisplaySelectModal';
import ErrorMsgPopUpForStock from '@components/modules/pc/stock_status/ErrorMsgPopUpForStock/ErrorMsgPopUpForStock';
import MemorizedListCell from '@components/modules/pc/production_schedule/MemorizedListCell/MemorizedListCell';
import ProductionScheduleEditable from '@components/modules/pc/production_schedule/ProductionScheduleEditable/ProductionScheduleEditable';
import ProductionScheduleMemorizedListCell from '@components/modules/pc/production_schedule/ProductionScheduleMemorizedListCell/ProductionScheduleMemorizedListCell';
import {
  generateMonthlyDateArray,
  isIncludes,
} from '@lib/pc/production_schedule/functions';
import { containsNonNumeric, replaceContent } from '@lib/common/functions';
import { CircularProgress } from '@material-ui/core';
import { tagSearch } from '@lib/common/functions';
import type {
  Todo,
  BasisForSchedules,
  MonthlyData,
  FunctionType,
} from '@lib/common/type';
import type { SelectedProduct } from '@lib/pc/production_schedule/type';
import { PRODUCT_KIND } from '@lib/common/type';
import { useUpdateProductionScheduleMutation } from '@lib/pc/production_schedule/hooks';

type Props = {
  selectedDate: Todo;
  data?: BasisForSchedules[];
  selected: SelectedProduct | boolean | null;
  selectIndex: number;
  setSelectIndex: (index: number) => void;
  handleSelectedProduct: (
    d: Todo,
    productName: string,
    indexId: string
  ) => void;
  handleOpenProductDetail: (
    id: number,
    name: string,
    safetyStockDays: string
  ) => void;
  directMode: boolean;
  refetch: () => void;
  updateCount: number;
  setUpdateCount: (updateCount: number) => void;
  // setDirectUpdating: (directUpdating: boolean) => void;
};

const TableWithFixedSidebar: FC<Props> = ({
  selectedDate,
  data,
  selected,
  selectIndex,
  setSelectIndex,
  handleSelectedProduct,
  handleOpenProductDetail,
  directMode,
  refetch,
  updateCount,
  setUpdateCount,
}: // setDirectUpdating,
Props) => {
  const [searchWord, setSearchWord] = useState('');
  const [tagMenuActive, setTagMenuActive] = useState(false);
  const [tagListModalActive, setTagListModalActive] = useState(false);
  const [displaySelectModalActive, setDisplaySelectModalActive] =
    useState(false);
  const [selectedTags, setSelectedTags] = useState<Todo>([]);
  const selectedTagIds = selectedTags.map((item: Todo) => item['id']);
  const functionTypeObj = [
    { id: 0, type: '生産計画' },
    { id: 1, type: '生産実績' },
    { id: 2, type: '需要予測' },
    { id: 3, type: '出荷実績' },
    { id: 4, type: '理想在庫' },
    { id: 5, type: '予定在庫' },
    { id: 6, type: '実在庫' },
  ];
  const [selectedTypes, setSelectedTypes] =
    useState<FunctionType[]>(functionTypeObj);
  const selectedTypeIds = selectedTypes.map((item: FunctionType) => item['id']);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const ProductNameArr = data
    ? data.map((p: Todo) => ({
        id: p.id,
        productName: p.name,
        productNameAbbreviation: p.nameAbbreviation,
        productNameKana: p.nameKana,
        productCode: p.productCode,
        tagIds: p.tagIds,
        productSafetyStockDays: p.safetyStockDays,
      }))
    : [];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  const [inputParams, setInputParams] = useState<Todo>({
    actualAmount: '',
    comment: '',
    checkEnabled: false,
  });

  // 更新処理のためのproductId
  const [selectedProductId, setSelectedProductId] = useState(0);
  const [selectedSubmitDate, setSelectedSubmitDate] = useState<Date>();
  const [errMsg, setErrMsg] = useState('');

  // セルで直接変更した時→true
  const [isChanged, setIsChanged] = useState(false);

  // 直接入力モードと表示間で受け渡しする現在のセルの位置管理
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [currentCellIndex, setCurrentCellIndex] = useState(0);

  const handleSuccess = () => {
    setInputParams({
      ...inputParams,
      actualAmount: '',
      comment: '',
      checkEnabled: false,
    });
    setIsChanged(false);
    refetch();
    // setDirectUpdating(false);
  };

  // 更新のmutation
  const updateProductionScheduleMutation = useUpdateProductionScheduleMutation(
    selectedProductId,
    selectedSubmitDate && selectedSubmitDate,
    handleSuccess,
    setErrMsg
  );

  // 直接入力セル更新処理
  const handleUpdate = (inputParams: Todo) => {
    if (isChanged) {
      if (containsNonNumeric(inputParams.actualAmount)) {
        setErrMsg('半角数字のみ入力してください');
      } else {
        // setDirectUpdating(true);
        updateProductionScheduleMutation.mutate(inputParams);
      }
    }
  };

  useEffect(() => {
    setTagMenuActive(false);
  }, [selectedTagIds.length === 0]);

  const filteredProduct = ProductNameArr.filter(
    (p: Todo) =>
      p.productName.includes(searchWord) ||
      p.productNameAbbreviation?.includes(searchWord) ||
      p.productNameKana?.includes(searchWord) ||
      p.productCode?.toString().includes(searchWord)
  ).filter((p: Todo) => tagSearch(p, selectedTagIds));

  return (
    <>
      <div id="whole-wrapper" className="whole-wrapper">
        <S.ListWrapper className="list-wrapper">
          <S.ListSidebar className="list-sidebar">
            <S.SearchArea>
              <S.ListLeftHeadCell
                onClick={() => setTagMenuActive(!tagMenuActive)}
                isBlue={searchWord !== '' || selectedTagIds.length > 0}
              >
                <span className="filter-product">
                  {searchWord !== '' || selectedTagIds.length > 0 ? (
                    <>
                      <FilteredIcon />
                      <div className="filter_text">商品絞込み中</div>
                    </>
                  ) : (
                    <>
                      <Filter />
                      <div className="filter_text">商品の絞込</div>
                    </>
                  )}
                </span>
              </S.ListLeftHeadCell>
              <S.DisplayButton
                onClick={() =>
                  setDisplaySelectModalActive(!displaySelectModalActive)
                }
                isBlue={selectedTypeIds.length !== 7}
              >
                <span className="filter-product">
                  {selectedTypeIds.length !== 7 ? (
                    <>
                      <FilteredIcon />
                      <div className="filter_text">選択中</div>
                    </>
                  ) : (
                    <>
                      <Filter />
                      <div className="filter_text">表示選択</div>
                    </>
                  )}
                </span>
              </S.DisplayButton>
            </S.SearchArea>
            {filteredProduct.map(
              (
                s: {
                  id: number;
                  productName: string;
                  productNameAbbreviation: string;
                  productNameKana: string;
                  productCode: number;
                  productSafetyStockDays: string;
                },
                i: number
              ) => {
                return (
                  // 商品名、商品コード
                  <S.ListLeftHeadProductCell
                    className="list-left-product-cell"
                    key={`${i}-${s.id}`}
                    productIndex={i}
                  >
                    <S.ListTbody>
                      <S.ListLeftHeadNameAndCode
                        key={`${i}-${s.id}-${s.productCode}`}
                        productIndex={i}
                        onClick={() =>
                          handleOpenProductDetail(
                            s.id,
                            s.productNameAbbreviation
                              ? s.productNameAbbreviation
                              : s.productName,
                            !s.productSafetyStockDays
                              ? '3'
                              : String(s.productSafetyStockDays)
                          )
                        }
                      >
                        {/* 商品名 */}
                        <S.ListProductNameAndCode>
                          <S.ListProductName>
                            {replaceContent(
                              s.productNameAbbreviation
                                ? s.productNameAbbreviation
                                : s.productName,
                              12.5
                            )}
                          </S.ListProductName>
                          {/* 商品コード */}
                          {s.productCode}
                        </S.ListProductNameAndCode>
                      </S.ListLeftHeadNameAndCode>
                      <div className="type-name">
                        {functionTypeObj
                          .filter((value) => selectedTypeIds.includes(value.id))
                          .map((value, j) => {
                            // 表示タイプ
                            return (
                              <S.ListLeftHeadTypeName
                                key={`${i}-${j}-${value.id}`}
                                typeIndex={j}
                                productIndex={i}
                                notDisplay={
                                  !selectedTypeIds.includes(value.id)
                                    ? 'true'
                                    : ''
                                }
                              >
                                {value.type}
                              </S.ListLeftHeadTypeName>
                            );
                          })}
                      </div>
                    </S.ListTbody>
                  </S.ListLeftHeadProductCell>
                );
              }
            )}
          </S.ListSidebar>
          <S.ListBody className={selected && !directMode ? 'move-to-left' : ''}>
            <S.ListRow className="list-row">
              {data &&
                generateMonthlyDateArray(
                  data[0].numberOfDaysParamsMonth,
                  selectedDate,
                  selectIndex
                ).map((p, i) => {
                  return (
                    // 月日、合計、前年同月
                    <S.ListHeadCell
                      key={`${i}-${p.productionDate}`}
                      index={i}
                      numOfIndex={data[0].numberOfDaysParamsMonth}
                      highLighten={p.isToday}
                      dayOfWeek={moment(p.productionDate).format('ddd')}
                      onClick={() => {
                        setSelectIndex(i);
                      }}
                    >
                      {i === 0
                        ? moment(p.productionDate).format('M/D(ddd)')
                        : i === data[0].numberOfDaysParamsMonth + 1
                        ? '合計'
                        : i === data[0].numberOfDaysParamsMonth + 2
                        ? '前年同月'
                        : moment(p.productionDate).format('DD(ddd)')}
                    </S.ListHeadCell>
                  );
                })}
            </S.ListRow>
            {data ? (
              data
                .filter(
                  (p: Todo) =>
                    p.name.includes(searchWord) ||
                    p.nameAbbreviation?.includes(searchWord) ||
                    p.nameKana?.includes(searchWord) ||
                    p.productCode?.toString().includes(searchWord)
                )
                .filter((p: Todo) => tagSearch(p, selectedTagIds))
                .map((p: Todo, i: number) => {
                  return (
                    <React.Fragment key={`${i}-${p.productCode}`}>
                      {directMode && !tagMenuActive ? (
                        <S.ListRow key={`${i}-${p.name}`}>
                          {p.productionSchedules.map(
                            (schedule: MonthlyData[], idx: number) => {
                              return (
                                <ProductionScheduleEditable
                                  p={p}
                                  key={`production-schedule-editable-${p.productCode}-${p.name}-${i}-${idx}`}
                                  currentRowIndex={currentRowIndex}
                                  setCurrentRowIndex={setCurrentRowIndex}
                                  currentCellIndex={currentCellIndex}
                                  setCurrentCellIndex={setCurrentCellIndex}
                                  selectIndex={selectIndex}
                                  setSelectedProductId={setSelectedProductId}
                                  setSelectedSubmitDate={setSelectedSubmitDate}
                                  inputParams={schedule}
                                  // setInputParams={setInputParams}
                                  isChanged={isChanged}
                                  setIsChanged={setIsChanged}
                                  handleUpdate={handleUpdate}
                                  setErrMsg={setErrMsg}
                                  updateCount={updateCount}
                                  setUpdateCount={setUpdateCount}
                                  rowIndex={i}
                                  cellIndex={idx}
                                  productLength={filteredProduct.length}
                                  totalTitle={
                                    idx === data[0].numberOfDaysParamsMonth + 1
                                      ? '合計'
                                      : idx ===
                                        data[0].numberOfDaysParamsMonth + 2
                                      ? '前年同月'
                                      : ''
                                  }
                                />
                              );
                            }
                          )}
                        </S.ListRow>
                      ) : (
                        <S.ListRow key={`${i}-${p.id}`}>
                          {p.productionSchedules.map(
                            (schedule: MonthlyData[], idx: number) => {
                              // 生産計画
                              return (
                                <ProductionScheduleMemorizedListCell
                                  key={`${i}-${p.id}-${idx}`}
                                  selected={selected}
                                  rowIndex={i}
                                  p={p}
                                  paramsType={schedule}
                                  cellIndex={idx}
                                  selectIndex={selectIndex}
                                  monthlyDateNum={p.numberOfDaysParamsMonth}
                                  handleSelectedProduct={handleSelectedProduct}
                                  currentRowIndex={currentRowIndex}
                                  setCurrentRowIndex={setCurrentRowIndex}
                                  currentCellIndex={currentCellIndex}
                                  setCurrentCellIndex={setCurrentCellIndex}
                                />
                              );
                            }
                          )}
                        </S.ListRow>
                      )}

                      <S.ListRow
                        notDisplay={!selectedTypeIds.includes(1) ? 'true' : ''}
                      >
                        {selectedTypeIds.includes(1) &&
                          p.productProductionResults.map(
                            (
                              productProductionResult: MonthlyData[],
                              idx: number
                            ) => {
                              return (
                                <MemorizedListCell
                                  key={`${i}-${p.id}-${idx}`}
                                  index={i}
                                  p={p}
                                  paramsType={productProductionResult}
                                  idx={idx}
                                  selectIndex={selectIndex}
                                  last={selectedTypeIds.length === 2}
                                  monthlyDateNum={p.numberOfDaysParamsMonth}
                                />
                              );
                            }
                          )}
                      </S.ListRow>
                      <S.ListRow
                        notDisplay={!selectedTypeIds.includes(2) ? 'true' : ''}
                      >
                        {selectedTypeIds.includes(2) &&
                          p.demandForecasts.map(
                            (demandForecast: MonthlyData[], idx: number) => {
                              return (
                                <MemorizedListCell
                                  key={`${i}-${p.id}-${idx}`}
                                  index={i}
                                  p={p}
                                  paramsType={demandForecast}
                                  idx={idx}
                                  selectIndex={selectIndex}
                                  last={
                                    selectedTypeIds.length === 2 ||
                                    (selectedTypeIds.includes(1) &&
                                      selectedTypeIds.length == 3)
                                  }
                                  monthlyDateNum={p.numberOfDaysParamsMonth}
                                />
                              );
                            }
                          )}
                      </S.ListRow>
                      <S.ListRow
                        notDisplay={!selectedTypeIds.includes(3) ? 'true' : ''}
                      >
                        {selectedTypeIds.includes(3) &&
                          p.shippings.map(
                            (shipping: MonthlyData[], idx: number) => {
                              return (
                                <MemorizedListCell
                                  key={`${i}-${p.id}-${idx}`}
                                  index={i}
                                  p={p}
                                  paramsType={shipping}
                                  idx={idx}
                                  selectIndex={selectIndex}
                                  last={
                                    selectedTypeIds.length === 2 ||
                                    (selectedTypeIds.every(
                                      (v: number) => v <= 3
                                    ) &&
                                      !selectedTypeIds.some(
                                        (v: number) => v >= 4
                                      ) &&
                                      selectedTypeIds.length == 3) ||
                                    !isIncludes([4, 5, 6], selectedTypeIds)
                                  }
                                  monthlyDateNum={p.numberOfDaysParamsMonth}
                                />
                              );
                            }
                          )}
                      </S.ListRow>
                      <S.ListRow
                        notDisplay={!selectedTypeIds.includes(4) ? 'true' : ''}
                      >
                        {selectedTypeIds.includes(4) &&
                          p.safetyStocks.map(
                            (safetyStock: MonthlyData[], idx: number) => {
                              return (
                                <MemorizedListCell
                                  key={`${i}-${p.id}-${idx}`}
                                  index={i}
                                  p={p}
                                  paramsType={safetyStock}
                                  idx={idx}
                                  selectIndex={selectIndex}
                                  last={
                                    selectedTypeIds.length === 2 ||
                                    !isIncludes([5, 6], selectedTypeIds)
                                  }
                                  monthlyDateNum={p.numberOfDaysParamsMonth}
                                />
                              );
                            }
                          )}
                      </S.ListRow>
                      <S.ListRow
                        notDisplay={!selectedTypeIds.includes(5) ? 'true' : ''}
                      >
                        {selectedTypeIds.includes(5) &&
                          p.estimatedProductStocks.map(
                            (
                              estimatedProductStock: MonthlyData[],
                              idx: number
                            ) => {
                              return (
                                <MemorizedListCell
                                  key={`${i}-${p.id}-${idx}`}
                                  index={i}
                                  p={p}
                                  paramsType={estimatedProductStock}
                                  idx={idx}
                                  selectIndex={selectIndex}
                                  last={
                                    selectedTypeIds.length === 2 ||
                                    !selectedTypeIds.includes(6)
                                  }
                                  monthlyDateNum={p.numberOfDaysParamsMonth}
                                />
                              );
                            }
                          )}
                      </S.ListRow>
                      <S.ListRow
                        notDisplay={!selectedTypeIds.includes(6) ? 'true' : ''}
                      >
                        {selectedTypeIds.includes(6) &&
                          p.actualProductStocks.map(
                            (
                              actualProductStock: MonthlyData[],
                              idx: number
                            ) => {
                              return (
                                <MemorizedListCell
                                  key={`${i}-${p.id}-${idx}`}
                                  index={i}
                                  p={p}
                                  paramsType={actualProductStock}
                                  idx={idx}
                                  selectIndex={selectIndex}
                                  last={true}
                                  monthlyDateNum={p.numberOfDaysParamsMonth}
                                />
                              );
                            }
                          )}
                      </S.ListRow>
                    </React.Fragment>
                  );
                })
            ) : (
              <S.CircularIconWrapper>
                <CircularProgress style={{ color: '#64b2f9' }} />
              </S.CircularIconWrapper>
            )}
          </S.ListBody>
        </S.ListWrapper>
      </div>
      {tagMenuActive && (
        <TagMenu
          searchParam={searchWord}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          setOpenTagModal={() => setTagListModalActive(true)}
          handleChange={handleSearch}
          deleteSearchParam={() => setSearchWord('')}
        />
      )}
      {tagListModalActive && (
        <TagListModal
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          tagListModalActive={tagListModalActive}
          handleClose={() => {
            setTagListModalActive(false);
            setTagMenuActive(false);
          }}
          productKind={PRODUCT_KIND.PRODUCT}
        />
      )}
      {displaySelectModalActive && (
        <DisplaySelectModal
          notSelectableObj={'生産計画'}
          selectedTypes={selectedTypes}
          functionTypeObj={functionTypeObj}
          setSelectedTypes={(type) => setSelectedTypes(type)}
          displaySelectModalActive={displaySelectModalActive}
          handleClose={() => setDisplaySelectModalActive(false)}
        />
      )}
      {tagMenuActive && (
        <Overlay
          zIndex={9997}
          handleClick={() => {
            setTagMenuActive(false);
          }}
        />
      )}
      <ErrorMsgPopUpForStock
        errMsg={errMsg}
        handleClose={() => setErrMsg('')}
        fromPc={true}
        width={'20rem'}
      />
    </>
  );
};

export default TableWithFixedSidebar;
