import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import * as S from './MaterialForm.styles';
import { Button } from '@components/elements/buttons/Button';
import InputComponent from '@components/modules/pc/materials_inventory/InputComponent/InputComponent';
import MobileDateSelector from '@components/elements/inputs/DateSelector/MobileDateSelector';
import { InputParams } from '@lib/pc/stock_status/type';
import type { Todo } from '@lib/common/type';
import { MaterialStocks } from '@lib/mobile/materials_stocks_edit/type';

type Props = {
  material: MaterialStocks;
  inputParams: InputParams;
  selectedDate: Date;
  count: number;
  setInputParams: (InputParams: InputParams) => void;
  amountDetail: Todo;
  newCalcTotal: (
    index: number,
    value: string | number,
    valueKind: 'piece' | 'unit' | 'case'
  ) => void;
  changeNewDate: (index: number, value: Todo) => void;
  handleDelete: () => void;
  deleteId: number | null;
  setDeleteId: (deleteId: number | null) => void;
  getBeforeDate: (selectedDate: Date) => void;
  getNextDate: (selectedDate: Date) => void;
  setIsChanged: (isChanged: boolean) => void;
  comment: string;
  setComment: (comment: string) => void;
  setPopUp: (popUp: boolean) => void;
};

const MaterialsForm = ({
  material,
  inputParams,
  selectedDate,
  count,
  getBeforeDate,
  getNextDate,
  setInputParams,
  amountDetail,
  newCalcTotal,
  changeNewDate,
  handleDelete,
  deleteId,
  setDeleteId,
  setIsChanged,
  comment,
  setComment,
  setPopUp,
}: Props) => {
  const handleComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputParams({ ...inputParams, comment: e.target.value });
    setComment(e.target.value);
    setIsChanged(true);
  };

  // ケースと単位の値の更新
  const handleNewOnChange = (
    i: number,
    value: string,
    valueKind: 'unit' | 'case'
  ) => {
    if (value.match(/^[0-9]*$/)) {
      newCalcTotal(i, Number(value), valueKind);
      setIsChanged(true);
    }
  };

  // バラの値の更新
  const handleNewOnChangePieceAmount = (
    i: number,
    value: string,
    valueKind: 'piece'
  ) => {
    if (value.match(/^\d*\.?\d{0,1}$/)) {
      newCalcTotal(i, value, valueKind);
      setIsChanged(true);
    }
  };

  // 使用期限日の更新
  const handleChangeNewDate = (i: number, value: Date) => {
    changeNewDate(i, value);
    setIsChanged(true);
  };

  const onClickDelete = () => {
    handleDelete();
    setDeleteId(null);
  };

  const handleDeleteButton = (i: number, amountDetail: Todo) => {
    setPopUp(false);
    if (amountDetail[i].id) {
      // 登録済みデータの場合
      setDeleteId(amountDetail[i].id);
      setIsChanged(true);
    } else {
      // 未登録のデータの場合、増やしたinputコンポーネントの削除
      const filteredAmountDetail = amountDetail.filter(
        (item: Todo) => item !== amountDetail[i]
      );
      setInputParams({ ...inputParams, amountDetail: filteredAmountDetail });
    }
  };

  return (
    <S.Wrapper>
      <S.HeadContainer id="top">
        <MobileDateSelector
          date={selectedDate}
          count={count}
          getBeforeDate={getBeforeDate}
          getNextDate={getNextDate}
        />
      </S.HeadContainer>
      <S.InputContainer>
        {amountDetail &&
          Object.values(amountDetail)
            .map((item: Todo, i: number) => {
              return (
                <InputComponent
                  key={`item-${i}`}
                  index={i}
                  labelOfAmount={material.labelOfAmount}
                  labelOfUnit={material.labelOfUnit}
                  newExpirationDate={item.expirationDate}
                  onChangePieceAmount={(value) =>
                    handleNewOnChangePieceAmount(i, value, 'piece')
                  }
                  onChangeUnitAmount={(value) =>
                    handleNewOnChange(i, value, 'unit')
                  }
                  onChangeCaseAmount={(value) =>
                    handleNewOnChange(i, value, 'case')
                  }
                  onChangeNewDate={(value) => handleChangeNewDate(i, value)}
                  inputAmountParams={amountDetail}
                  handleRemoveField={handleDeleteButton}
                  piecesPerBox={
                    material.piecesPerBox ? material.piecesPerBox : 1
                  }
                  piecesPerUnit={
                    material.piecesPerUnit ? material.piecesPerUnit : 1
                  }
                />
              );
            })
            .reverse()}
      </S.InputContainer>
      <S.NoteAmountContainer>
        <S.NoteAmountLabel>備考</S.NoteAmountLabel>
        <S.NoteAmountInput
          placeholder=""
          value={amountDetail?.length > 0 && comment ? comment : ''}
          onChange={handleComment}
        />
      </S.NoteAmountContainer>
      {deleteId && (
        <S.DeleteModalWrapper>
          <S.DeleteModalTextContainer>
            <span>削除後は元に戻すことが出来ません。</span>
            <span>本当に削除しますか？</span>
          </S.DeleteModalTextContainer>
          <S.DeleteModalButtonContainer>
            <Button
              padding="sm"
              outlined
              onClick={() => {
                setDeleteId(null);
              }}
            >
              キャンセル
            </Button>
            <Button
              padding="sm"
              icon={<DeleteIcon />}
              outlined
              onClick={() => {
                onClickDelete();
              }}
            >
              削除
            </Button>
          </S.DeleteModalButtonContainer>
        </S.DeleteModalWrapper>
      )}
    </S.Wrapper>
  );
};
export default MaterialsForm;
