import { useCallback, useState, useMemo } from 'react';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { cyan } from '@material-ui/core/colors';
import * as S from './StockStatus.styles';
import { Heading } from '@components/elements/typographies/Heading';
import { Button } from '@components/elements/buttons/Button';
import DateSelector from '@components/elements/inputs/DateSelector/DateSelector';
import RightSlideSidebar from '@components/modules/pc/stock_status/RightSlideSidebar/RightSlideSidebar';
import TableWithFixedSidebar from '@components/modules/pc/stock_status/TableWithFixedSidebar/TableWithFixedSidebar';
import { WithHeader } from '@components/templates/WithHeader';
import ProductDetailModal from '@components/modules/pc/stock_status/ProductDetailModal/ProductDetailModal';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import StoreroomSelect from '@components/modules/pc/stock_status/StoreroomSelect/StoreroomSelect';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import AllStoreroomsRightSlideSidebar from '@components/modules/pc/stock_status/AllStoreroomsRightSlideSidebar/AllStoreroomsRightSlideSidebar';
import { useStocks } from '@lib/pc/stock_status/hooks';
import useCases from '@lib/pc/common/hooks/useCases';
import useStorerooms from '@lib/pc/common/hooks/useStorerooms';
import type { DetailItem, selectedItem } from '@lib/pc/stock_status/type';
import { type Case, type Todo, MATERIAL_PRODUCT_KIND } from '@lib/common/type';
import { getTodayForInput } from '@lib/common/functions';

export const StockStatusRoot = () => {
  const initializeSelectedItem = {
    ExpiryDays: '',
    comment: '',
    data: [],
    date: '',
    piecesPerBox: 1,
    productId: '',
    productName: '',
    productKind: '',
    stocks: [],
    estimatedStocks: [],
    type: '',
    // casesPerCart: '',
    stockDateLabel: '',
    labelOfAmount: '',
    safetyStockDays: '',
  };

  const today = new Date();
  const [date, setDate] = useState(new Date());
  const [initialize] = useState<Todo>(initializeSelectedItem);
  const [selectedItem, setSelectedItem] = useState<selectedItem>(initialize);
  const [selectedEditItem, setSelectedEditItem] =
    useState<selectedItem>(initialize);
  const [popUp, setPopUp] = useState(false);
  const [rightSlideSidebarActive, setRightSlideSidebarActive] = useState(false);
  const [allRightSlideSidebarActive, setAllRightSlideSidebarActive] =
    useState(false);
  const [productDetailModalActive, setProductDetailModalActive] =
    useState(false);
  const [selectedStoreroomId, setSelectedStoreroomId] = useState('0');
  const [errMsg, setErrMsg] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [messageKind, setMessageKind] = useState('');
  const [viewEstimated, setViewEstimated] = useState(false);
  const [caseUnitDisplay, setCaseUnitDisplay] = useState(false);

  // ケースの初期値設定が必要
  const [needToSetInitCase, setNeedToSetInitCase] = useState(false);
  const [inputAmountParams, setInputAmountParams] = useState<Todo>([]);
  const [comment, setComment] = useState('');
  // SidebarInputの入力フォーム（日付、ケース、バラ）、備考への変更有無
  const [isChanged, setIsChanged] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('');
  const [selectedCasePiecesPerCase, setSelectedCasePiecesPerCase] = useState(1);

  const formattedDate = selectedEditItem
    ? moment(selectedEditItem.date).format('YYYY/MM/DD (ddd)')
    : '';
  const productName =
    selectedEditItem && selectedEditItem.productName
      ? selectedEditItem.productName
      : '';

  const { stocksInProducts, refetch } = useStocks(
    moment(date).toDate(),
    selectedStoreroomId
  );
  const { data: storerooms } = useStorerooms(
    MATERIAL_PRODUCT_KIND.PRODUCT_AND_SEMI_PRODUCT
  );
  const { data: cases } = useCases();

  const [filteredProductCases, setFilteredProductCases] = useState<Case[]>([]);
  const [initializeAmount, setInitializeAmount] = useState<DetailItem>({
    amountDetailId: '',
    bara: '',
    case: '',
    total: '',
    caseId: 0,
    piecesPerBox: 1,
    productionOrShippingDate: '',
  });

  const filtered = useMemo(() => {
    if (!stocksInProducts) return;
    if (selectedStoreroomId === '0') {
      return stocksInProducts;
    } else if (selectedStoreroomId !== '') {
      return stocksInProducts.filter((a) =>
        a.storeroomIds?.includes(Number(selectedStoreroomId))
      );
    }
  }, [selectedStoreroomId, stocksInProducts]);

  const handleOpenProductDetail = useCallback((item: Todo) => {
    setSelectedItem(item);
    setProductDetailModalActive(!productDetailModalActive);
  }, []);

  const handleSelectedProduct = async (item: Todo) => {
    // 選択したセルのデータ有無を確認（実在庫か予定在庫のどちらか）
    const filteredStock = item.stocks.find((stock: Todo) =>
      moment(item.date).isSame(stock.date, 'day')
    );
    const filteredEstimatedStock = item.estimatedStocks.find((stock: Todo) =>
      moment(item.date).isSame(stock.date, 'day')
    );

    if (
      (selectedStoreroomId === '0' &&
        filteredStock.amountDetail[0].total !== '') ||
      (selectedStoreroomId === '0' &&
        filteredEstimatedStock.amount === 0 &&
        viewEstimated) ||
      (selectedStoreroomId === '0' &&
        !!filteredEstimatedStock.amount &&
        viewEstimated)
    ) {
      setSelectedEditItem(item);
      setAllRightSlideSidebarActive(!allRightSlideSidebarActive);
    } else if (selectedStoreroomId !== '0') {
      setSelectedEditItem(item);
      // 完成品または半製品のの該当商品ケース
      const filteredProductCases = await cases?.filter(
        (c) =>
          c.productId === item?.productId && c.productKind === item?.productKind
      );
      filteredProductCases && setFilteredProductCases(filteredProductCases);
      filteredProductCases &&
        setInitializeAmount({
          ...initializeAmount,
          caseId: filteredProductCases[0]?.caseId
            ? filteredProductCases[0]?.caseId
            : 0,
        });
      setRightSlideSidebarActive(!rightSlideSidebarActive);
      setPopUp(false);
    }
  };

  const handleClosePopUp = () => {
    setPopUp(false);
  };

  const handleCheckboxChange = () => {
    setViewEstimated((prevViewEstimated) => !prevViewEstimated);
  };

  const handleCheckCaseDisplay = () => {
    setCaseUnitDisplay(!caseUnitDisplay);
  };

  const onClickCancel = () => {
    if (isChanged) {
      setPopUp(false);
      setConfirmMsg('保存せずに編集を終了します。よろしいですか。');
    } else {
      setInputAmountParams([]);
      setRightSlideSidebarActive(false);
      setPopUp(false);
      setNeedToSetInitCase(false);
      // 下記で0を設定すると、ケースデータがないデータの場合エラー発生
      setSelectedCasePiecesPerCase(1);
      setComment('');
    }
  };

  // 1日戻る
  const getBeforeDate = (date: Date) => {
    // setDate(date.subtract(1, 'days').toDate());
    setDate(moment(date).subtract(1, 'days').toDate());
    // setSevenDaysBefore(moment(date).subtract(1, 'days').toDate());
    setPopUp(false);
  };

  // 1日進む
  const getNextDate = (date: Date) => {
    // 未来日も選択可能
    setDate(moment(date).add(1, 'days').toDate());
    // setSevenDaysBefore(moment(date).add(1, 'days').toDate());
    setPopUp(false);
  };

  const handleClickToday = () => {
    getTodayForInput(date, today, setDate, refetch);
    // 1週間前日付の取得
    // setSevenDaysBefore(moment(date).subtract(7, 'days').toDate());
  };

  const theme = createTheme({
    palette: {
      primary: cyan,
    },
  });

  // TODO:どこかにまとめる
  const CyanCheckbox = () => {
    return (
      <ThemeProvider theme={theme}>
        <div style={{ height: '30px' }}>
          <Checkbox
            id="checkboxId"
            color="primary"
            checked={viewEstimated}
            onChange={handleCheckboxChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <S.Label htmlFor="checkboxId">予定在庫の表示</S.Label>
        </div>
      </ThemeProvider>
    );
  };

  // ケース数の表示
  const CaseCheckbox = () => {
    return (
      <ThemeProvider theme={theme}>
        <div>
          <Checkbox
            id="caseUnitDisplayId"
            color="primary"
            checked={caseUnitDisplay}
            onChange={handleCheckCaseDisplay}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <S.Label htmlFor="caseUnitDisplayId">ケース数の表示</S.Label>
        </div>
      </ThemeProvider>
    );
  };

  const handleOkAllStoreRooms = () => {
    setAllRightSlideSidebarActive(false);
    setSelectedEditItem(initialize);
  };

  return (
    <WithHeader>
      <S.Wrapper>
        <S.HeadContainer>
          <div>
            <Heading tag="span" text="在庫状況" fs="32" fw="lg" />
            <Heading tag="span" text="を確認" fs="24" fw="lg" />
          </div>
          <S.DisplayOptionWrapper>
            <S.CheckboxContainer>
              {/* 予定在庫の表示 */}
              <CyanCheckbox />
              {/* ケース数の表示 */}
              <CaseCheckbox />
            </S.CheckboxContainer>
            <S.StoreroomSelectWrapper>
              <StoreroomSelect
                storerooms={storerooms}
                value={Number(selectedStoreroomId)}
                setSelectedStoreroomId={setSelectedStoreroomId}
                rightSlideSidebarActive={rightSlideSidebarActive}
              />
            </S.StoreroomSelectWrapper>
            <S.DateWrapper onClick={() => setPopUp(false)}>
              <Button
                width="90px"
                margin={'0 8px 0 0'}
                outlined={true}
                borderWidth={1}
                onClick={handleClickToday}
              >
                今日
              </Button>
              <DateSelector
                date={date}
                getBeforeDate={getBeforeDate}
                getNextDate={getNextDate}
                setDate={setDate}
                capableSelectFutureDate
              />
            </S.DateWrapper>
          </S.DisplayOptionWrapper>
        </S.HeadContainer>

        <TableWithFixedSidebar
          selectedDate={moment(date)}
          data={filtered}
          selected={false}
          handleSelectedProduct={handleSelectedProduct}
          handleOpenProductDetail={handleOpenProductDetail}
          rightSlideSidebarActive={rightSlideSidebarActive}
          viewEstimated={viewEstimated}
          caseUnitDisplay={caseUnitDisplay}
          onClickCancel={onClickCancel}
        />
        <AllStoreroomsRightSlideSidebar
          rightSlideSidebarActive={allRightSlideSidebarActive}
          selectedProduct={selectedEditItem}
          handleOkAllStoreRooms={handleOkAllStoreRooms}
        />
        {rightSlideSidebarActive && (
          <RightSlideSidebar
            stocksInProducts={stocksInProducts}
            rightSlideSidebarActive={rightSlideSidebarActive}
            setRightSlideSidebarActive={setRightSlideSidebarActive}
            selectedProduct={selectedEditItem}
            selectedStoreroomId={selectedStoreroomId}
            initializeAmount={initializeAmount}
            filteredProductCases={filteredProductCases}
            popUp={popUp}
            setPopUp={setPopUp}
            refetch={refetch}
            setIsDelete={setIsDelete}
            setMessageKind={setMessageKind}
            needToSetInitCase={needToSetInitCase}
            setNeedToSetInitCase={setNeedToSetInitCase}
            inputAmountParams={inputAmountParams}
            setInputAmountParams={setInputAmountParams}
            comment={comment}
            setComment={setComment}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            confirmMsg={confirmMsg}
            setConfirmMsg={setConfirmMsg}
            selectedCasePiecesPerCase={selectedCasePiecesPerCase}
            setSelectedCasePiecesPerCase={setSelectedCasePiecesPerCase}
            onClickCancel={onClickCancel}
          />
        )}
      </S.Wrapper>
      <ProductDetailModal
        productDetailModalActive={productDetailModalActive}
        selectedDate={moment(date)}
        data={selectedItem}
        handleClose={() => {
          setProductDetailModalActive(false);
          setSelectedItem(initialize);
        }}
      />
      <UpdatePopUp
        popUp={popUp}
        formattedDate={formattedDate}
        productName={productName}
        handleClose={handleClosePopUp}
        fromPc={true}
        taskKind={'stock'}
        messageKind={messageKind}
        isDelete={isDelete}
      />
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => setErrMsg('')}
        fromPc={true}
      />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </WithHeader>
  );
};
