import { FC } from 'react';
import moment from 'moment';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
} from 'victory';
import { DividerWithDot } from '@components/elements/dividers/DividerWithDot';
import { DividerWithSquare } from '@components/elements/dividers/DividerWithSquare/DividerWithSquare';
import { ModalPaper } from '@components/modules/common/pc/ModalPaper';
import { addComma } from '@lib/pc/production_schedule/functions';
import {
  convertStockEstimatedArrToBarData,
  convertSafetyStocksArrToBarData,
  convertActualOrSafetyStocksArrToBarData,
  convertActualOrSafetyStockBarData,
  findMaxAmount,
} from '@lib/pc/materials_inventory/functions';
import * as S from './Graph.styles';
import { CircularProgress } from '@material-ui/core';
import type { Todo } from '@lib/common/type';
import { generateDateArray } from '@lib/pc/materials_inventory/functions';

type Props = {
  data: Todo;
  selectedDate: moment.Moment;
};

/*  COMMENT(220314):生産計画の根拠グラフ */
const MaterialsInventoryGraphWithTable: FC<Props> = ({
  selectedDate,
  data,
}: Props) => {
  // グラフ左のY軸の最大値を求める
  const maxYCoordinate = findMaxAmount(data);

  return (
    <ModalPaper heading1="資材・原材料在庫状況の分析">
      {data ? (
        <S.GraphContainer>
          <S.LeftHeadContainer>
            <S.LeftLabelContainer>
              <S.LeftGraphLabelContainer>
                <S.GraphLeftLabelContainer>
                  <div>
                    <S.ColorBox bgColor={'#ABD5F3'} />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div>実在在庫数</div>
                    <span>倉庫にある資材・原材料の実数</span>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer>

                <S.GraphLeftLabelContainer>
                  <div>
                    <S.ColorBox bgColor={'#F6C1B1'} />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div>予定在庫数 </div>
                    <span>生産計画通りに生産したら、この在庫数になる予定</span>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer>
                <S.GraphLeftLabelContainer>
                  <div>
                    <DividerWithDot borderColor="#0A68F4" />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div>納品済数</div>
                    <span>納品された数量</span>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer>

                <S.GraphLeftLabelContainer>
                  <div>
                    <DividerWithSquare borderColor="#AF28C5" />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div>納品予定数</div>
                    <span>納品予定の数量</span>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer>
              </S.LeftGraphLabelContainer>
              <div>
                <VictoryChart
                  domainPadding={{ x: 50 }}
                  width={120}
                  height={320}
                  padding={{ top: 50, bottom: 0, right: 0, left: 0 }}
                  containerComponent={<VictoryContainer responsive={false} />}
                >
                  <VictoryAxis
                    width={130}
                    height={400}
                    dependentAxis
                    offsetX={120}
                    standalone
                    style={{
                      axisLabel: {
                        fontSize: 16,
                        padding: 30,
                        zIndex: 100,
                      },
                    }}
                  />
                  <VictoryGroup
                    groupComponent={<g display="none" />}
                    offset={20}
                  >
                    <VictoryLine
                      style={{ data: { display: 'none' } }}
                      data={convertSafetyStocksArrToBarData(data.actualStocks)}
                    />
                    {/* グラフ左のY軸の値 */}
                    <VictoryBar
                      barWidth={16}
                      data={convertActualOrSafetyStocksArrToBarData(
                        data.actualStocks,
                        maxYCoordinate
                      )}
                      style={{ data: { display: 'none' } }}
                    />
                  </VictoryGroup>
                </VictoryChart>
              </div>
            </S.LeftLabelContainer>

            <div>
              <S.LeftTableEmptyCell />
              <S.LeftTableHeadCell marginTop bg={'#ABD5F3'}>
                <span> 実在在庫数</span>
              </S.LeftTableHeadCell>
              <S.LeftTableHeadCell bg={'#F6C1B1'}>
                <span> 予定在庫数</span>
              </S.LeftTableHeadCell>
              <S.LeftTableHeadCell bg="#F8F7F4">
                <S.GraphLeftLabelContainer>
                  <div>
                    <DividerWithDot borderColor="#0A68F4" />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div> 納品済数</div>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer>
              </S.LeftTableHeadCell>
              <S.LeftTableHeadCell bg="#F8F7F4">
                <S.GraphLeftLabelContainer>
                  <div>
                    <DividerWithSquare borderColor="#AF28C5" />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div>納品予定数</div>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer>
              </S.LeftTableHeadCell>
            </div>
          </S.LeftHeadContainer>
          {/* グラフ */}
          <S.ScrollableContainer>
            {/* COMMENT:交互背景は時間かかりそう
      https://github.com/FormidableLabs/victory/issues/1601 */}
            <VictoryChart
              domainPadding={{ x: 73 }}
              // domainPadding={{ x: 44 }}
              width={1095}
              height={320}
              padding={{ top: 50, bottom: 0, right: 0, left: 35 }}
              containerComponent={<VictoryContainer responsive={false} />}
            >
              <VictoryAxis
                // dependentAxis
                //x
                style={{
                  axis: { stroke: '#F8F7F4' },
                  ticks: { stroke: 'white' },
                  tickLabels: {
                    display: 'none',
                  },
                }}
              />
              <VictoryAxis
                dependentAxis
                //y
                style={{
                  axis: { display: 'none' },
                  grid: { stroke: '#E7E2DD' },
                  tickLabels: {
                    fontSize: 0,
                    padding: 0,
                    stroke: '#E7E2DD',
                  },
                }}
              />

              <VictoryGroup offset={20}>
                {/* 実在庫数 */}
                <VictoryBar
                  style={{
                    data: {
                      fill: '#ABD5F3',
                      stroke: '#728DA1',
                      strokeWidth: 1,
                    },
                  }}
                  barWidth={16}
                  data={convertActualOrSafetyStockBarData(data.actualStocks)}
                />
                {/* 予定在庫数 */}
                <VictoryBar
                  style={{
                    data: {
                      fill: '#F6C1B1',
                      stroke: '#B68A7C',
                      strokeWidth: 1,
                    },
                  }}
                  barWidth={16}
                  data={convertStockEstimatedArrToBarData(data.estimatedStocks)}
                />
              </VictoryGroup>

              {/* 納品済数 */}
              <VictoryGroup
                data={convertSafetyStocksArrToBarData(data.actualDeliveries)}
              >
                <VictoryLine
                  style={{
                    data: {
                      stroke: '#0A68F4',
                      strokeDasharray: '8, 4',
                      zIndex: 100,
                    },
                  }}
                />
                <VictoryScatter
                  size={3}
                  style={{
                    data: {
                      fill: '#ffffff',
                      stroke: '#0A68F4',
                      strokeWidth: 3,
                    },
                  }}
                />
              </VictoryGroup>
              {/* 納品予定数 */}
              <VictoryGroup
                data={convertSafetyStocksArrToBarData(data.estimatedDeliveries)}
              >
                <VictoryLine
                  style={{
                    data: {
                      stroke: '#AF28C5',
                      strokeDasharray: '8, 4',
                      zIndex: 100,
                    },
                  }}
                />
                <VictoryScatter
                  size={3}
                  symbol="square"
                  style={{
                    data: {
                      fill: '#ffffff',
                      stroke: '#AF28C5',
                      strokeWidth: 3,
                    },
                  }}
                />
              </VictoryGroup>
            </VictoryChart>

            {/* 値のテーブル */}
            <S.TableWrapper>
              <S.TableRow>
                {generateDateArray(15, selectedDate).map(
                  (day: Todo, i: Todo) => {
                    return (
                      <S.TableHeadCell
                        key={i}
                        isToday={i === 7}
                        dayOfWeek={moment(day.productionDate).format('ddd')}
                      >
                        {i === 0 ? (
                          <div>
                            {moment(day.productionDate).format('MM/DD')}
                            <span>
                              {moment(day.productionDate).format('(ddd)')}
                            </span>
                          </div>
                        ) : (
                          <div>
                            {moment(day.productionDate).format('DD')}
                            <span>
                              {moment(day.productionDate).format('(ddd)')}
                            </span>
                          </div>
                        )}
                      </S.TableHeadCell>
                    );
                  }
                )}
              </S.TableRow>
              {/* 実在在庫 */}
              <S.TableRow>
                {data.actualStocks.map((p: Todo, i: number) => {
                  return (
                    <S.TableCell color={'#0A68F4'} key={i} isToday={i === 7}>
                      {p.amount ? addComma(p.amount) : '-'}
                    </S.TableCell>
                  );
                })}
              </S.TableRow>
              {/* 予定在庫 */}
              <S.TableRow>
                {data.estimatedStocks.map((p: Todo, i: number) => {
                  return (
                    <S.TableCell color={'#CE7D64'} key={i} isToday={i === 7}>
                      {addComma(p.amount) || '-'}
                    </S.TableCell>
                  );
                })}
              </S.TableRow>
              {/* 納品済数 */}
              <S.TableRow>
                {data.actualDeliveries.map((p: Todo, i: number) => {
                  return (
                    <S.TableCell color={'#3387C5'} key={i} isToday={i === 7}>
                      {addComma(p.amount) || '-'}
                    </S.TableCell>
                  );
                })}
              </S.TableRow>
              {/* 納品予定数 */}
              <S.TableRow>
                {data.estimatedDeliveries.map((p: Todo, i: number) => {
                  return (
                    <S.TableCell
                      color={'#3387C5'}
                      key={i}
                      isToday={i === 7}
                      marginTop
                    >
                      {addComma(p.amount) || '-'}
                    </S.TableCell>
                  );
                })}
              </S.TableRow>
            </S.TableWrapper>
          </S.ScrollableContainer>
        </S.GraphContainer>
      ) : (
        <S.GraphContainer>
          <S.CircularIconWrapper top={'83rem'}>
            <CircularProgress style={{ color: '#64b2f9' }} />
          </S.CircularIconWrapper>
        </S.GraphContainer>
      )}
    </ModalPaper>
  );
};

export default MaterialsInventoryGraphWithTable;
