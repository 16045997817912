import { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import type { Product, Todo } from '@lib/common/type';

export const tagSearch = (p: Product, selectedTagIds: number[]) => {
  // or検索
  const orIncludeTags = new Set(
    [...p.tagIds, ...selectedTagIds].filter(
      (item) => p.tagIds.includes(item) && selectedTagIds.includes(item)
    )
  );
  if (selectedTagIds.length === 0) {
    return p;
  } else if (orIncludeTags.size > 0) {
    return p;
  }
};

export const replaceContent = (string: string, length: number) => {
  const maxLength = length;
  let allLength = 0;

  for (let i = 0; i < string.length; i++) {
    if (string[i].match(/[ -~]/)) {
      allLength += 0.5;
    } else {
      allLength += 1;
    }
  }

  if (allLength > maxLength) {
    return string.substring(0, maxLength) + '...';
  }
  return string;
};

// ReactSelectでスペースがある場合表示が崩れるのでスペースを削除
export const removeSpaces = (str: string): string => {
  return str.replace(/\s/g, '');
};

export const removeLastItem = (array: Todo[]) => {
  if (array.length >= 1) {
    return array.slice(0, -1);
  }
};

export const getTodayForInput = (
  date: Date,
  today: Date,
  setDate: (value: Date) => void,
  refetch: () => void
) => {
  if (
    moment(date).format('YYYY-MM-DD') === moment(today).format('YYYY-MM-DD')
  ) {
    refetch();
  } else {
    setDate(new Date());
  }
  if (navigator.serviceWorker) {
    navigator.serviceWorker
      .getRegistrations()
      .then((registrations) => registrations.forEach((reg) => reg.update()));
  }
};

export const getTodayForReference = (
  selectedMonth: string,
  currentMonth: string,
  setDate: (value: Moment) => void,
  setSelectIndex: (value: number) => void,
  refetch: () => void
) => {
  setSelectIndex(new Date().getDate() - 1);
  if (currentMonth === selectedMonth) {
    refetch();
  } else {
    setDate(moment());
  }
  if (navigator.serviceWorker) {
    navigator.serviceWorker
      .getRegistrations()
      .then((registrations) => registrations.forEach((reg) => reg.update()));
  }
};

export const containsNonNumeric = (input: string) => {
  // 半角数字以外の文字が含まれているか確認
  return /[^\d]/.test(input);
};

export const addComma = (val: number | null) => {
  if (val === null) return;

  return ('' + val).replace(/(\d)(?=(\d\d\d)+$)/g, '$1,');
};

// デバイスが縦向きか横向きか、タブレットかモバイルかの判定
export const useDeviceOrientationAndSize = () => {
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia('(orientation: portrait)').matches
  );
  const [deviceType, setDeviceType] = useState('desktop');

  useEffect(() => {
    const checkDeviceType = () => {
      const screenWidth = screen.width;

      // 返り値は 'mobile', 'tablet', 'desktop'のいずれの文字列
      if (screenWidth < 768) {
        setDeviceType('mobile');
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        setDeviceType('tablet');
      } else {
        setDeviceType('desktop');
      }
    };

    const checkOrientation = () => {
      setIsPortrait(window.matchMedia('(orientation: portrait)').matches);
    };

    checkDeviceType();
    window.addEventListener('resize', checkDeviceType);
    window.addEventListener('orientationchange', checkOrientation);

    return () => {
      window.removeEventListener('resize', checkDeviceType);
      window.removeEventListener('orientationchange', checkOrientation);
    };
  }, []);

  return { isPortrait, deviceType };
};

// userAgentからiPhoneかどうかを判定
export const isIPhone = () => {
  const userAgent = navigator.userAgent;
  return /iPhone/.test(userAgent);
};
