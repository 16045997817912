import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as S from './DeliverStatusInputWrapper.styles';
import { ReactComponent as AddIcon } from '@assets/icons/add.svg';
import DeliveryStatusInputComponent from '@components/modules/pc/purchase_orders/DeliveryStatusInputComponent/DeliveryStatusInputComponent';
import {
  DeliveredDetail,
  PurchaseOrderState,
} from '@lib/pc/purchase_orders/type';
import { Todo } from '@lib/common/type';
import useCreateMaterialDeliveriesMutation from '@lib/pc/purchase_orders/useCreateMaterialDeliveriesMutation';
import useUpdateMaterialDeliveriesMutation from '@lib/pc/purchase_orders/useUpdateMaterialDeliveriesMutation';

type Props = {
  index: number;
  detail: Todo;
  supplierId: number | undefined;
  setErrMsg: (errMsg: string) => void;
  isChangedArray: boolean[];
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  setMsgKind: (msgKind: string) => void;
  setTaskKind: (taskKind: string) => void;
  setPopUp: (popUp: boolean) => void;
  setDeleteId: (deleteId: number) => void;
  setConfirmMsg: (confirmMsg: string) => void;
  msgKind: string;
  refetch: () => void;
  materialOrdersRefetch: () => void;
  isLineChangedArray: boolean[];
  setIsLineChangedArray: (isLineChangedArray: boolean[]) => void;
  disableAnotherMaterial: boolean;
  setDisableAnotherMaterial: (disableAnotherMaterial: boolean) => void;
  orderStatus: string;
  inputting: boolean;
  setInputting: (inputting: boolean) => void;
};

const DeliverStatusInputWrapper: FC<Props> = ({
  index,
  detail,
  supplierId,
  setErrMsg,
  updating,
  setUpdating,
  setMsgKind,
  setTaskKind,
  setPopUp,
  setDeleteId,
  setConfirmMsg,
  msgKind,
  refetch,
  materialOrdersRefetch,
  isLineChangedArray,
  setIsLineChangedArray,
  disableAnotherMaterial,
  setDisableAnotherMaterial,
  orderStatus,
  inputting,
  setInputting,
}) => {
  const { state } = useLocation<PurchaseOrderState>();
  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);

  // 納品状況データ初期値
  const defaultInitData = {
    amount: 0,
    deliveryDate: '',
    deliveryDetailId: null,
    unit: '',
  };

  // 納品状況更新データ
  const [newData, setNewData] = useState<DeliveredDetail[]>(
    detail?.deliveryDetails
  );

  // 上下に並んだ同じ商品だとnewDataに設定されないため、stateの情報を使用して設定する
  useEffect(() => {
    setNewData(detail?.deliveryDetails);
    reRender();
  }, [state?.selectedMaterialOrderId]);

  const [initData, setInitData] = useState<Todo>(defaultInitData);
  const [deliveryDetailId, setDeliveryDetailId] = useState(
    newData[index]?.deliveryDetailId ? newData[index]?.deliveryDetailId : 0
  );
  const [isChangedArray, setIsChangedArray] = useState<boolean[]>([]);

  const handleCreateSuccess = () => {
    materialOrdersRefetch();
    refetch();
    setIsChangedArray([]);
    setIsLineChangedArray([]);
    setDisableAnotherMaterial(false);
    setUpdating(false);
    setInputting(false);
    reRender();
    setPopUp(true);
  };

  const createMaterialDeliveriesMutation = useCreateMaterialDeliveriesMutation(
    handleCreateSuccess,
    setErrMsg
  );

  const updateMaterialDeliveriesMutation = useUpdateMaterialDeliveriesMutation(
    deliveryDetailId,
    handleCreateSuccess,
    setErrMsg
  );

  // 納品状況更新
  const updateMaterialOrderDelivery = (detail: Todo, index: number) => {
    if (deliveryDetailId) {
      setMsgKind('update');
      setTaskKind('materialDelivery');
      // 更新処理
      updateMaterialDeliveriesMutation.mutate({
        materialDelivery: {
          materialId: detail.materialId,
          materialOrderDetailId: detail.id,
          amount: Number(newData[index].amount),
        },
      });
    } else {
      setMsgKind('create');
      setTaskKind('materialDelivery');
      // 作成処理
      createMaterialDeliveriesMutation.mutate({
        supplierId: supplierId,
        deliveryDate: newData[index].deliveryDate,
        detailsAttributes: [
          {
            materialId: detail.materialId,
            materialOrderDetailId: detail.id,
            amount: Number(newData[index].amount),
          },
        ],
      });
    }
  };

  // 下記を有効にすると、注文書一覧でスクロール時、表示内容が変化する
  // useEffect(() => {
  // reRender();
  // }, [newData?.length]);

  return (
    <>
      <DeliveryStatusInputComponent
        detail={detail}
        newData={newData}
        setNewData={setNewData}
        setErrMsg={setErrMsg}
        updateMaterialOrderDelivery={updateMaterialOrderDelivery}
        isChangedArray={isChangedArray}
        initData={initData}
        updating={updating}
        setUpdating={setUpdating}
        setPopUp={setPopUp}
        setDeleteId={setDeleteId}
        setConfirmMsg={setConfirmMsg}
        msgKind={msgKind}
        isLineChangedArray={isLineChangedArray}
        disableAnotherMaterial={disableAnotherMaterial}
        setDisableAnotherMaterial={setDisableAnotherMaterial}
        orderStatus={orderStatus}
        setInputting={setInputting}
        setDeliveryDetailId={setDeliveryDetailId}
      />
      {orderStatus === 'completed' && !inputting && (
        <S.FlexTableDivRow
          onClick={() => {
            setInitData((prevData: Todo) => ({
              ...prevData,
              orderDetailId: newData[index].deliveryDetailId,
            }));
            newData.push(initData);
          }}
        >
          <AddIcon />
          追加
        </S.FlexTableDivRow>
      )}
    </>
  );
};

export default DeliverStatusInputWrapper;
