import styled, { css } from 'styled-components';

const highLightenCell = css`
  position: relative;
  border-right: 2px solid ${(props) => props.theme.palette.blue};
  border-left: 2px solid ${(props) => props.theme.palette.blue};
`;

const normalCell = css`
  border-bottom: 1px solid #efefef;
`;

const lastCell = css`
  border-bottom: 2px solid ${(props) => props.theme.palette.sandGray};
`;

// 各数値セル
export const ListCellContainer = styled.div<{
  highLighten: boolean;
  withCheckEnabled?: boolean;
  invalid?: boolean;
  last?: boolean;
  productionSchedule?: boolean;
}>`
  position: relative;
  width: 91px;
  box-sizing: border-box;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.productionSchedule ? 'pointer' : '')};
  height: 30px;
  color: ${(props) => (props.withCheckEnabled ? '#C74141' : '')};
  font-weight: ${(props) => (props.withCheckEnabled ? 'bold' : '')};
  background-color: ${(props) =>
    props.withCheckEnabled ? '#FDDEDE!important' : ''};
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};

  /* 前年同月 */
  :last-child {
    position: sticky;
    top: 0;
    right: 0;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
    background: ${(props) => props.theme.palette.white};
    pointer-events: none;
  }

  /* 合計 */
  :nth-last-child(2) {
    position: sticky;
    top: 0;
    right: 91px;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
    background: ${(props) => props.theme.palette.beige};
    pointer-events: none;
  }

  ${({ highLighten }) => (highLighten ? highLightenCell : null)};
  ${({ last }) => (last ? lastCell : normalCell)};

  &.select-cell {
    border: 2px solid ${(props) => props.theme.palette.primary.main};
  }
`;
