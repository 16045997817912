import styled, { css } from 'styled-components';

const highLightenCell = css`
  position: relative;
  border-right: 2px solid ${(props) => props.theme.palette.blue};
  border-left: 2px solid ${(props) => props.theme.palette.blue};
`;

// 生産計画入力セル
export const Wrapper = styled.input<{
  highLighten?: boolean;
  withCheckEnabled?: boolean;
}>`
  width: 91px;
  padding: 0.5rem 0;
  text-align: center;
  height: 30px;
  border-bottom: 1px solid #efefef;
  ${({ highLighten }) => (highLighten ? highLightenCell : null)};
  color: ${(props) => (props.withCheckEnabled ? '#C74141' : '')};
  font-weight: ${(props) => (props.withCheckEnabled ? 'bold' : '')};
  background-color: ${(props) =>
    props.withCheckEnabled ? '#FDDEDE!important' : ''};

  :focus {
    border: 2px solid #2ba9d1;
    outline: 0;
  }

  /* 前年同月 */
  :last-child {
    position: sticky;
    top: 0;
    right: 0;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
    background: ${(props) => props.theme.palette.white};
    pointer-events: none;
  }

  /* 合計 */
  :nth-last-child(2) {
    position: sticky;
    top: 0;
    right: 91px;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
    background: ${(props) => props.theme.palette.beige};
    pointer-events: none;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  /* &.select-cell {
      border: 2px solid ${(props) => props.theme.palette.primary.main};
    } */
`;
