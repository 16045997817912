import styled from 'styled-components';

export const Wrapper = styled.div<{
  width?: string;
}>`
  display: flex;
  position: relative;
  text-align: center;
`;

export const LabelWrapper = styled.span`
  padding: 0.75rem 0;
`;
