import styled from 'styled-components';

export const SearchWrapper = styled.div`
  display: flex;
  background-color: '#F8F7F4';
  border-bottom: 1px solid ${(props) => props.theme.palette.sand};
  padding-left: 0.8rem;
`;

export const SearchConditionInput = styled.input`
  /* margin-left: 0.75rem; */
  width: 100%;
  padding: 4px 0 4px 14px;
  border-radius: 7.8px 0px 0px 7.8px;
  background-color: '#F8F7F4';

  outline: none;

  &::placeholder {
    color: ${(props) => props.theme.palette.khaki};
  }
  &::after {
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
    /* position: absolute; */
    background-image: '@assets/icons/mobile/search.svg';
  }
`;

export const Icon = styled.div`
  color: ${(props) => props.theme.palette.khaki};
  padding-right: 0.75rem;
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0 7.8px 7.8px 0;
  /* position: absolute; */
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const ListLeftHeadCell = styled.div<{
  invalid?: boolean;
}>`
  background-color: '#F8F7F4';
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  cursor: pointer;
  height: 40px;
  /* min-width: 250px; */
  border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
  box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05);
  position: -webkit-sticky; /* for Safari */
  -webkit-transform: translateZ(0);
  display: flex;
  justify-content: space-between;
  /* padding: 6px 0.4rem; */
  gap: 0.25rem;

  display: flex;
  flex-direction: column;

  cursor: pointer;

  > span {
    font-size: 0.75rem;
  }
`;
