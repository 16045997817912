import { useState, useEffect } from 'react';
import { WithHeader } from '@components/templates/WithHeader';
import useProductProductionResults from '@lib/pc/product_production_result/useProductProductionRetults';
import useProductsProcesses from '@lib/pc/product_production_result/useProductsProcesses';
import useProducts from '@lib/pc/wrapping/useProducts';
import useCases from '@lib/pc/common/hooks/useCases';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import { PcScrollStyle } from '@lib/styled-components/globalStyle';
import ProductProductionResultHeaderControl from '@components/modules/pc/product_production_result/ProductProductionResultHeaderControl/ProductProductionResultHeaderControl';
import ProductProductionResultContents from '@components/modules/pc/product_production_result/ProductProductionResultContents/ProductProductionResultContents';
import { getTodayForInput } from '@lib/common/functions';
import ProductProductionResultCopyModal from '@components/modules/pc/product_production_result/ProductProductionResultCopyModal/ProductProductionResultCopyModal';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import useDuplicateProductProductionResultMutation from '@lib/pc/product_production_result/useDuplicateProductProductionResultMutation';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import moment from 'moment';
import { Factory } from '@lib/common/type';

export const ProductProductionResultRoot = () => {
  const selectedFactoryAndLine = localStorage
    .getItem('product_production_selected_line')
    ?.split(',');
  const today = new Date();
  const [date, setDate] = useState(new Date());
  const [sevenDaysBefore, setSevenDaysBefore] = useState(new Date());
  // コピーしたいデータの日付
  const [duplicateTargetDate, setDuplicateTargetDate] = useState(new Date());
  const [selectedType, setSelectedType] = useState('');
  const [popUp, setPopUp] = useState(false);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [messageKind, setMessageKind] = useState('');
  const [editProductName, setEditProductName] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const { productionResults, refetch } = useProductProductionResults(date);
  const { data: products } = useProducts();
  const { data: productsProcesses } = useProductsProcesses();
  const { data: cases } = useCases();

  const [selectedFactoryId, setSelectedFactoryId] = useState(
    selectedFactoryAndLine
      ? selectedFactoryAndLine[0]
      : productionResults?.factories[0]?.id
  );

  const [factoriesData] = useState(productionResults?.factories);
  const [selectedFactoryData, setSelectedFactoryData] = useState(
    productionResults?.productionResults[0]
  );
  const [selectedLineId, setSelectedLineId] = useState(
    productionResults?.productionResults[0]?.lines[0]?.id
  );
  // 選択ラインのIndex
  const [selectedLineIndex, setSelectedLine] = useState(0);
  // 商品選択時の1台車あたりのケース数
  const [casesPerCart, setCasesPerCart] = useState('');
  // 登録済みProductIds
  const [registeredProductIds, setRegisteredProductIds] = useState<number[]>(
    []
  );

  // 1日戻る
  const getBeforeDate = (date: Date) => {
    setDate(moment(date).subtract(1, 'days').toDate());
    setSevenDaysBefore(moment(date).subtract(1, 'days').toDate());
    setPopUp(false);
  };

  // 1日進む
  const getNextDate = (date: Date) => {
    // 未来日も選択可能
    setDate(moment(date).add(1, 'days').toDate());
    setSevenDaysBefore(moment(date).add(1, 'days').toDate());
    setPopUp(false);
  };

  const handleClickToday = () => {
    getTodayForInput(date, today, setDate, refetch);
    // 1週間前日付の取得
    setSevenDaysBefore(moment(date).subtract(7, 'days').toDate());
  };

  const onClickLast = async () => {
    await setSelectedType('latest');
    // (await selectedType(await selectedType)) &&
    duplicateProductProductionResultMutation.mutate();
  };

  const onClickLastWeek = async () => {
    await setDuplicateTargetDate(sevenDaysBefore);
    await setSelectedType('last_week');
    duplicateProductProductionResultMutation.mutate();
  };

  const onClickCalender = async () => {
    await setSelectedType('selected');
    duplicateProductProductionResultMutation.mutate();
  };

  const handleDuplicateSuccess = () => {
    refetch();
    setSelectedType('');
    setCopyModalOpen(false);
  };

  // 完成品生産実績データ作成処理
  const duplicateProductProductionResultMutation =
    useDuplicateProductProductionResultMutation(
      date,
      selectedType,
      duplicateTargetDate,
      selectedLineId,
      handleDuplicateSuccess,
      setErrMsg
    );

  useEffect(() => {
    localStorage.setItem('last_view_page', 'product_production_result');
  }, []);

  useEffect(() => {
    // 1週間前日付の取得
    setSevenDaysBefore(moment(date).subtract(7, 'days').toDate());
  }, []);

  useEffect(() => {
    if (errMsg) {
      setCopyModalOpen(false);
    }
  }, [errMsg]);

  // localStorageに保存されたfactoryIdが存在しない場合、存在する最初のfactoryIdを設定する
  useEffect(() => {
    const ids = productionResults?.factories.map((item: Factory) => item['id']);
    if (ids && !ids.includes(Number(selectedFactoryId))) {
      setSelectedFactoryId(ids[0]);
    }
  }, [productionResults?.productionResults?.length]);

  if (!productsProcesses || !cases) return null;

  return (
    <WithHeader>
      <PcScrollStyle />
      <ProductProductionResultHeaderControl
        productionResults={productionResults}
        factories={
          factoriesData ? factoriesData : productionResults?.productionResults
        }
        selectedFactoryId={selectedFactoryId}
        setSelectedFactoryId={setSelectedFactoryId}
        setSelectedFactoryData={setSelectedFactoryData}
        setSelectedLineId={setSelectedLineId}
        setSelectedLine={setSelectedLine}
        selectedDate={date}
        getBeforeDate={getBeforeDate}
        getNextDate={getNextDate}
        setPopUp={setPopUp}
        handleClickToday={handleClickToday}
        handleClickCopy={() => {
          setCopyModalOpen(!copyModalOpen);
          setPopUp(false);
        }}
        setDate={setDate}
      />
      <ProductProductionResultContents
        productionResults={productionResults}
        selectedFactoryId={selectedFactoryId}
        setSelectedFactoryId={setSelectedFactoryId}
        selectedFactoryData={
          selectedFactoryData
            ? selectedFactoryData
            : productionResults?.productionResults[0]
        }
        setSelectedFactoryData={setSelectedFactoryData}
        selectedLineId={
          selectedLineId
            ? selectedLineId
            : productionResults?.productionResults[0]?.lines[0]?.id
        }
        setSelectedLineId={setSelectedLineId}
        selectedLineIndex={selectedLineIndex}
        setSelectedLine={setSelectedLine}
        date={date}
        products={products}
        productsProcesses={productsProcesses}
        cases={cases}
        setPopUp={setPopUp}
        setEditProductName={setEditProductName}
        refetch={refetch}
        setMessageKind={setMessageKind}
        casesPerCart={casesPerCart}
        setCasesPerCart={setCasesPerCart}
        registeredProductIds={registeredProductIds}
        setRegisteredProductIds={setRegisteredProductIds}
      />
      <UpdatePopUp
        popUp={popUp}
        handleClose={() => setPopUp(false)}
        fromPc={true}
        taskKind={'productionResult'}
        messageKind={messageKind}
        productName={editProductName}
      />
      <ProductProductionResultCopyModal
        copyModalOpen={copyModalOpen}
        onClickLast={onClickLast}
        onClickLastWeek={onClickLastWeek}
        onClickCalender={onClickCalender}
        sevenDaysBefore={sevenDaysBefore}
        date={date}
        setDuplicateTargetDate={setDuplicateTargetDate}
      />
      {copyModalOpen && (
        <Overlay dark handleClick={() => setCopyModalOpen(false)} />
      )}
      {errMsg && (
        <ErrorMsgPopUp
          errMsg={errMsg}
          handleClose={() => setErrMsg('')}
          fromPc={true}
        />
      )}
    </WithHeader>
  );
};
