import moment from 'moment';
import type {
  ResponseDemandForecast,
  ResponseStock,
  ResponseAmount,
  ResponseProductionSchedule,
} from '@lib/pc/production_schedule/type';

export const isSameDayWithOutTime = (d1: Date, d2: Date) => {
  return moment(d1).isSame(d2, 'day');
};

export const isSameIndex = (
  idx: number,
  selectIndex: number,
  monthlyDateNum: number
) => {
  return idx === selectIndex && selectIndex <= monthlyDateNum;
};

export const generateDateArray = (num: number, date = moment()) => {
  return [...Array(2 * num + 1)].map((_, i) => {
    const selectedDay = new Date(moment(date).toDate());
    const d = moment(date).toDate();
    d.setDate(d.getDate() + (i - num));
    return {
      productionDate: d,
      isToday: isSameDayWithOutTime(d, selectedDay),
    };
  });
};

export const generateDateArrayForModal = (num: number, date = moment()) => {
  return [...Array(num + 1)].map((_, i) => {
    const selectedDay = new Date(moment(date).toDate());
    const d = moment(date).toDate();
    d.setDate(1 + i);
    return {
      productionDate: d,
      isToday: isSameDayWithOutTime(d, selectedDay),
    };
  });
};

export const generateMonthlyDateArray = (
  monthlyDateNum: number,
  date = moment(),
  index: number
) => {
  return [...Array(monthlyDateNum + 3)].map((_, i) => {
    const d = moment(date).toDate();
    d.setDate(1 + i);
    return {
      productionDate: d,
      isToday: i === index && i <= monthlyDateNum,
    };
  });
};

export const generateProductionDummyData = (num: number) => {
  return [...Array(2 * num + 1)].map((_, i) => {
    const d = new Date();
    d.setDate(d.getDate() + (i - num));
    return {
      id: i + 1,
      productionDate: d,
      estimatedNum: Math.floor(Math.random() * 700) + i,
      actualNum: null,
    };
  });
};

export const addComma = (val: number | null) => {
  if (val === null) return;

  return ('' + val).replace(/(\d)(?=(\d\d\d)+$)/g, '$1,');
};

export const isIncludes = (arr: number[], target: number[]) =>
  arr.some((id) => target.includes(id));

export const changeWidth = (num: number) => {
  let width = 2175;
  switch (num) {
    case 27:
      width = 1965;
      break;

    case 28:
      width = 2035;
      break;

    case 29:
      width = 2105;
      break;
  }
  return width;
};

// 今はまだ使用していない
// export const convertTokubaiAndBarData = (arr: Todo[], arr2: Todo[]) => {
//   return arr.map((a: Todo, i: number) => {
//     return {
//       id: i,
//       x: i,
//       y: arr2[i].amount ? arr2[i].amount : a.amount,
//     };
//   });
// };

// 【生産計画の根拠と受注確定状況を確認】グラフ左のY軸の値
export const convertDemandForecastOrShippingAmountToBarData = (
  arr: ResponseDemandForecast[]
) => {
  return arr.map((a: ResponseDemandForecast, i: number) => {
    return {
      id: a.id,
      x: i,
      y: Math.max(
        (a.demandForecastAmount || 0) + (a.var || 0),
        a.shippingAmount,
        1
      ),
    };
  });
};

// 【在庫数の予定と実績】グラフ左のY軸の値
export const convertActualOrSafetyStocksArrToBarData = (
  arr: ResponseAmount[]
) => {
  return arr.map((a: ResponseAmount, i: number) => {
    return {
      id: a.id,
      x: i,
      y: Math.max(a.actualAmount, a.estimatedAmount, a.safetyStocks, 1),
    };
  });
};

// 【生産計画と実績（生産計画数と生産実績）】グラフ左のY軸の値
export const convertEstimatedOrActualArrToBarData = (arr: ResponseAmount[]) => {
  return arr.map((a: ResponseAmount, i: number) => {
    return {
      id: a.id,
      x: i,
      y: Math.max(
        a.actualAmount,
        a.estimatedAmount,
        a.productionResultAmount,
        10
      ),
    };
  });
};

export const convertEstimatedArrToBarData = (arr: ResponseAmount[]) => {
  return arr.map((a: ResponseAmount, i: number) => {
    return {
      id: a.id,
      x: i,
      y: a.actualAmount ? a.actualAmount : a.estimatedAmount,
    };
  });
};

export const convertProductionArrToBarData = (
  arr: ResponseProductionSchedule[]
) => {
  return arr.map((a: ResponseProductionSchedule, i: number) => {
    return {
      id: a.id,
      x: i,
      y: a.productionResultAmount,
    };
  });
};

export const convertActualArrToBarData = (arr: ResponseAmount[]) => {
  return arr.map((a: ResponseAmount, i: number) => {
    return {
      id: a.id,
      x: i,
      y: a.actualAmount,
    };
  });
};

export const convertStockEstimatedArrToBarData = (arr: ResponseAmount[]) => {
  return arr.map((a: ResponseAmount, i: number) => {
    return {
      id: a.id,
      x: i,
      y: a.estimatedAmount,
    };
  });
};

export const convertSafetyStocksArrToBarData = (arr: ResponseStock[]) => {
  return arr.map((a: ResponseStock, i: number) => {
    return {
      id: a.id,
      x: i,
      y: a.safetyStocks,
    };
  });
};

export const convertShippingArrToBarData = (arr: ResponseDemandForecast[]) => {
  return arr.map((a: ResponseDemandForecast, i: number) => {
    return {
      id: a.id,
      x: i,
      y: a.shippingAmount,
    };
  });
};

export const convertNumberOfGroundsToBarData = (
  arr: ResponseDemandForecast[]
) => {
  return arr.map((a: ResponseDemandForecast, i: number) => {
    return {
      id: a.id,
      x: i,
      // 出荷確定数と出荷予測数、出荷予測数+特売数の中で最も大きいものが棒グラフになる仕様（出荷予測数+特売数が今後必要）
      y: Math.max(a.demandForecastAmount, a.shippingAmount),
    };
  });
};

export const convertDemandForecastArrToBarData = (
  arr: ResponseDemandForecast[]
) => {
  return arr.map((a: ResponseDemandForecast, i: number) => {
    return {
      id: a.id,
      x: i,
      y: a.demandForecastAmount,
    };
  });
};

export const convertVarArrToBarData = (arr: ResponseDemandForecast[]) => {
  return arr.map((a: ResponseDemandForecast, i: number) => {
    return {
      id: a.id,
      x: i,
      y: a.var,
    };
  });
};

export const convertPredictionLowArrToBarData = (
  arr: ResponseDemandForecast[]
) => {
  return arr.map((a: ResponseDemandForecast, i: number) => {
    return {
      id: a.id,
      x: i,
      y:
        a.demandForecastAmount && a.var && a.demandForecastAmount - a.var < 0
          ? 0
          : a.demandForecastAmount && a.var && a.demandForecastAmount - a.var,
    };
  });
};

export const convertPredictionHighArrToBarData = (
  arr: ResponseDemandForecast[]
) => {
  return arr.map((a: ResponseDemandForecast, i: number) => {
    return {
      id: a.id,
      x: i,
      y: a.demandForecastAmount && a.var && a.demandForecastAmount + a.var,
    };
  });
};

// 仮で出力
export const convertDemandForecastTokubaiArrToBarData = (
  arr: ResponseDemandForecast[]
) => {
  return arr.map((a: ResponseDemandForecast, i: number) => {
    return {
      id: a.id,
      x: i,
      y: a.demandForecastAmount ? a.demandForecastAmount + 50 : '',
    };
  });
};
