import { FC, useState, useEffect } from 'react';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import humps from 'humps';
import type {
  Todo,
  ShippingDestination,
  ShippingDestinationMeta,
  LoadOptionsResult,
  Additional,
} from '@lib/common/type';
import { AsyncPaginate } from 'react-select-async-paginate';
import { OptionsOrGroups, GroupBase } from 'react-select';

type Props = {
  handleChangeShipping: (e: number) => void;
  setPopUpShippingName?: (popUpShippingName: string) => void;
  setSelectedCode?: (selectedCode: string) => void;
  height?: string;
  menuTop?: string;
  searchable?: boolean;
  selectedShippingDestinationIds?: number[];
  registeredIds?: number[];
  selectedId?: number;
  useCacheUniqs?: boolean;
};

const ShippingDestinationSelect: FC<Props> = ({
  handleChangeShipping,
  setPopUpShippingName,
  setSelectedCode,
  height,
  menuTop,
  searchable,
  selectedShippingDestinationIds,
  selectedId,
  useCacheUniqs,
}: Props) => {
  const initialize: ShippingDestination = {
    value: 0,
    label: '全ての出荷先（総数）',
    code: '',
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOption, setSelectedOption] =
    useState<ShippingDestination | null>(initialize);

  const loadOptions = async (
    search: string,
    loadedOptions: OptionsOrGroups<
      ShippingDestination,
      GroupBase<ShippingDestination>
    >,
    { page = 1 }: Additional = { page: 1 }
  ): Promise<LoadOptionsResult> => {
    const perPage = 1000; // 最大1000件ずつ取得する
    try {
      const response = await axiosInstance().get(
        `/api/v1/shipping_destinations`,
        {
          params: {
            page,
            search,
            per_page: perPage,
          },
        }
      );

      const shippingDestinations = humps.camelizeKeys(
        response.data.shipping_destinations
      ) as ShippingDestination[];

      const meta = humps.camelizeKeys(
        response.data.meta
      ) as unknown as ShippingDestinationMeta;
      // 現在のページが総ページ数を超えているか確認し、超えていればページをリセット
      if (page > meta.totalPages && meta.totalPages > 0) {
        return loadOptions(search, loadedOptions, { page: 1 });
      }

      const nextPage = page < meta.totalPages ? page + 1 : page;

      // 選択済みの出荷先を非表示に
      const filteredShippingDestinations =
        shippingDestinations &&
        shippingDestinations?.filter(
          (asd: ShippingDestination) =>
            !selectedShippingDestinationIds?.includes(asd.value)
        );

      return {
        options: filteredShippingDestinations,
        hasMore: page < meta.totalPages,
        additional: {
          page: nextPage,
        },
      };
    } catch (error: Todo) {
      console.error('Error fetching data:', error.message);
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 1,
        },
      };
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, []);

  // 出荷先グループ設定画面で、選択追加後、追加済出荷先を空にする。
  useEffect(() => {
    if (selectedId === 0) {
      setSelectedOption(null);
    }
  }, [selectedId]);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      minWidth: '100%',
      minHeight: height ? height : '4rem',
      width: '22rem',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      cursor: 'pointer',
      boxShadow: 'none',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: menuTop ? menuTop : '37px',
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
  };

  return (
    <>
      {/* AsyncPaginate コンポーネントの設定 */}
      <AsyncPaginate
        placeholder="出荷先の選択"
        menuShouldBlockScroll={true}
        menuShouldScrollIntoView={false}
        debounceTimeout={800}
        styles={styles}
        value={selectedOption}
        onChange={(option: ShippingDestination | null) => {
          setSelectedOption(option);
          if (option) {
            handleChangeShipping(option.value);
            setPopUpShippingName && setPopUpShippingName(option.label);
            setSelectedCode && setSelectedCode(option.code);
          }
        }}
        loadOptions={loadOptions}
        additional={{
          page: currentPage,
        }}
        isSearchable={searchable ? searchable : false}
        components={{
          IndicatorSeparator: () => null,
        }}
        // 値が変更されたときにキャッシュがクリアされ、オプション再読み込み
        {...(useCacheUniqs
          ? { cacheUniqs: [selectedShippingDestinationIds, selectedOption] }
          : {})}
      />
    </>
  );
};

export default ShippingDestinationSelect;
