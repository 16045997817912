import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as S from './ProductMasterCreateWrapper.styles';
import ProductInfo from '@components/modules/pc/settings/product_master_setting/ProductMasterEdit/ProductInfo/ProductInfo';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import { defaultNewProductData } from './default';
import useProducts from '@lib/pc/common/hooks/useProducts';
import { ProductMasterSettingState } from '@lib/pc/settings/product_master_setting/type';

export type InputParams = {
  time: string;
};

const ProductMasterCreateWrapper = () => {
  const { timestamp } = useParams<{ timestamp: string }>();
  const { state } = useLocation<ProductMasterSettingState>();
  const history = useHistory();
  const [popUp, setPopUp] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [newProductData, setNewProductData] = useState(
    state?.editData ? state.editData : defaultNewProductData
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('');
  const [selectedTabId, setSelectedTabId] = useState(0);

  // 確認メッセージhandleOk判定用:一覧画面へ移動か、別タブ表示か。
  const [goBack, setGoBack] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const { data: allProducts } = useProducts();

  // ProductInfoで作成した新しいProduct
  const [productFromInfo, setProductFromInfo] = useState(defaultNewProductData);

  const ProductSettingTabs = ['製品情報', '工程', '保管場所', 'タグ', 'ケース'];

  const handleProductUpdateSuccess = () => {
    setUpdating(false);
    setPopUp(!popUp);
    setIsChanged(false);
    setUpdatePopUp(!updatePopUp);
  };

  // 一覧画面へ戻る
  const onClickGoBack = () => {
    if (isChanged) {
      setGoBack(true);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      history.push({
        pathname: `/pc/settings/product_master_setting`,
        state,
      });
    }
  };

  const handleClickTab = (i: number) => {
    setGoBack(false);
    if (productFromInfo.id === 0) {
      setErrMsg('はじめに製品情報を登録してください。');
    } else if (isChanged) {
      setSelectedTabId(i);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      setSelectedTabIndex(i);
      history.push({
        pathname: `/pc/settings/product_master_setting_edit/${productFromInfo.id}`,
        state: {
          ...state,
          stateSelectedTabId: i,
          stateSelectedProductId: productFromInfo.id,
        },
      });
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    // ここで一覧画面へ戻るか別タブを表示するかの判断が必要
    if (goBack) {
      history.push({
        pathname: `/pc/settings/product_master_setting`,
        state,
      });
    } else {
      setSelectedTabIndex(selectedTabId);
    }
    setIsChanged(false);
    setConfirmMsg('');
  };
  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  return (
    <div>
      <S.TabWrapper>
        {ProductSettingTabs.map((d: string, i: number) => (
          <div key={d}>
            <S.TabArea>
              <S.Tab
                key={d}
                tabCount={ProductSettingTabs.length}
                active={i == selectedTabIndex}
                onClick={() => {
                  handleClickTab(i);
                }}
              >
                {d}
              </S.Tab>
            </S.TabArea>
          </div>
        ))}
      </S.TabWrapper>

      <DndProvider backend={HTML5Backend}>
        {/* 製品情報 */}
        {selectedTabIndex === 0 && (
          <ProductInfo
            product={newProductData}
            updating={updating}
            setUpdating={setUpdating}
            setPopUp={setPopUp}
            newProductData={newProductData}
            setNewProductData={setNewProductData}
            handleProductUpdateSuccess={handleProductUpdateSuccess}
            onClickGoBack={onClickGoBack}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            allProducts={allProducts}
            productFromInfo={productFromInfo}
            setProductFromInfo={setProductFromInfo}
            timestamp={timestamp}
          />
        )}
      </DndProvider>
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <UpdatePopUp
        popUp={updatePopUp}
        taskKind={'messageOnly'}
        messageKind={'create'}
        handleClose={() => setUpdatePopUp(false)}
      />
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => setErrMsg('')}
        fromPc={true}
      />
      {updatePopUp && <Overlay handleClick={() => setUpdatePopUp(false)} />}
    </div>
  );
};

export default ProductMasterCreateWrapper;
