import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import * as S from './TableWithFixedSidebar.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import TagMenu from '@components/modules/common/TagMenu/TagMenu';
import TagListModal from '@components/modules/common/TagListModal/TagListModal';
import { addComma } from '@lib/pc/stock_status/functions';
import { generateDateArray } from '@lib/pc/materials_inventory/functions';
import { tagSearch } from '@lib/common/functions';
import {
  FunctionType,
  MATERIAL_TYPE,
  MATERIAL_PRODUCT_KIND,
  Todo,
} from '@lib/common/type';
import { CircularProgress } from '@material-ui/core';
import DisplaySelectModal from '@components/modules/common/DisplaySelectModal/DisplaySelectModal';
import type { selectedItem } from '@lib/pc/materials_inventory/type';

type Props = {
  selectedDate: moment.Moment;
  data: Todo;
  selected: boolean;
  handleSelectedMaterial: (d: selectedItem) => void;
  handleSelectEstimatedMaterial: (d: Todo) => void;
  handleOpenProductDetail: (item: Todo) => void;
  setTagListModalActive?: () => void;
  rightSlideSidebarActive: boolean;
  onClickCancel: () => void;
  selectedStoreroomId: number;
};

const TableWithFixedSidebar: FC<Props> = ({
  selectedDate,
  data,
  selected,
  handleSelectedMaterial,
  handleSelectEstimatedMaterial,
  handleOpenProductDetail,
  rightSlideSidebarActive,
  onClickCancel,
  selectedStoreroomId,
}: Props) => {
  const [searchWord, setSearchWord] = useState<string>('');
  const [tagMenuActive, setTagMenuActive] = useState<boolean>(false);
  const [tagListModalActive, setTagListModalActive] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<Todo>([]);
  // 表示タイプの選択
  const [displaySelectModalActive, setDisplaySelectModalActive] =
    useState(false);
  const functionTypeObj = [
    { id: 0, type: '実在庫' },
    { id: 1, type: '予定在庫' },
    { id: 2, type: '納品済数' },
    { id: 3, type: '予定納品数' },
  ];
  const [selectedTypes, setSelectedTypes] =
    useState<FunctionType[]>(functionTypeObj);

  const selectedTypeIds = selectedTypes.map((item: FunctionType) => item['id']);

  const selectedTagIds = selectedTags.map((item: Todo) => item['id']);

  const MaterialNameArr = data
    ? data.map((p: Todo) => ({
        id: p.id,
        name: p.name,
        nameAbbreviation: p.nameAbbreviation,
        nameKana: p.nameKana,
        piecesPerBox: p.piecesPerBox,
        lastInput: p.stockLastInputAt,
        materialCode: p.materialCode,
        tagIds: p.tagIds,
        storeroomIds: p.storeroomIds,
        materialType:
          p.materialType === MATERIAL_TYPE.ROW_MATERIAL ? '原' : '資',
        actualStocks: p.actualStocks,
        estimatedStocks: p.estimatedStocks,
        estimatedDeliveries: p.estimatedDeliveries,
        actualDeliveries: p.actualDeliveries,
      }))
    : [];

  const handleSearch = (e: Todo) => {
    setSearchWord(e.target.value);
  };

  useEffect(() => {
    setTagMenuActive(false);
  }, [selectedTagIds.length === 0]);

  const filteredProduct = data
    ?.filter(
      (p: Todo) =>
        p.name.includes(searchWord) ||
        p.nameAbbreviation?.includes(searchWord) ||
        p.nameKana?.includes(searchWord) ||
        p.materialCode?.toString().includes(searchWord)
    )
    .filter((p: Todo) => tagSearch(p, selectedTagIds));

  return (
    <>
      <div>
        <S.ListWrapper>
          <S.ListSidebar>
            <div>
              <S.ListLeftHeadCell
                invalid={rightSlideSidebarActive}
                onClick={() => setTagMenuActive(!tagMenuActive)}
                isBlue={searchWord !== '' || selectedTagIds.length > 0}
              >
                <span className="filter-product">
                  {searchWord !== '' || selectedTagIds.length > 0 ? (
                    <>
                      <FilteredIcon />
                      <div className="filter_text">資材・原材料絞込中</div>
                    </>
                  ) : (
                    <>
                      <Filter
                        isBlue={searchWord !== '' || selectedTagIds.length > 0}
                      />
                      <div className="filter_text">資材・原材料絞込</div>
                    </>
                  )}
                </span>
              </S.ListLeftHeadCell>
              <S.DisplayButton
                onClick={() =>
                  setDisplaySelectModalActive(!displaySelectModalActive)
                }
                isBlue={selectedTypeIds.length !== 4}
              >
                <span className="filter-product">
                  {selectedTypeIds.length !== 4 ? (
                    <>
                      <FilteredIcon />
                      <div className="filter_text">選択中</div>
                    </>
                  ) : (
                    <>
                      <Filter />
                      <div className="filter_text">表示選択</div>
                    </>
                  )}
                </span>
              </S.DisplayButton>
            </div>

            {MaterialNameArr.filter(
              (p: Todo) =>
                p.name.includes(searchWord) ||
                p.nameAbbreviation?.includes(searchWord) ||
                p.nameKana?.includes(searchWord) ||
                p.materialCode?.toString().includes(searchWord)
            )
              .filter((p: Todo) => tagSearch(p, selectedTagIds))
              .map(
                (
                  s: {
                    name: string;
                    nameAbbreviation: string;
                    materialCode: number;
                    materialType: string;
                    lastInput: string;
                    safetyStockDays: string | null;
                  },
                  i: number
                ) => {
                  const materialName = s.nameAbbreviation
                    ? s.nameAbbreviation
                    : s.name;
                  return (
                    // 資材・原材料名、資材・原材料コード
                    <S.ListLeftHeadCell
                      key={i}
                      onClick={() => handleOpenProductDetail(s)}
                    >
                      <S.MaterialInfoWrapper>
                        <S.ListLeftHeadName className="list-left-product-cell">
                          <S.MaterialName>{materialName}</S.MaterialName>
                          <S.MaterialTypeDiv materialKind={s.materialType}>
                            {s.materialType}
                          </S.MaterialTypeDiv>
                        </S.ListLeftHeadName>
                        {selectedTypeIds.length > 1 && (
                          <S.ListLeftHeadMeta>
                            <S.ListLeftMaterialCode>
                              {s.materialCode}
                            </S.ListLeftMaterialCode>
                            <div>
                              実在庫最終入力日
                              <div>
                                {s.lastInput &&
                                  moment(s.lastInput).format('YYYY/MM/DD')}
                              </div>
                            </div>
                          </S.ListLeftHeadMeta>
                        )}
                      </S.MaterialInfoWrapper>
                      <div className="type-name">
                        {functionTypeObj
                          .filter((value) => selectedTypeIds.includes(value.id))
                          .map((value, j) => {
                            // 表示タイプ
                            return (
                              <S.ListLeftHeadTypeName
                                key={`${i}-${j}-${value.id}`}
                                typeIndex={j}
                                productIndex={i}
                                notDisplay={
                                  !selectedTypeIds.includes(value.id)
                                    ? 'true'
                                    : ''
                                }
                              >
                                {value.type}
                              </S.ListLeftHeadTypeName>
                            );
                          })}
                      </div>
                    </S.ListLeftHeadCell>
                  );
                }
              )}
          </S.ListSidebar>
          <S.ListBody
            className={selected ? 'move-to-left' : ''}
            key="list-body"
          >
            <S.ListDateRow>
              {generateDateArray(15, selectedDate).map((p, i) => {
                return (
                  <S.ListHeadCell
                    key={`${i} - ${p.productionDate}`}
                    highLighten={i === 7}
                    dayOfWeek={moment(p.productionDate).format('ddd')}
                  >
                    {moment(p.productionDate).format('MM/DD(ddd)')}
                  </S.ListHeadCell>
                );
              })}
            </S.ListDateRow>
            {data ? (
              filteredProduct.map((p: Todo, i: number) => {
                return (
                  <React.Fragment key={`${i}-${p.id}-${p.name}`}>
                    {/* 実在庫 */}
                    <S.ListRow key={i}>
                      {generateDateArray(15).map((d: Todo, idx: number) => {
                        return (
                          <S.ListCellContainer
                            invalid={rightSlideSidebarActive}
                            key={idx}
                            highLighten={idx === 7}
                            withComment={p.actualStocks[idx]?.comment}
                            selectedStoreroomId={selectedStoreroomId !== 0}
                            onClick={() => {
                              handleSelectedMaterial({
                                materialId: p.id,
                                materialName: p.name,
                                materialType: p.materialType,
                                type:
                                  p.materialType === MATERIAL_TYPE.ROW_MATERIAL
                                    ? '原'
                                    : '資',
                                date: p.actualStocks[idx].date,
                                amountDetail: p.actualStocks[idx].amountDetail,
                                comment: p.actualStocks[idx].comment,
                                piecesPerBox: p.piecesPerBox
                                  ? p.piecesPerBox
                                  : 1,
                                piecesPerUnit: p.piecesPerUnit
                                  ? p.piecesPerUnit
                                  : 1,
                                labelOfAmount: p.labelOfAmount,
                                labelOfUnit: p.labelOfUnit,
                                actualStocks: p.actualStocks,
                                estimatedStocks: p.estimatedStocks,
                                actualDeliveries: p.actualDeliveries,
                                estimatedDeliveries: p.estimatedDeliveries,
                              });
                            }}
                          >
                            {addComma(p.actualStocks[idx]?.amount)}
                          </S.ListCellContainer>
                        );
                      })}
                    </S.ListRow>
                    {/* 予定在庫 */}
                    <S.ListRow
                      notDisplay={!selectedTypeIds.includes(1) ? 'true' : ''}
                    >
                      {p.estimatedStocks.map((d: Todo, idx: number) => {
                        return (
                          <S.CellContainer
                            key={`estimated-${i}-${
                              p.id
                            }-${idx}-${Math.random()}`}
                            highLighten={idx === 7}
                            amount={d?.amount || d?.amount === 0}
                            onClick={() => {
                              if (d?.amount || d?.amount === 0) {
                                handleSelectEstimatedMaterial({
                                  materialId: p.id,
                                  date: p.actualStocks[idx].date,
                                });
                              }
                            }}
                          >
                            {addComma(d?.amount)}
                          </S.CellContainer>
                        );
                      })}
                    </S.ListRow>
                    {/* 納品済数 */}
                    <S.ListRow
                      notDisplay={!selectedTypeIds.includes(2) ? 'true' : ''}
                    >
                      {p.actualDeliveries.map((d: Todo, idx: number) => {
                        return (
                          <S.CellContainer
                            key={`delivered-${i}-${
                              p.id
                            }-${idx}-${Math.random()}`}
                            highLighten={idx === 7}
                          >
                            {addComma(d?.amount)}
                          </S.CellContainer>
                        );
                      })}
                    </S.ListRow>
                    {/* 予定納品数 */}
                    <S.ListRow
                      notDisplay={!selectedTypeIds.includes(3) ? 'true' : ''}
                    >
                      {p.estimatedDeliveries.map((d: Todo, idx: number) => {
                        return (
                          <S.CellContainer
                            key={`delivery-${i}-${
                              p.id
                            }-${idx}-${Math.random()}`}
                            highLighten={idx === 7}
                          >
                            {addComma(d?.amount)}
                          </S.CellContainer>
                        );
                      })}
                    </S.ListRow>
                  </React.Fragment>
                );
              })
            ) : (
              <S.CircularIconWrapper>
                <CircularProgress style={{ color: '#64b2f9' }} />
              </S.CircularIconWrapper>
            )}
          </S.ListBody>
        </S.ListWrapper>
      </div>
      {tagMenuActive && (
        <TagMenu
          searchParam={searchWord}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          setOpenTagModal={() => setTagListModalActive(true)}
          handleChange={handleSearch}
          deleteSearchParam={() => setSearchWord('')}
          title="資材・原材料"
        />
      )}
      {tagListModalActive && (
        <TagListModal
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          tagListModalActive={tagListModalActive}
          handleClose={() => {
            setTagListModalActive(false);
            setTagMenuActive(false);
          }}
          productKind={MATERIAL_PRODUCT_KIND.MATERIAL}
        />
      )}
      {displaySelectModalActive && (
        <DisplaySelectModal
          notSelectableObj={'実在庫'}
          selectedTypes={selectedTypes}
          functionTypeObj={functionTypeObj}
          setSelectedTypes={(type) => setSelectedTypes(type)}
          displaySelectModalActive={displaySelectModalActive}
          handleClose={() => setDisplaySelectModalActive(false)}
        />
      )}

      {(tagMenuActive || rightSlideSidebarActive) && (
        <Overlay
          zIndex={9997}
          handleClick={() => {
            setTagMenuActive(false);
            onClickCancel();
          }}
        />
      )}
    </>
  );
};

export default TableWithFixedSidebar;
