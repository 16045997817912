import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import * as S from './RightSlideSidebar.styles';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import CancelButton from '@components/modules/pc/stock_status/CancelButton/CancelButton';
import SubmitButton from '@components/modules/pc/stock_status/SubmitButton/SubmitButton';
import ErrorMsgPopUpForStock from '@components/modules/pc/stock_status/ErrorMsgPopUpForStock/ErrorMsgPopUpForStock';
import FloatingAddButton from '@components/modules/common/mobile/FloatingAddButton/FloatingAddButton';
import ScrollToTop from '@components/modules/common/ScrollToTop/ScrollToTop';
import MaterialsForm from '@components/modules/pc/materials_inventory/MaterialsForm/MaterialsForm';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import { selectedItem } from '@lib/pc/materials_inventory/type';
import type { Todo } from '@lib/common/type';
import type { DetailItem } from '@lib/stock_status/type';
import {
  calcTotalFunc,
  duplicateDateCheck,
} from '@lib/pc/materials_inventory/functions';
import { InputParams } from '@lib/pc/materials_inventory/type';
import useUpdateMaterialStocksMutation from '@lib/pc/materials_inventory/useUpdateMaterialStocksMutation';
import useDeleteMaterialStocksMutation from '@lib/pc/materials_inventory/useDeleteMaterialStocksMutation';

type Props = {
  rightSlideSidebarActive: boolean;
  setRightSlideSidebarActive: (rightSlideSidebarActive: boolean) => void;
  selectedMaterial: selectedItem;
  selectedStoreroomId: string;
  popUp: boolean;
  setPopUp: (popUp: boolean) => void;
  refetch: () => void;
  setIsDelete: (isDelete: boolean) => void;
  setMessageKind: (messageKind: string) => void;
  inputAmountParams: Todo;
  setInputAmountParams: (inputAmountParams: Todo) => void;
  comment: string;
  setComment: (comment: string) => void;
  isChanged: boolean;
  setIsChanged: (isChanged: boolean) => void;
  confirmMsg: string;
  setConfirmMsg: (confirmMsg: string) => void;
  onClickCancel: () => void;
};

const RightSlideSidebar: FC<Props> = ({
  rightSlideSidebarActive,
  setRightSlideSidebarActive,
  selectedMaterial,
  selectedStoreroomId,
  popUp,
  setPopUp,
  refetch,
  setIsDelete,
  setMessageKind,
  inputAmountParams,
  setInputAmountParams,
  comment,
  setComment,
  isChanged,
  setIsChanged,
  confirmMsg,
  setConfirmMsg,
  onClickCancel,
}: Props) => {
  const day =
    selectedMaterial && selectedMaterial.date !== ''
      ? moment(selectedMaterial.date)
      : moment();

  const formattedDate = moment(day).format('YYYY/MM/DD (ddd)');

  const [initializeAmountDetail, setInitializeAmountDetail] = useState<Todo>(
    []
  );

  const [inputParams, setInputParams] = useState<InputParams>({
    amountDetail: selectedMaterial?.amountDetail || initializeAmountDetail,
    comment: selectedMaterial?.comment || '',
  });
  const [errMsg, setErrMsg] = useState('');
  // 削除ボタン押したidをセット
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [updating, setUpdating] = useState(false);

  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);

  // 入力エリア初期値
  const init = {
    id: '',
    piece: '',
    case: '',
    total: 0,
    expirationDate: '',
  };

  const handleClickFloatingAddButton = () => {
    // iPadでコメント入力後、＋ボタン押下でコメントが消えてしまう対策
    if (comment) {
      setComment(comment);
    }

    setInputAmountParams([...inputAmountParams, init]);
    setPopUp(false);
    const element = document.documentElement;
    const bottom = element.scrollHeight - element.clientHeight;
    window.scroll(0, bottom);
  };

  // inputコンポーネントのケースとユニットとバラの計算
  const newCalcTotal = (
    index: number,
    value: string | number,
    valueKind: 'piece' | 'unit' | 'case'
  ) => {
    setInputAmountParams((newParams: Todo) => {
      const oldDetail = newParams[index];
      const newDetail = { ...oldDetail, [valueKind]: value };
      // TODO: piecesPerUnitを正しい値に設定
      calcTotalFunc(
        newDetail,
        selectedMaterial.piecesPerBox,
        selectedMaterial.piecesPerUnit
      );
      newParams[index] = newDetail;
      return newParams;
    });
    reRender();
  };

  // inputコンポーネントの日付の選択と更新
  const changeNewDate = (index: number, value: Todo) => {
    setInputAmountParams((newParams: Todo) => {
      const oldDate = newParams[index];
      const newDate = { ...oldDate, expirationDate: value };
      newParams[index] = newDate;
      return newParams;
    });
    reRender();
  };

  // 変更ボタン押下時処理--------
  const handleSuccess = () => {
    setIsDelete(false);
    setIsChanged(false);
    setMessageKind('update');
    setPopUp(!popUp);
    setInputAmountParams([]);
    setLatestData();
    setRightSlideSidebarActive(false);
    setComment('');
    setUpdating(false);
  };

  // 更新のmutation
  const updateMaterialStocksMutation = useUpdateMaterialStocksMutation(
    selectedMaterial?.materialId || 0,
    day.toDate(),
    selectedStoreroomId,
    handleSuccess,
    setErrMsg
  );

  const onClickSubmit = () => {
    // 日付重複チェック
    if (duplicateDateCheck(inputAmountParams)) {
      setErrMsg('同じ日付では登録できません。');
    } else if (inputAmountParams.some((ip: DetailItem) => ip.total === 0)) {
      setErrMsg('在庫数は必ず入力してください。');
    } else if (!(inputAmountParams.length > 0 || isChanged)) {
      setErrMsg('変更するデータはありません。');
    } else {
      setUpdating(true);
      updateMaterialStocksMutation.mutate({
        amountDetail: inputAmountParams,
        comment: comment,
      });
    }
  };

  const setLatestData = () => {
    refetch();
    setInputParams({
      amountDetail: selectedMaterial?.amountDetail || initializeAmountDetail,
      comment: selectedMaterial?.comment || '',
    });
    reRender();
  };

  const handleDeleteSuccess = () => {
    refetch();
    setLatestData();
    setDeleteId(null);
    setDeletePopUp(!deletePopUp);
    setIsChanged(false);
  };

  const deleteMaterialStocksMutation = useDeleteMaterialStocksMutation(
    deleteId,
    handleDeleteSuccess
  );

  // 削除ボタンでレコードを削除する
  const handleDelete = () => {
    deleteMaterialStocksMutation.mutate();
  };

  // 確認メッセージのOKボタン
  const handleOk = () => {
    setIsChanged(false);
    setConfirmMsg('');
    setInputAmountParams([]);
    setRightSlideSidebarActive(false);
    setComment(selectedMaterial?.comment ? selectedMaterial?.comment : '');
  };

  const handleCancel = () => {
    setConfirmMsg('');
  };

  useEffect(() => {
    // 既存データの設定
    if (
      selectedMaterial &&
      selectedMaterial?.amountDetail?.length > 0 &&
      rightSlideSidebarActive
    ) {
      setInputAmountParams(selectedMaterial.amountDetail);
    } else {
      setInitializeAmountDetail([]);
      // initializeAmountDetail[0]へinitを設定
      initializeAmountDetail.push(init);
      setInputAmountParams(initializeAmountDetail);
    }
    // 備考の設定
    if (rightSlideSidebarActive) {
      selectedMaterial &&
        setComment(comment ? comment : selectedMaterial?.comment);
    }
  }, [rightSlideSidebarActive]);

  if (!selectedMaterial) return null;

  return (
    <S.Wrapper open={rightSlideSidebarActive}>
      <ScrollToTop />
      <S.ScrollableContainer>
        <S.HeadContainer>
          <S.HeadDate>{formattedDate}</S.HeadDate>
          <S.HeadMaterialName>
            <S.HeadMaterialType materialType={selectedMaterial?.type}>
              {selectedMaterial?.type}
            </S.HeadMaterialType>
            {selectedMaterial?.materialName}
          </S.HeadMaterialName>
        </S.HeadContainer>

        <MaterialsForm
          material={selectedMaterial}
          inputParams={inputParams}
          setInputParams={setInputParams}
          inputAmountParams={inputAmountParams}
          setInputAmountParams={setInputAmountParams}
          newCalcTotal={newCalcTotal}
          changeNewDate={changeNewDate}
          handleDelete={handleDelete}
          deleteId={deleteId}
          setDeleteId={setDeleteId}
          setIsChanged={setIsChanged}
          comment={comment}
          setComment={setComment}
        />
        <S.ButtonContainer invalid={errMsg || confirmMsg ? true : false}>
          <CancelButton onClick={onClickCancel} disabled={updating}>
            キャンセル
          </CancelButton>
          <SubmitButton onClick={onClickSubmit} disabled={updating}>
            変更
          </SubmitButton>
        </S.ButtonContainer>
      </S.ScrollableContainer>
      <FloatingAddButton handleClick={handleClickFloatingAddButton} />
      <ErrorMsgPopUpForStock
        errMsg={errMsg}
        handleClose={() => setErrMsg('')}
        fromPc={true}
      />
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      {deletePopUp && <Overlay handleClick={() => setDeletePopUp(false)} />}
      <UpdatePopUp
        popUp={deletePopUp}
        handleClose={() => setDeletePopUp(false)}
        fromPc={true}
        taskKind={'stock'}
        messageKind={'delete'}
        isDelete
      />
    </S.Wrapper>
  );
};

export default RightSlideSidebar;
