import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as S from './Form.styles';
import { Heading } from '@components/elements/typographies/Heading';
import PdfContent from '@components/modules/pc/purchase_orders_edit/PdfContent/PdfContent';
import { Button } from '@components/elements/buttons/Button';
import { Todo } from '@lib/common/type';
import useUpdateMaterialOrderMutation from '@lib/pc/purchase_orders_edit/useUpdateMaterialOrderMutation';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import OrderDetailsWrapper from '../OrderDetailsWrapper/OrderDetailsWrapper';
import FormTop from '../FormTop/FormTop';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import { initMaterialOrderData } from '@lib/pc/purchase_orders_new/type';
import useManufacture from '@lib/pc/purchase_orders_new/useManufacture';
import useCreateMaterialOrderMutation from '@lib/pc/purchase_orders_new/useCreateMaterialOrderMutation';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import { PurchaseOrderState } from '@lib/pc/purchase_orders/type';
import useMaterials from '@lib/pc/purchase_orders/useMaterials';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';

type Props = {
  materialOrder: Todo;
  refetch?: () => void;
};

const Form: FC<Props> = ({ materialOrder, refetch }) => {
  const initDetail = {
    materialId: null,
    productName: '',
    unitPrice: 0,
    amount: 0,
    taxRate: 'ten_percent',
  };
  const { state } = useLocation<PurchaseOrderState>();
  const history = useHistory();
  const [newData, setNewData] = useState<Todo>(materialOrder);
  const [errMsg, setErrMsg] = useState('');
  const [popUp, setPopUp] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('');
  const [manufactureData, setManufactureData] = useState<Todo>(
    materialOrder?.manufacture
  );
  // Form全体に変更があった場合
  const [isChanged, setIsChanged] = useState(false);
  // 明細項目に変更があった場合
  const [isDetailChanged, setIsDetailChanged] = useState(false);
  const [newTotalPrice, setNewTotalPrice] = useState(0);
  const [newTotalTax, setNewTotalTax] = useState(0);
  const [newTotalPriceWithTax, setNewTotalPriceWithTax] = useState(0);
  const [selectedSupplierId, setSelectedSupplierId] = useState(
    materialOrder?.materialOrder?.supplierId
  );
  const [selectedHonorific, setSelectedHonorific] = useState(
    materialOrder?.materialOrder?.supplierHonorific
  );
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(
    materialOrder?.materialOrder?.supplierDepartmentId
  );
  // 取引先部署
  const [selectedDepartment, setSelectedDepartment] = useState<Todo>(
    materialOrder?.materialOrder?.supplierDepartments
  );
  // const [afterCreateOrder, setAfterCreateOrder] = useState(false);
  const [createdMaterialOrderId, setCreatedMaterialOrderId] = useState(0);

  const { data: manufacture } = useManufacture();

  const { materials: materials, refetch: refetchMaterial } =
    useMaterials(selectedSupplierId);

  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNewData({ ...newData, [e.target.name]: e.target.value });
    setIsChanged(true);
  };

  const handleClickAdd = () => {
    const details = newData.orderDetails;
    details.push(initDetail);
    setNewData({ ...newData, orderDetails: details });
    setIsDetailChanged(true);

    reRender();
  };

  // OKボタン押下時
  const handleClickOk = () => {
    setNewTotalPrice(0);
    setNewTotalTax(0);
    setNewTotalPriceWithTax(0);
    history.push({
      pathname: `/pc/purchase_orders`,
      state: {
        ...state,
        selectedMaterialOrderId: state.selectedMaterialOrderId,
        afterCreateOrder: false,
        afterCreateOrUpdate: false,
      },
    });
  };

  const handleSuccess = (isCreate: boolean) => {
    setPopUp((prev) => !prev);
    refetch?.();
    setIsChanged(false);
    setIsDetailChanged(false);
    setNewTotalPrice(0);
    setNewTotalTax(0);
    setNewTotalPriceWithTax(0);

    history.push({
      pathname: '/pc/purchase_orders',
      state: {
        ...state,
        selectedMaterialOrderId: isCreate
          ? createdMaterialOrderId
          : state.selectedMaterialOrderId,
        afterCreateOrder: isCreate,
        afterCreateOrUpdate: true,
      },
    });
  };

  const handleUpdateSuccess = () => handleSuccess(false);
  const handleCreateSuccess = () => handleSuccess(true);

  // 更新のmutation
  const updateMaterialOrderMutation = useUpdateMaterialOrderMutation(
    newData?.orderId,
    handleUpdateSuccess,
    setErrMsg
  );

  const createMaterialOrderMutation = useCreateMaterialOrderMutation(
    handleCreateSuccess,
    setCreatedMaterialOrderId
  );

  const isAllMaterialIdCheck = (orderDetails: Todo[]): boolean => {
    for (const detail of orderDetails) {
      if (detail.materialId === null) {
        return true;
      }
    }
    return false;
  };

  // 入力値チェック
  const handleCheckNewData = (newData: Todo) => {
    if (isAllMaterialIdCheck(newData.orderDetails)) {
      setErrMsg('明細品目は必ず入力してください。');
    } else {
      handleSubmitMaterialOrder(newData);
    }
  };

  const handleSubmitMaterialOrder = (newData: Todo) => {
    if (materialOrder?.materialOrder) {
      // 更新処理
      updateMaterialOrderMutation.mutate({
        supplierId: newData.supplierId,
        honorific: newData.supplierHonorific,
        supplierDepartmentId: newData.supplierDepartmentId,
        purchaseOrderNumber: newData.purchaseOrderNumber,
        confirmationDate: newData.confirmationDate,
        subject: newData.subject,
        requestedDeliveryDate: newData.deliveryDate,
        paymentTerm: newData.paymentTerm,
        deliveryDestination: newData.deliveryDestination,
        supplierDescription: newData.supplierDescription,
        comment: newData.comment ? newData.comment : '',
        orderDetails: newData.orderDetails,
      });
    } else {
      // 作成処理
      createMaterialOrderMutation.mutate({
        supplierId: newData.supplierId,
        honorific: newData.supplierHonorific,
        supplierDepartmentId: newData.supplierDepartmentId,
        purchaseOrderNumber: newData.purchaseOrderNumber,
        confirmationDate: newData.confirmationDate,
        subject: newData.subject,
        requestedDeliveryDate: newData.deliveryDate,
        paymentTerm: newData.paymentTerm,
        deliveryDestination: newData.deliveryDestination,
        supplierDescription: newData.supplierDescription,
        comment: newData.comment ? newData.comment : '',
        orderDetails: newData.orderDetails,
      });
    }
  };

  useEffect(() => {
    setNewData(materialOrder?.materialOrder);
    setManufactureData(materialOrder?.manufacture);
  }, [materialOrder?.materialOrder?.orderDetails]);

  useEffect(() => {
    if (!materialOrder?.materialOrder) {
      setNewData(initMaterialOrderData);
    }
  }, []);

  // 取引先が変わったらmaterialを取得し直す
  useEffect(() => {
    refetchMaterial();
  }, [selectedSupplierId]);

  useEffect(() => {
    setSelectedSupplierId(materialOrder?.materialOrder?.supplierId);
  }, [materialOrder?.materialOrder?.supplierId]);

  useEffect(() => {
    setSelectedHonorific(materialOrder?.materialOrder?.supplierHonorific);
  }, [materialOrder?.materialOrder?.supplierHonorific]);

  useEffect(() => {
    setSelectedDepartmentId(materialOrder?.materialOrder?.supplierDepartmentId);
  }, [materialOrder?.materialOrder?.supplierDepartments?.length]);

  return (
    <>
      <S.Wrapper>
        <S.HeadContainer>
          <S.PageTitle>
            <S.PageTitleText>
              <Heading text="発注情報" fs="32" fw="lg" />
              {materialOrder?.materialOrder ? (
                <Heading text="を編集" fs="24" fw="lg" />
              ) : (
                <Heading text="を作成" fs="24" fw="lg" />
              )}
            </S.PageTitleText>
          </S.PageTitle>
        </S.HeadContainer>

        <S.MainContent>
          <S.FormContainer>
            <FormTop
              newData={newData}
              setNewData={setNewData}
              handleChange={handleChange}
              setIsChanged={setIsChanged}
              selectedSupplierId={selectedSupplierId}
              setSelectedSupplierId={setSelectedSupplierId}
              selectedHonorific={selectedHonorific}
              setSelectedHonorific={setSelectedHonorific}
              selectedDepartmentId={selectedDepartmentId}
              setSelectedDepartmentId={setSelectedDepartmentId}
              selectedDepartment={selectedDepartment}
              setSelectedDepartment={setSelectedDepartment}
              isDetailChanged={isDetailChanged}
            />
            <S.FormLabel>明細</S.FormLabel>
            <S.ProductList>
              <S.ProductListHeader>
                <S.ProductName>品目</S.ProductName>
                <S.ProductUnitPrice>単価</S.ProductUnitPrice>
                <S.ProductUnitAmount>数量</S.ProductUnitAmount>
                <S.ProductUnitTaxRate>税率</S.ProductUnitTaxRate>
                <S.ProductDelete>削除</S.ProductDelete>
              </S.ProductListHeader>
              <S.ProductListBody>
                {newData?.orderDetails &&
                  newData?.orderDetails?.map((details: Todo, index: number) => (
                    <OrderDetailsWrapper
                      key={index}
                      index={index}
                      newData={newData}
                      edit={materialOrder?.materialOrder ? true : false}
                      materials={materials}
                      setNewData={setNewData}
                      setIsDetailChanged={setIsDetailChanged}
                      setNewTotalPrice={setNewTotalPrice}
                      setNewTotalTax={setNewTotalTax}
                      setNewTotalPriceWithTax={setNewTotalPriceWithTax}
                    />
                  ))}
              </S.ProductListBody>
              <Button
                borderWidth={1}
                outlined
                onClick={handleClickAdd}
                width="136px"
                margin="8px 0 0 0"
              >
                品目を追加
              </Button>
            </S.ProductList>
            <S.FormLabel>備考</S.FormLabel>
            <S.FormTextArea
              name="comment"
              id="comment"
              value={newData?.comment || ''}
              onChange={handleChange}
            />
          </S.FormContainer>
          {/* PDF */}
          <S.PdfViewContainer>
            <PdfContent
              newData={newData}
              manufacture={
                manufactureData ? manufactureData : manufacture?.manufactures
              }
              newTotalPrice={newTotalPrice}
              newTotalTax={newTotalTax}
              newTotalPriceWithTax={newTotalPriceWithTax}
            />
          </S.PdfViewContainer>
        </S.MainContent>

        <S.ButtonWrapper>
          {(isChanged || isDetailChanged) &&
          (materialOrder?.materialOrder?.orderStatus === 'draft' ||
            materialOrder?.materialOrder?.orderStatus === 'failed' ||
            materialOrder?.materialOrder?.orderStatus === undefined) ? (
            <S.ButtonContainer>
              <Button
                borderWidth={1}
                outlined
                onClick={() => {
                  setConfirmMsg(
                    '変更内容が保存されていませんが、よろしいですか？'
                  );
                }}
              >
                キャンセル
              </Button>
              <Button
                onClick={() => {
                  handleCheckNewData(newData);
                }}
              >
                保存
              </Button>
            </S.ButtonContainer>
          ) : (
            <S.OkButtonContainer>
              <Button onClick={() => handleClickOk()}>OK</Button>
            </S.OkButtonContainer>
          )}
        </S.ButtonWrapper>
      </S.Wrapper>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => setErrMsg('')}
        fromPc={true}
      />
      <UpdatePopUp
        popUp={popUp}
        handleClose={() => {
          setPopUp(false);
        }}
        fromPc={true}
        taskKind={'purchaseOrder'}
        messageKind={'update'}
      />
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        handleOk={() =>
          history.push({ pathname: `/pc/purchase_orders`, state })
        }
        handleCancel={() => setConfirmMsg('')}
      />
      {(confirmMsg || errMsg) && <Overlay dark zIndex={1} />}
    </>
  );
};

export default Form;
