import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 193px);
  overflow: scroll;
  padding-bottom: 150px;
  background-color: ${(props) => props.theme.palette.beige};
  .go-back {
    width: 250px;
    display: flex;
    padding: 16px 0 16px 80px;
    cursor: pointer;
    color: ${(props) => props.theme.palette.blue};
    > span {
      padding-top: 8px;
      padding-left: 10px;
    }
  }
`;

/**
 * 各商品の情報
 */
export const ProductMasterEditContents = styled.div`
  padding: 16px 80px;
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-template-rows: auto auto auto;
  gap: 24px 24px;
  grid-template-areas: 'product-img product-infos';

  /* 商品画像 **********/
  .product-img {
    grid-area: product-img;
    width: 120px;
    height: 120px;
    background-color: #ececec;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    label {
      img {
        width: 120px;
        height: 120px;
        object-fit: contain;
        cursor: pointer;
      }
      .add-img {
        cursor: pointer;
      }
    }
  }
  .qr {
    position: absolute;
    margin-top: 300px;
  }

  /* 基本情報 **********/
  /* 情報 */
  .product-infos {
    grid-area: product-infos;
    /* 商品名以外 */
    &__codes {
      display: flex;
      .product-info {
        margin-right: 32px;

        .quantity {
          display: flex;
          align-items: baseline;
          &__input {
            width: 8rem;
          }
          &__unit {
            display: block;
            padding-left: 8px;
            &__case {
              width: 5rem;
            }
          }
        }
      }
    }
    /* 各項目 */
    .product-info {
      margin-bottom: 16px;
      dt {
        font-weight: 700;
        margin-bottom: 8px;
      }
      &.number-of-days-before-expiration-date {
        padding-top: 24px;
        .number-of-days-before-expiration-date__input {
          width: 8rem;
        }
      }
    }
  }
`;

export const CircularIconWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ButtonContainer = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f7f4;
  & > button {
    width: 136px;
    &.disabled {
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: #6b675c;
      cursor: initial;
    }
  }
`;
