import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as S from './MaterialMasterEditWrapper.styles';
import MaterialInfo from '@components/modules/pc/settings/material_master_setting/MaterialMasterEdit/MaterialInfo/MaterialInfo';
import SupplierInfo from '@components/modules/pc/settings/material_master_setting/MaterialMasterEdit/SupplierInfo/SupplierInfo';
import TagInfo from '@components/modules/pc/settings/material_master_setting/MaterialMasterEdit/TagInfo/TagInfo';
import StoreroomInfo from '@components/modules/pc/settings/material_master_setting/MaterialMasterEdit/StoreroomInfo/StoreroomInfo';
import { Todo } from '@lib/common/type';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import useMaterials from '@lib/pc/settings/material_master_setting/useMaterials';
import { MaterialMasterSettingState } from '@lib/pc/settings/material_master_setting/type';

type Props = {
  material: Todo;
  refetch: () => void;
  timestamp: string;
};

export type InputParams = {
  time: string;
};

const MaterialMasterEditWrapper = ({ material, refetch }: Props) => {
  const { timestamp } = useParams<{ timestamp: string }>();
  const { state } = useLocation<MaterialMasterSettingState>();
  const history = useHistory();
  const [popUp, setPopUp] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [newMaterialData, setNewMaterialData] = useState<Todo>(
    material?.material
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('');
  const [selectedTabId, setSelectedTabId] = useState(0);
  // 保存ボタン有効化
  const [enableToSave, setEnableToSave] = useState(false);
  // ケース編集モード
  const [caseEditMode, setCaseEditMode] = useState(false);

  // 確認メッセージhandleOk判定用:一覧画面へ移動か、別タブ表示か。
  const [goBack, setGoBack] = useState(false);
  const { materials: allMaterials } = useMaterials();

  const MaterialSettingTabs = ['資材・原材料', '仕入先', '保管場所', 'タグ'];

  const defaultNewProductData = '';
  // ProductInfoで作成した新しいProduct
  const [materialFromInfo, setMaterialFromInfo] = useState(
    defaultNewProductData
  );

  const storeRoomAlert =
    material?.materialAndStorerooms?.length === 0 ? true : false;

  // 工程タブ、工程select選択可能
  const [, setSelectableProcess] = useState(false);

  const handleUpdateSuccess = () => {
    refetch();
    setUpdating(false);
    setPopUp(!popUp);
    setIsChanged(false);
    setUpdatePopUp(!updatePopUp);
    setEnableToSave(false);
    setCaseEditMode(false);
    if (selectedTabIndex === 1) {
      setSelectableProcess(true);
    }
  };

  // 一覧画面へ戻る
  const onClickGoBack = () => {
    if (isChanged) {
      setGoBack(true);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      history.push({
        pathname: `/pc/settings/material_master_setting`,
        state: {
          ...state,
          stateSearchWord: state.stateSearchWord ? state.stateSearchWord : '',
          stateSelectedTags: state.stateSelectedTags
            ? state.stateSelectedTags
            : [],
          stateSelectedTabId: 0,
          stateSelectedProductId: 0,
        },
      });
    }
  };

  const handleClickTab = (i: number) => {
    setGoBack(false);
    setCaseEditMode(false);
    // setEnableToSave(false);
    if (isChanged) {
      setSelectedTabId(i);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      setSelectedTabIndex(i);
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    // ここで一覧画面へ戻るか別タブを表示するかの判断が必要
    if (goBack) {
      history.push({
        pathname: `/pc/settings/material_master_setting`,
        state: {
          ...state,
          messageKind: '',
          productName: '',
          stateSelectedTabId: 0,
        },
      });
    } else {
      setSelectedTabIndex(selectedTabId);
    }
    setIsChanged(false);
    setConfirmMsg('');
  };
  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  useEffect(() => {
    if (state.stateSelectedTabId) {
      setSelectedTabIndex(state.stateSelectedTabId);
    } else {
      setSelectedTabIndex(0);
    }
  }, [state]);

  return (
    <div>
      <S.TabWrapper>
        {MaterialSettingTabs.map((d: string, i: number) => (
          <div key={d}>
            <S.TabArea>
              <S.Tab
                key={d}
                tabCount={MaterialSettingTabs.length}
                active={i == selectedTabIndex}
                onClick={() => {
                  handleClickTab(i);
                }}
              >
                {d}
              </S.Tab>
            </S.TabArea>
          </div>
        ))}
      </S.TabWrapper>

      <DndProvider backend={HTML5Backend}>
        {/* 資材・原材料 */}
        {selectedTabIndex === 0 && (
          <MaterialInfo
            material={material}
            updating={updating}
            setUpdating={setUpdating}
            setPopUp={setPopUp}
            newMaterialData={newMaterialData}
            setNewMaterialData={setNewMaterialData}
            handleUpdateSuccess={handleUpdateSuccess}
            onClickGoBack={onClickGoBack}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            allMaterials={allMaterials}
            materialFromInfo={materialFromInfo}
            setMaterialFromInfo={setMaterialFromInfo}
            timestamp={timestamp}
            storeRoomAlert={storeRoomAlert}
            materialTypes={material?.materialTypes}
            orderTypes={material?.orderTypes}
            labelOfAmounts={material?.labelOfAmounts}
            orderUnits={material?.orderUnits}
            labelOfUnits={material?.labelOfUnits}
          />
        )}
        {/* 仕入先 */}
        {selectedTabIndex === 1 && (
          <SupplierInfo
            material={material}
            updating={updating}
            setUpdating={setUpdating}
            onClickGoBack={onClickGoBack}
            handleUpdateSuccess={handleUpdateSuccess}
            setIsChanged={setIsChanged}
            productFromInfo={materialFromInfo}
            enableToSave={enableToSave}
            setEnableToSave={setEnableToSave}
            caseEditMode={caseEditMode}
            setCaseEditMode={setCaseEditMode}
          />
        )}
        {/* 保管場所 */}
        {selectedTabIndex === 2 && (
          <StoreroomInfo
            material={material}
            updating={updating}
            setUpdating={setUpdating}
            onClickGoBack={onClickGoBack}
            handleUpdateSuccess={handleUpdateSuccess}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            setEnableToSave={setEnableToSave}
            caseEditMode={caseEditMode}
            setCaseEditMode={setCaseEditMode}
          />
        )}
        {/* タグ */}
        {selectedTabIndex === 3 && (
          <TagInfo
            material={material}
            updating={updating}
            setUpdating={setUpdating}
            onClickGoBack={onClickGoBack}
            handleProductUpdateSuccess={handleUpdateSuccess}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
          />
        )}
      </DndProvider>
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <UpdatePopUp
        popUp={updatePopUp}
        taskKind={'messageOnly'}
        messageKind={'update'}
        handleClose={() => setUpdatePopUp(false)}
      />

      {updatePopUp && <Overlay handleClick={() => setUpdatePopUp(false)} />}
    </div>
  );
};

export default MaterialMasterEditWrapper;
