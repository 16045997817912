import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { SemiProductsProcess } from '@lib/common/type';

const useProductsProcesses = () => {
  return useQuery('semi_products_processes', async () => {
    const { data } = await axiosInstance().get(
      '/api/v1/semi_products_processes'
    );
    return humps.camelizeKeys(
      data.semi_products_processes
    ) as SemiProductsProcess[];
  });
};

export default useProductsProcesses;
