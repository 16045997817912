import { useState } from 'react';
import * as S from './StoreroomInfo.styles';
import ProductImg from '@assets/images/product-img.jpg';
import { ReactComponent as PencilIcon } from '@assets/icons/pencil.svg';
import { ReactComponent as PlusBlueIcon } from '@assets/icons/mobile/plus_blue.svg';
import ChevronLeft from '@assets/icons/chevron_left';
import { Button } from '@components/elements/buttons/Button';
import StoreRoomModal from '@components/modules/pc/settings/common/StoreRoomModal/StoreRoomModal';
import { Todo } from '@lib/common/type';
import { StoreroomWithDisplayOrder } from '@lib/pc/settings/product_master_edit/type';
import useUpdateProductAndStoreroomsMutation from '@lib/pc/settings/product_master_edit/useUpdateProductsAndStorerooms';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';

type Props = {
  product: Todo;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  onClickGoBack: () => void;
  handleProductUpdateSuccess: () => void;
  isChanged: boolean;
  setIsChanged: (isChanged: boolean) => void;
  setEnableToSave: (enableToSave: boolean) => void;
  caseEditMode: boolean;
  setCaseEditMode: (caseEditMode: boolean) => void;
};

const StoreroomInfo = ({
  product,
  updating,
  setUpdating,
  onClickGoBack,
  handleProductUpdateSuccess,
  isChanged,
  setIsChanged,
  setEnableToSave,
  caseEditMode,
  setCaseEditMode,
}: Props) => {
  const [storeRoomModalActive, setStoreRoomModalActive] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const newDataForUpdate = product?.storerooms
    ? product?.storerooms.map((value: StoreroomWithDisplayOrder) => {
        return {
          productId: product.product.id,
          id: value.id,
          name: value.name,
          displayOrder: value.displayOrder,
          editMode: false,
        };
      })
    : [];

  // 更新に使用
  const [newProductAndStoreroomsArray, setNewProductAndStoreroomsArray] =
    useState<StoreroomWithDisplayOrder[]>(newDataForUpdate);

  // 商品と在庫室テーブル
  const updateProductAndStoreroomsMutation =
    useUpdateProductAndStoreroomsMutation(
      product?.id || product?.product.id,
      handleProductUpdateSuccess,
      setErrMsg
    );

  const onClickEdit = (index: number) => {
    setEditOpen(!editOpen);
    setCaseEditMode(true);
    newProductAndStoreroomsArray[index].editMode = true;
  };

  const handleChangeDisplayOrder = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setEnableToSave(true);
    setIsEdited(!isEdited);
    setIsChanged(true);
    if (e.target.value.match(/^[0-9]*$/)) {
      newProductAndStoreroomsArray[index].displayOrder = e.target.value
        ? Number(e.target.value)
        : '';
    }
  };

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmit = () => {
    setUpdating(true);

    updateProductAndStoreroomsMutation.mutate(newProductAndStoreroomsArray);
  };

  return (
    <>
      {product && (
        <>
          <S.Wrapper>
            <div className="go-back" onClick={onClickGoBack}>
              <ChevronLeft isBlue={true} />
              <span>一覧画面へ戻る</span>
            </div>

            <S.ProductMasterEditContents>
              <div className="product-info">
                <div className="product-img">
                  <div className="img">
                    <img
                      src={
                        product.imageUrl ||
                        product.product?.imageUrl ||
                        ProductImg
                      }
                    />
                  </div>
                </div>
                <div className="name">
                  {product.name || product.product?.name}
                </div>
              </div>
              {/* 保管場所 */}
              <div className="storage-location-container">
                <S.StoreroomArrayTable>
                  <thead>
                    <tr>
                      <S.StoreroomTitleTh>保管場所</S.StoreroomTitleTh>
                      <S.DisplayOrderTitleTh>表示順</S.DisplayOrderTitleTh>
                    </tr>
                  </thead>
                  <tbody>
                    {newProductAndStoreroomsArray &&
                      newProductAndStoreroomsArray.map(
                        (sr: StoreroomWithDisplayOrder, index: number) => {
                          return (
                            <S.SelectedSToreroomWrapperTr key={index}>
                              <S.StoreroomNameTd>{sr.name}</S.StoreroomNameTd>
                              {/* 表示順 */}
                              <S.DisplayOrderTd editMode={sr.editMode}>
                                {sr.editMode && caseEditMode ? (
                                  <S.DisplayOrderInput
                                    type="text"
                                    inputMode="numeric"
                                    id={`displayOrderInput-${index}`}
                                    autoComplete="off"
                                    value={sr.displayOrder}
                                    onChange={(e) =>
                                      handleChangeDisplayOrder(e, index)
                                    }
                                  />
                                ) : (
                                  <S.DisplayOrderDiv editMode={sr.editMode}>
                                    {sr.displayOrder}
                                  </S.DisplayOrderDiv>
                                )}
                              </S.DisplayOrderTd>

                              <S.EditButtonTd>
                                <S.EditButton
                                  onClick={() => {
                                    // 編集モード
                                    onClickEdit(index);
                                  }}
                                >
                                  <PencilIcon />
                                </S.EditButton>
                              </S.EditButtonTd>
                            </S.SelectedSToreroomWrapperTr>
                          );
                        }
                      )}
                  </tbody>
                </S.StoreroomArrayTable>
                <div
                  className="add-button"
                  onClick={() => setStoreRoomModalActive(true)}
                >
                  <S.PlusIcon>
                    <PlusBlueIcon />
                  </S.PlusIcon>
                  <span className="label">保管場所を選択</span>
                </div>
              </div>
            </S.ProductMasterEditContents>
          </S.Wrapper>
          {storeRoomModalActive && (
            <StoreRoomModal
              newProductAndStoreroomsArray={newProductAndStoreroomsArray}
              setNewProductAndStoreroomsArray={setNewProductAndStoreroomsArray}
              storeRoomModalActive={storeRoomModalActive}
              handleClose={() => setStoreRoomModalActive(false)}
              setIsChanged={setIsChanged}
            />
          )}
        </>
      )}
      <S.ButtonContainer>
        {isChanged ? (
          <Button
            children={updating ? '保存中...' : '保存'}
            onClick={handleSubmit}
          />
        ) : (
          <Button disabled children={'保存'} />
        )}
      </S.ButtonContainer>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default StoreroomInfo;
