import { useMutation } from 'react-query';
import humps from 'humps';
import { AxiosError } from 'axios';
import { ErrorResponse } from '@lib/common/type';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import type { MaterialInputParams } from '@lib/pc/settings/material_master_setting/type';

const useUpdateMaterialMutation = (
  materialId: number,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: MaterialInputParams) => {
      return await axiosInstance().patch(
        `/api/v1/settings/material/${materialId}`,
        humps.decamelizeKeys({
          material: {
            material_type: params.materialType,
            material_code: params.materialCode,
            expiry_days: params.expiryDays,
            name: params.name,
            name_kana: params.nameKana,
            name_abbreviation: params.nameAbbreviation,
            pieces_per_unit: params.piecesPerUnit,
            label_of_amount: params.labelOfAmount,
            pieces_per_box: params.piecesPerBox,
            label_of_unit: params.labelOfUnit,
            default_order_amount: params.defaultOrderAmount,
            order_unit: params.orderUnit,
            order_type: params.orderType,
            alert_threshold: params.alertThreshold,
            hidden: params.hidden,
            image_data_uri: params.imageDataUri,
          },
        })
      );
    },
    {
      onSuccess: () => {
        handleSuccess?.();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useUpdateMaterialMutation;
