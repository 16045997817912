import { FC } from 'react';
import * as S from './TagMenu.styles';
import { ReactComponent as FilterListIcon } from '@assets/icons/mobile/filter_list.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/close_24px.svg';
import { ReactComponent as SearchIcon } from '@assets/icons/search.svg';
import { ReactComponent as TagIcon } from '@assets/icons/mobile/tag.svg';
import SelectedTagList from '@components/modules/pc/selected_tag_list/SelectedTagList';
import type { Tag } from '@lib/common/type';

type Props = {
  searchParam: string;
  selectedTags: Tag[];
  setSelectedTags: (tags: Tag[]) => void;
  setOpenTagModal: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  top?: string;
  right?: string;
  left?: string;
  title?: string;
  deleteSearchParam: () => void;
};

const TagMenu: FC<Props> = ({
  searchParam,
  selectedTags,
  setSelectedTags,
  setOpenTagModal,
  handleChange,
  top,
  right,
  left,
  title,
  deleteSearchParam,
}: Props) => {
  const editedPlaceHolder = title
    ? title + '/コードで検索'
    : '商品/コードで検索';

  return (
    <S.Wrapper top={top} right={right} left={left}>
      <S.Header>
        <S.HeaderTitle>
          <FilterListIcon />
          <S.HeaderTitleText>
            {title ? title : '商品'}の絞り込み
          </S.HeaderTitleText>
        </S.HeaderTitle>
      </S.Header>
      <S.Main>
        <S.InputWrapper
          onClick={() => {
            setOpenTagModal();
          }}
        >
          <S.Icon>
            <TagIcon />
          </S.Icon>
          <S.Input
            type="text"
            placeholder={
              selectedTags.length ? '選択されたタグ' : 'タグで絞り込み'
            }
            tagSelected={selectedTags.length ? true : false}
            readOnly
          />
        </S.InputWrapper>
        <div>
          {selectedTags.length === 0 ? (
            ''
          ) : (
            <SelectedTagList
              tags={selectedTags}
              setOpenTagModal={setOpenTagModal}
              clearAll={() => setSelectedTags([])}
              clear={(tagId) =>
                setSelectedTags(selectedTags.filter((t) => t.id !== tagId))
              }
            />
          )}
        </div>
        <S.InputWrapper>
          <S.Icon>
            <SearchIcon />
          </S.Icon>
          <S.Input
            type="text"
            placeholder={editedPlaceHolder}
            value={searchParam}
            onChange={handleChange}
          />
          {/* 検索エリアバツボタン */}
          {searchParam && (
            <S.DeleteIcon onClick={() => deleteSearchParam()}>
              <DeleteIcon />
            </S.DeleteIcon>
          )}
        </S.InputWrapper>
      </S.Main>
    </S.Wrapper>
  );
};
export default TagMenu;
