/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from 'react';
import moment from 'moment';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
} from 'victory';
import { ModalPaper } from '@components/modules/common/pc/ModalPaper';
import {
  convertArrToBarData,
  // convertDataForTable,
  convertActualOrSafetyStockBarData,
  convertStockBarData,
  generateDateArray,
  isSameDayWithOutTime,
  addComma,
} from '@lib/pc/stock_status/functions';
import * as S from './StockStatusGraphWithTable.styles';
// import StockStatusGraphWithTableSideBar from '@components/modules/pc/stock_status/StockStatusGraphWithTableSideBar/StockStatusGraphWithTableSideBar';

//TODO: type
type Props = {
  selectedDate: moment.Moment;
  data: any;
};

const StockStatusGraphWithTable: FC<Props> = ({
  selectedDate,
  data,
}: Props) => {
  const [openIndex, setOpenIndex] = useState<number[]>([]);
  const today = new Date();

  const handleClickAccordion = (index: number) => {
    openIndex.includes(index)
      ? setOpenIndex(openIndex.filter((i) => i !== index))
      : setOpenIndex([...openIndex, index]);
  };

  if (!data) return null;

  // const amountValue = convertDataForTable(data.stocks);

  return (
    <ModalPaper heading1="在庫状況の分析">
      <S.ModalHeader>
        <S.FlexContainer>
          <S.FlexContainer>
            <S.ColorBox bgColor="#F6C1B1" />
            現物在庫数
          </S.FlexContainer>
          <S.FlexContainer>
            <S.DotDividerWrapper>
              <S.DotDivider />
            </S.DotDividerWrapper>
            理想在庫数 (当日を含めて{data.safetyStockDays}
            日分の出荷予測数を満たす数量)
          </S.FlexContainer>
        </S.FlexContainer>
      </S.ModalHeader>
      <S.GraphContainer>
        <S.ScrollableContainer>
          {/* COMMENT:交互背景は時間かかりそう
      https://github.com/FormidableLabs/victory/issues/1601 */}
          <S.LeftLabelContainer>
            <S.LeftGraphLabelContainer>
              <div>
                <VictoryChart
                  domainPadding={{ x: 50 }}
                  width={120}
                  height={320}
                  padding={{ top: 50, bottom: 0, right: 0, left: 0 }}
                  containerComponent={<VictoryContainer responsive={false} />}
                >
                  <VictoryAxis
                    width={130}
                    height={400}
                    dependentAxis
                    offsetX={120}
                    standalone
                    style={{
                      axisLabel: {
                        fontSize: 16,
                        padding: 30,
                        zIndex: 100,
                      },
                    }}
                  />
                  <VictoryGroup
                    groupComponent={<g display="none" />}
                    offset={20}
                  >
                    {/* グラフ左のY軸の値 */}
                    <VictoryBar
                      barWidth={16}
                      data={convertActualOrSafetyStockBarData(data.stocks)}
                      style={{ data: { display: 'none' } }}
                    />
                  </VictoryGroup>
                </VictoryChart>
              </div>
            </S.LeftGraphLabelContainer>
          </S.LeftLabelContainer>
          <VictoryChart
            height={320}
            width={900}
            domainPadding={{ x: 73 }}
            padding={{ top: 50, bottom: 0, right: -50, left: 90 }}
            containerComponent={<VictoryContainer responsive={false} />}
          >
            <VictoryAxis
              dependentAxis
              //x
            />
            <VictoryAxis
              dependentAxis
              //y
              style={{
                grid: { stroke: '#E7E2DD' },
              }}
            />
            <VictoryGroup>
              <VictoryBar
                barWidth={16}
                style={{ data: { fill: '#F6C1B1' } }}
                data={convertStockBarData(data.stocks)}
              />
            </VictoryGroup>
            <VictoryGroup
              data={convertArrToBarData(data.stocks)}
              color="#106CAE'"
            >
              <VictoryLine
                style={{
                  data: {
                    stroke: '#0A68F4',
                    strokeDasharray: '4, 2',
                    strokeWidth: 2,
                  },
                }}
              />
              <VictoryScatter
                size={3}
                style={{
                  data: {
                    fill: '#ffffff',
                    stroke: '#106CAE',
                    strokeWidth: 3,
                  },
                }}
              />
            </VictoryGroup>
          </VictoryChart>

          <S.TableWrapper>
            <S.TableRow>
              <S.TableAccordionHeadCell bgColor="transparent" />
              {generateDateArray(6, selectedDate).map((day: any, i: any) => {
                return (
                  <S.TableHeadCell
                    key={`日付け${i}`}
                    isToday={isSameDayWithOutTime(day.productionDate, today)}
                    dayOfWeek={moment(day.productionDate).format('ddd')}
                  >
                    <div>
                      {moment(day.productionDate).format('MM/DD')}
                      <span>{moment(day.productionDate).format('(ddd)')}</span>
                    </div>
                  </S.TableHeadCell>
                );
              })}
            </S.TableRow>
            <S.TableRow>
              <S.TableAccordionHeadCell color="#106CAE" bgColor="#E7E2DD">
                理想在庫数
              </S.TableAccordionHeadCell>
              {data.stocks &&
                data.stocks.map((p: any, i: number) => {
                  return (
                    <S.TableCell
                      key={`理想在庫数${i}`}
                      isToday={isSameDayWithOutTime(p.date, today)}
                    >
                      {addComma(p.safetyStocks)}
                    </S.TableCell>
                  );
                })}
            </S.TableRow>
            <S.AccordionTableHeadRow onClick={() => handleClickAccordion(0)}>
              <S.TableAccordionHeadCell bgColor="#F6C1B1">
                在庫数合計
              </S.TableAccordionHeadCell>
              {data.stocks &&
                data.stocks.map((p: any, i: number) => {
                  return (
                    <S.TableCell
                      key={`在庫数合計${i}`}
                      isToday={isSameDayWithOutTime(
                        moment(p.date).toDate(),
                        today
                      )}
                      lastCell
                    >
                      {addComma(p.amount)}
                    </S.TableCell>
                  );
                })}
            </S.AccordionTableHeadRow>
            {/* <S.AccordionWrapper>
              <div style={{ display: 'flex' }}>
                <StockStatusGraphWithTableSideBar />
                {Object.values(amountValue).map((cData: any, i: number) => {
                  return (
                    <S.TableRow key={`完成品D${i}`}>
                      <S.Column
                        key={`完成品column${i}`}
                        isToday={isSameDayWithOutTime(cData.key, today)}
                        even={i % 2 === 0}
                      >
                        <S.ValueCellWrapper>
                          {cData.value.map((amount: any, i: any) =>
                            i % 2 === 0 ? (
                              <S.EvenCell key={`even${i}`}>
                                {amount === 0 ? '' : amount}
                              </S.EvenCell>
                            ) : (
                              <S.ValueCell key={`notEven${i}`}>
                                {amount === 0 ? '' : amount}
                              </S.ValueCell>
                            )
                          )}
                        </S.ValueCellWrapper>
                      </S.Column>
                    </S.TableRow>
                  );
                })}
              </div>
            </S.AccordionWrapper> */}
          </S.TableWrapper>
        </S.ScrollableContainer>
      </S.GraphContainer>
    </ModalPaper>
  );
};

export default StockStatusGraphWithTable;
