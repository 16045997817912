import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { FC } from 'react';
import * as S from './MaterialFormWrapper.styles';
import MaterialForm from '@components/modules/mobile/materials_stocks_edit/MaterialForm/MaterialForm';
import TitleBar from '@components/modules/mobile/materials_stocks_edit/TitleBar/TitleBar';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import type { Todo } from '@lib/common/type';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import FloatingAddButton from '@components/modules/common/mobile/FloatingAddButton/FloatingAddButton';
import {
  totalCheck,
  calcTotalFunc,
  duplicateDateCheck,
} from '@lib/pc/materials_inventory/functions';
import { MaterialStocks } from '@lib/mobile/materials_stocks_edit/type';
import useUpdateMaterialStocksMutation from '@lib/mobile/materials_stocks_edit/useUpdateMaterialStocksMutation';
import useDeleteMaterialStocksMutation from '@lib/mobile/materials_stocks_edit/useDeleteMaterialStocksMutation';

type Props = {
  id: string;
  date: Date;
  inputAmountParams: Todo;
  setInputAmountParams: (inputAmountParams: Todo) => void;
  stock_kind: string;
  storeroom_id: string;
  material: MaterialStocks;
  stocks: Todo[];
  refetch: () => void;
};

const MaterialFormWrapper: FC<Props> = ({
  id,
  date,
  storeroom_id,
  material,
  stocks,
  refetch,
}: Props) => {
  const initializeAmount = {
    id: '',
    piece: '',
    unit: '',
    case: '',
    total: 0,
    expirationDate: '',
  };

  // TODO: APIでくるのは数値で入力値は文字列なので変換をどこかでしないといけない
  const [initializeAmountDetail] = useState<Todo>(initializeAmount);
  const stock = stocks?.find((stock) => moment(date).isSame(stock.date, 'day'));
  const [inputParams, setInputParams] = useState<Todo>({
    amountDetail: stock?.amountDetail || initializeAmountDetail,
    comment: stock?.comment || '',
  });
  const [comment, setComment] = useState('');
  // SidebarInputの入力フォーム（日付、ケース、バラ）、備考への変更有無
  const [isChanged, setIsChanged] = useState(false);
  // 日付選択のカウント
  const [count, setCount] = useState(0);
  // 削除ボタン押した日付をセット
  const [deleteId, setDeleteId] = useState<number | null>(null);
  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);
  const [popUp, setPopUp] = useState(false);
  const [messageKind, setMessageKind] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [confirmMsg, setConfirmMsg] = useState('');
  const [confirmKind, setConfirmKind] = useState('');

  const history = useHistory();
  const { state } = useLocation();

  const formattedDate = moment(date).format('YYYY/MM/DD (ddd)');

  // 入力エリア初期値
  const init = {
    id: '',
    piece: '',
    unit: '',
    case: '',
    total: 0,
    expirationDate: '',
  };

  const handleClickFloatingAddButton = () => {
    const amountDetail = inputParams.amountDetail;
    amountDetail.push(init);
    setInputParams({
      ...inputParams,
      amountDetail: amountDetail,
    });
    setPopUp(false);
    const element = document.documentElement;
    const bottom = element.scrollHeight - element.clientHeight;
    window.scroll(0, bottom);
  };

  // 基準日1日戻る
  const getBeforeDate = (date: Date) => {
    setPopUp(false);
    if (isChanged) {
      setConfirmMsg('保存せずに編集を終了します。よろしいですか。');
      setConfirmKind('BeforeDate');
    } else {
      getBeforeDateAfterConfirm(date);
      reRender();
    }
  };

  // 前の日
  const getBeforeDateAfterConfirm = async (date: Date) => {
    date.setDate(date.getDate() - 1);
    setCount(count - 1);
    const stock = stocks?.find((stock) =>
      moment(date).isSame(stock.date, 'day')
    );
    const comment = stock.comment === null ? '' : stock.comment;
    setComment(comment);

    // 実験:これだと日付移動がうまく動く。
    setInputParams({
      amountDetail:
        stocks?.find((stock) => moment(date).isSame(stock.date, 'day'))
          ?.amountDetail || initializeAmountDetail,
      comment:
        stocks?.find((stock) => moment(date).isSame(stock.date, 'day'))
          ?.comment || '',
    });
  };

  // 基準日1日進む
  const getNextDate = (date: Date) => {
    setPopUp(false);
    if (isChanged) {
      setConfirmMsg('保存せずに編集を終了します。よろしいですか。');
      setConfirmKind('NextDate');
    } else {
      getNextDateAfterConfirm(date);
      reRender();
    }
  };

  // 次の日
  const getNextDateAfterConfirm = (date: Date) => {
    // 現在日付けが過去日のときのみ、日付を進められる。
    if (count < 0) {
      date.setDate(date.getDate() + 1);
      setCount(count + 1);
    }
    const stock = stocks?.find((stock) =>
      moment(date).isSame(stock.date, 'day')
    );
    const comment = stock.comment === null ? '' : stock.comment;

    setComment(comment);
    // 実験:これだと日付移動がうまく動く。
    setInputParams({
      amountDetail:
        stocks?.find((stock) => moment(date).isSame(stock.date, 'day'))
          ?.amountDetail || initializeAmountDetail,
      comment:
        stocks?.find((stock) => moment(date).isSame(stock.date, 'day'))
          ?.comment || '',
    });
    reRender();
  };

  const piecesPerBox = material.piecesPerBox ? material.piecesPerBox : 1;
  const piecesPerUnit = material.piecesPerUnit ? material.piecesPerUnit : 1;

  // ケース、バラ単位とバラの計算
  const newCalcTotal = (
    index: number,
    value: string | number,
    valueKind: 'piece' | 'unit' | 'case'
  ) => {
    // 実験
    setInputParams((newParams: Todo) => {
      const oldDetail = newParams.amountDetail[index];
      const newDetail = { ...oldDetail, [valueKind]: value };
      calcTotalFunc(newDetail, piecesPerBox, piecesPerUnit);
      newParams.amountDetail[index] = newDetail;
      return newParams;
    });
    reRender();
  };

  // inputコンポーネントの日付の選択と更新
  const changeNewDate = (index: number, value: Todo) => {
    // 全体的にinputParamsへ変更する
    setInputParams((newParams: Todo) => {
      const oldDetail = newParams.amountDetail[index];
      const newDetail = { ...oldDetail, expirationDate: value };
      newParams.amountDetail[index] = newDetail;
      return newParams;
    });
    reRender();
  };

  const setLatestData = async () => {
    await refetch();
    const stock = await stocks?.find((stock) =>
      moment(date).isSame(stock.date, 'day')
    );
    setInputParams({
      amountDetail: stock?.amountDetail || initializeAmountDetail,
      comment: stock?.comment || '',
    });
    reRender();
  };

  const handleDeleteSuccess = () => {
    setLatestData();
    setDeleteId(null);
    setIsDelete(true);
    setIsChanged(false);
    setMessageKind('delete');
    setPopUp(!popUp);
  };
  const deleteStockMutation = useDeleteMaterialStocksMutation(
    deleteId,
    handleDeleteSuccess
  );
  // 削除ボタンでレコードを削除する
  const handleDelete = () => {
    deleteStockMutation.mutate();
  };

  const handleSuccess = () => {
    setLatestData();
    setIsDelete(false);
    setIsChanged(false);
    setMessageKind('create');
    setPopUp(!popUp);
  };

  const updateMaterialStocksMutation = useUpdateMaterialStocksMutation(
    id,
    date,
    storeroom_id,
    handleSuccess,
    setErrMsg
  );

  const onClickSubmit = () => {
    if (duplicateDateCheck(inputParams.amountDetail)) {
      setErrMsg('同じ日付では登録できません。');
    } else if (totalCheck(inputParams.amountDetail)) {
      setErrMsg('在庫数は必ず入力してください。');
    } else if (!isChanged) {
      setErrMsg('変更するデータはありません。');
    } else {
      updateMaterialStocksMutation.mutate({
        amountDetail: inputParams.amountDetail,
        comment: inputParams.comment,
      });
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    setIsChanged(false);
    setConfirmMsg('');
    if (confirmKind === 'ProductList') {
      history.push(`/mobile/materials/for_stocks`, state);
    } else if (confirmKind === 'NextDate') {
      getNextDateAfterConfirm(date);
    } else if (confirmKind === 'BeforeDate') {
      getBeforeDateAfterConfirm(date);
    }
  };
  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  // データ作成、更新後、作成したデータ表示に必要
  useEffect(() => {
    const stock = material?.stocks?.find((stock) =>
      moment(date).isSame(stock.date, 'day')
    );
    const comment =
      stock?.comment !== null || stock?.comment !== undefined
        ? stock?.comment
        : '';
    comment && setComment(comment);
    setInputParams({
      amountDetail: stock?.amountDetail || initializeAmountDetail,
      comment: stock?.comment || '',
    });
    reRender();
  }, [stocks]);

  return (
    <>
      <TitleBar
        materialType={material.materialType}
        materialName={material.name}
        isChanged={isChanged}
        setConfirmMsg={setConfirmMsg}
        setConfirmKind={setConfirmKind}
        setPopUp={setPopUp}
      />

      <MaterialForm
        material={material}
        inputParams={inputParams}
        selectedDate={date}
        count={count}
        amountDetail={inputParams.amountDetail}
        newCalcTotal={newCalcTotal}
        changeNewDate={changeNewDate}
        handleDelete={handleDelete}
        deleteId={deleteId}
        setDeleteId={setDeleteId}
        getBeforeDate={getBeforeDate}
        getNextDate={getNextDate}
        setIsChanged={setIsChanged}
        comment={comment}
        setComment={setComment}
        setInputParams={setInputParams}
        setPopUp={setPopUp}
      />
      <S.Footer>
        <S.SubmitButton
          onClick={() => {
            onClickSubmit();
          }}
        >
          在庫を登録する
        </S.SubmitButton>
      </S.Footer>
      <UpdatePopUp
        popUp={popUp}
        formattedDate={formattedDate}
        productName={material.name}
        handleClose={() => {
          setPopUp(!popUp);
        }}
        fromPc={false}
        isDelete={isDelete}
        taskKind={'stock'}
        messageKind={messageKind}
      />
      <ConfirmPopUp
        fromPc={false}
        confirmMsg={confirmMsg}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <FloatingAddButton
        handleClick={handleClickFloatingAddButton}
        right="2rem"
      />
      <ErrorMsgPopUp errMsg={errMsg} handleClose={() => setErrMsg('')} />
    </>
  );
};

export default MaterialFormWrapper;
