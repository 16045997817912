import { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import * as S from './TableWithFilter.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import ProductImg from '@assets/images/product-img.jpg';
import { ReactComponent as CautionIcon } from '@assets/icons/caution.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/check_square_24px.svg';
import { ReactComponent as DuplicateIcon } from '@assets/icons/duplicate.svg';
import DeleteConfirmModal from '@components/modules/pc/settings/common/DeleteConfirmModal/DeleteConfirmModal';
import DeleteErrorModal from '@components/modules/pc/settings/common/DeleteErrorModal/DeleteErrorModal';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import { tagSearch } from '@lib/common/functions';
import useDeleteProduct from '@lib/pc/settings/product_master/useDeleteProduct';
import type { Storeroom, Tag, Todo } from '@lib/common/type';
import {
  DuplicateProduct,
  ProductMasterSettingState,
} from '@lib/pc/settings/product_master_setting/type';

type Props = {
  products: Todo;
  refetch: () => void;
  editData: Todo;
  setEditData: (editData: Todo) => void;
  popUp: boolean;
  setPopUp: (popUp: boolean) => void;
  messageKind: string;
  setMessageKind: (messageKind: string) => void;
  productName: string;
  setProductName: (productName: string) => void;
  searchWord: string;
  setSearchWord: (searchWord: string) => void;
  selectedTags: Todo[];
  setSelectedTags: (selectedTags: Todo[]) => void;
  selectedStorerooms: Storeroom[];
  handleSuccessEditSubmit: () => void;
  tagMenuActive: boolean;
  setTagMenuActive: (tagMenuActive: boolean) => void;
};

const TableWithFilter = ({
  products,
  refetch,
  editData,
  setEditData,
  popUp,
  setPopUp,
  messageKind,
  setMessageKind,
  productName,
  setProductName,
  searchWord,
  setSearchWord,
  selectedTags,
  setSelectedTags,
  selectedStorerooms,
  handleSuccessEditSubmit,
  tagMenuActive,
  setTagMenuActive,
}: Props) => {
  const { state } = useLocation<ProductMasterSettingState>();
  const history = useHistory();
  // タグの検索用にfilter使用
  const selectedTagIds = selectedTags
    ? selectedTags.map((item: Tag) => item['id'])
    : [];

  const [stateScrollPosition, setScrollPosition] = useState<number | null>(
    null
  );

  // 編集、削除メニューの表示
  const [editMenuOpen, setEditMenuOpen] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteError, setDeleteError] = useState(false);

  const handleDeleteProduct = () => {
    deleteProduct.mutate();
    setMessageKind('delete');
    setProductName(editData?.product?.name);
  };

  const handleProductDeleteSuccess = () => {
    handleSuccessEditSubmit();
    refetch();
    setDeleteConfirm(false);
    setMessageKind('delete');
    setPopUp(!popUp);
  };

  const deleteProduct = useDeleteProduct(
    editData?.product?.id,
    handleProductDeleteSuccess,
    setDeleteError,
    setDeleteConfirm
  );

  const onClickDuplicate = async (p: DuplicateProduct) => {
    const data = { ...p, id: null, imageUrl: null };
    history.push(`/pc/settings/product_master_setting_create`, {
      ...productMasterPickingState,
      stateScrollPosition: stateScrollPosition,
      stateSearchWord: searchWord,
      stateSelectedTags: selectedTags,
      editData: data,
    });
  };

  useEffect(() => {
    if (state === undefined) {
      document?.getElementById('table-contents')?.scrollTo(0, 0);
      setSearchWord('');
      setSelectedTags([]);
    } else {
      const { stateScrollPosition, stateSearchWord, stateSelectedTags } = state;
      setSearchWord(stateSearchWord);
      setSelectedTags(stateSelectedTags);
      document
        ?.getElementById('table-contents')
        ?.scrollTo(0, stateScrollPosition);
    }
  }, [state]);

  const productMasterPickingState = {
    stateScrollPosition: stateScrollPosition,
    stateSearchWord: searchWord,
    stateSelectedTags: selectedTags,
  };

  useEffect(() => {
    refetch();
  }, [selectedStorerooms, products?.length]);

  // スクロール位置保存
  document?.getElementById('table-contents')?.addEventListener('scroll', () => {
    const currentScrollPosition =
      document?.getElementById('table-contents')?.scrollTop;
    if (currentScrollPosition) {
      setScrollPosition(currentScrollPosition);
    }
  });

  const FilterFunctionIcon = () => {
    return (
      <S.ListLeftHeadCell
        onClick={() => setTagMenuActive(!tagMenuActive)}
        isBlue={searchWord !== '' || selectedTags.length > 0}
      >
        <span className="filter-product">
          {searchWord !== '' || selectedTags.length > 0 ? (
            <FilteredIcon />
          ) : (
            <Filter isBlue={searchWord !== '' || selectedTags.length > 0} />
          )}
        </span>
      </S.ListLeftHeadCell>
    );
  };

  return (
    <>
      <S.ProductRow className="header">
        <div className="duplicate header">複製</div>
        <div>
          <div className="img header">画像</div>
          <div className="code header">完成品コード</div>
          <S.HeaderName
            isBlue={searchWord !== '' || selectedTags.length > 0}
            onClick={() => {
              setTagMenuActive(!tagMenuActive);
            }}
          >
            完成品名
            <FilterFunctionIcon />
          </S.HeaderName>
          <div className="name-abbreviation">完成品名（略称）</div>
          <div className="appreciation-period-days header">{`賞味期限\n日数`}</div>
          <div className="appreciation-period-days header">{`安全在庫\n日数`}</div>
          <div className="jan-code">JANコード</div>
          <div className="is-discontinued-hidden">終売</div>
          <div className="is-discontinued-hidden">非表示</div>
          <div className="confirmation-title">確認事項</div>
        </div>
      </S.ProductRow>
      <S.Wrapper id="table-contents">
        {products ? (
          products
            .filter(
              (p: Todo) =>
                p.product.name.includes(searchWord) ||
                p.product.nameAbbreviation?.includes(searchWord) ||
                p.product.nameKana?.includes(searchWord) ||
                p.product.productCode?.toString().includes(searchWord)
            )
            .filter((p: Todo) => tagSearch(p, selectedTagIds))
            .map((p: Todo) => {
              return (
                <S.ProductRow className="hover-effect" key={p.product.id}>
                  {/* コピー */}
                  <div
                    className="duplicate"
                    onClick={() => {
                      onClickDuplicate(p.product);
                    }}
                  >
                    <DuplicateIcon />
                  </div>
                  {/* 編集エリア */}
                  <div
                    onClick={() => {
                      setEditData(p);
                      history.push(
                        `/pc/settings/product_master_setting_edit/${p.product.id}`,
                        {
                          ...productMasterPickingState,
                          stateScrollPosition: stateScrollPosition,
                          stateSearchWord: searchWord,
                          stateSelectedTags: selectedTags,
                        }
                      );
                    }}
                  >
                    {/* 完成品画像 */}
                    <div className="img">
                      <img
                        className="product-image"
                        src={p.product.imageUrl || ProductImg}
                      />
                    </div>
                    {/* 完成品コード */}
                    <div className="code">{p.product.productCode}</div>
                    <div className="name">{p.product.name}</div>
                    <div className="name-abbreviation">
                      {p.product.nameAbbreviation}
                    </div>
                    {/* 賞味期限日数 */}
                    <div className="appreciation-period-days">
                      {p.product.expiryDays}
                    </div>
                    {/* 安全在庫日数 */}
                    <div className="appreciation-period-days">
                      {p.product.safetyStockDays}
                    </div>
                    {/* JANコード */}
                    <div className="jan-code">{p.product.janCode}</div>
                    {/* 終売 */}
                    <div className="is-discontinued-hidden">
                      {p.product.isDiscontinued ? <CheckIcon /> : ''}
                    </div>
                    {/* 非表示 */}
                    <div className="is-discontinued-hidden">
                      {p.product.hidden ? <CheckIcon /> : ''}
                    </div>
                    {/* 確認事項 */}
                    <div className="confirmation">
                      {(p.caseIds.length === 0 ||
                        p.storerooms.length === 0) && <CautionIcon />}
                    </div>
                  </div>
                </S.ProductRow>
              );
            })
        ) : (
          <S.CircularIconWrapper>
            <CircularProgress style={{ color: '#64b2f9' }} />
          </S.CircularIconWrapper>
        )}

        {editMenuOpen && (
          <Overlay handleClick={() => setEditMenuOpen(!editMenuOpen)} />
        )}
        {deleteConfirm && (
          <DeleteConfirmModal
            name={editData.product.name}
            onClickCancel={() => {
              setEditData(null);
              setDeleteConfirm(false);
            }}
            onClickDelete={() => handleDeleteProduct()}
          />
        )}
        {deleteError && (
          <DeleteErrorModal onClickOK={() => setDeleteError(false)} />
        )}
      </S.Wrapper>
      <UpdatePopUp
        popUp={popUp}
        taskKind={'productMaster'}
        name={productName}
        handleClose={() => {
          setPopUp(false);
          setMessageKind('');
          setProductName('');
          history.push({
            pathname: `/pc/settings/product_master_setting`,
            state,
          });
        }}
        messageKind={messageKind}
      />
    </>
  );
};

export default TableWithFilter;
