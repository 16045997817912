import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';
// import axios from 'axios';
// import type { InputParams } from '@lib/stock_status/type';

const useCreateMaterialOrderMutation = (
  handleSuccess: () => void,
  setCreatedMaterialOrderId: (createdMaterialOrderId: number) => void
) => {
  return useMutation(
    async (params: Todo) => {
      const details = params.orderDetails.map((item: Todo) => ({
        id: item.id,
        materialId: item.materialId,
        unit_price: item.unitPrice,
        amount: item.amount,
        tax_rate: item.taxRate,
        _destroy: item.destroy,
      }));
      const data = await axiosInstance().post(
        `/api/v1/material_orders`,
        humps.decamelizeKeys({
          materialOrder: {
            supplier_id: params.supplierId,
            honorific: params.honorific,
            supplier_department_id: params.supplierDepartmentId,
            purchase_order_number: params.purchaseOrderNumber,
            confirmation_date: params.confirmationDate,
            subject: params.subject,
            requested_delivery_date: params.requestedDeliveryDate,
            payment_term: params.paymentTerm,
            delivery_destination: params.deliveryDestination,
            supplier_description: params.supplierDescription,
            comment: params.comment,
            details_attributes: details,
          },
        })
      );
      const materialOrderId = data.data.material_order_id;
      setCreatedMaterialOrderId(materialOrderId);
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useCreateMaterialOrderMutation;
