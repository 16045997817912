import { useEffect, useState } from 'react';
import { Todo } from '@lib/common/type';
import * as S from './ProductionScheduleEditable.styles';
import { addComma, isSameIndex } from '@lib/pc/production_schedule/functions';
import { containsNonNumeric } from '@lib/common/functions';

type Props = {
  p: Todo;
  currentRowIndex: number;
  setCurrentRowIndex: (currentRowIndex: number) => void;
  currentCellIndex: number;
  setCurrentCellIndex: (setCurrentCellIndex: number) => void;
  selectIndex: number;
  setSelectedProductId: (selectedProductId: number) => void;
  setSelectedSubmitDate: (selectedSubmitDate: Date) => void;
  inputParams: Todo;
  isChanged: boolean;
  setIsChanged: (isChanged: boolean) => void;
  handleUpdate: (inputParams: Todo) => void;
  setErrMsg: (errMsg: string) => void;
  updateCount: number;
  setUpdateCount: (updateCount: number) => void;
  rowIndex: number;
  cellIndex: number;
  productLength: number;
  totalTitle: string;
};

const ProductionScheduleEditable = ({
  p,
  currentRowIndex,
  setCurrentRowIndex,
  currentCellIndex,
  setCurrentCellIndex,
  selectIndex,
  setSelectedProductId,
  setSelectedSubmitDate,
  inputParams,
  isChanged,
  setIsChanged,
  handleUpdate,
  setErrMsg,
  updateCount,
  setUpdateCount,
  rowIndex,
  cellIndex,
  productLength,
  totalTitle,
}: Props) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const isToday = isSameIndex(
    cellIndex,
    selectIndex,
    p.numberOfDaysParamsMonth
  );
  const [actualAmount, setActualAmount] = useState(inputParams?.actualAmount);
  // 編集モード
  const [cellEditMode, setCellEditMode] = useState(false);
  // 直接入力モードのfocus管理
  const [focusedRowIndex, setFocusedRowIndex] = useState(currentRowIndex);
  const [focusedCellIndex, setFocusedCellIndex] = useState(currentCellIndex);

  const handleAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // 更新処理のためのproductId,submitDate設定---------
    setSelectedProductId(Number(p.id));
    setSelectedSubmitDate(p.productionSchedules[cellIndex].date);
    // 複数桁の半角数字かつBackspaceキー、Deleteキーのみ許容
    if (
      !/^\d*$/i.test(value) &&
      value !== 'Backspace' &&
      value !== 'Delete' &&
      value !== ''
    ) {
      setErrMsg('半角数字のみ入力してください');
    } else if (value) {
      setActualAmount(value);
      setIsChanged(true);
    } else {
      setActualAmount('');
      setIsChanged(true);
    }
  };

  const onBlur = () => {
    if (isChanged) {
      if (containsNonNumeric(actualAmount)) {
        setErrMsg('半角数字のみ入力してください');
      } else {
        // 再計算ボタン有効化
        const day = new Date(p.productionSchedules[cellIndex].date);
        day >= today && setUpdateCount(updateCount + 1);
        handleUpdate({
          ...inputParams,
          actualAmount: actualAmount,
          comment: inputParams.comment,
          checkEnabled:
            inputParams.checkEnabled !== null
              ? inputParams.checkEnabled
              : false,
        });
      }
    }
    setCellEditMode(false);
  };

  const handleArrowKeys = (e: React.KeyboardEvent) => {
    if (/^[0-9]$/.test(e.key)) {
      setCellEditMode(true);
    } else if (
      e.shiftKey &&
      e.key === 'Tab' &&
      focusedCellIndex === 0 &&
      !cellEditMode
    ) {
      // 最初の日付の場合、前のproductの最終日付へ移る
      // 最初のproductの場合、最後のproductへ移る
      if (focusedRowIndex === 0) {
        setFocusedRowIndex(productLength - 1);
      } else {
        setFocusedRowIndex(rowIndex - 1);
      }
      setFocusedCellIndex(p.numberOfDaysParamsMonth);
    } else if (e.shiftKey && e.key === 'Tab' && !cellEditMode) {
      setFocusedCellIndex((prevIndex) => prevIndex - 1);
    } else if (e.key === 'Tab' && p.numberOfDaysParamsMonth == cellIndex) {
      // タブ押下で最終日付の場合、最終日付時、次のproductへ移る
      // 最終productの場合、最初のproductへ移る
      if (productLength === rowIndex + 1) {
        setFocusedRowIndex(0);
      } else {
        setFocusedRowIndex(rowIndex + 1);
      }
      setFocusedCellIndex(0);
    } else if (
      e.key === 'Tab' &&
      focusedCellIndex < p.numberOfDaysParamsMonth
    ) {
      // タブ押下で最終日付ではない場合
      setFocusedCellIndex((prevIndex) => prevIndex + 1);
    } else if (e.key === 'ArrowUp' && focusedRowIndex > 0 && !cellEditMode) {
      setFocusedRowIndex((prevIndex) => prevIndex - 1);
    } else if (
      e.key === 'ArrowDown' &&
      focusedRowIndex < productLength - 1 &&
      !cellEditMode
    ) {
      setFocusedRowIndex((prevIndex) => prevIndex + 1);
    } else if (
      e.key === 'ArrowLeft' &&
      focusedCellIndex === 0 &&
      !cellEditMode
    ) {
      // 最初の日付の場合、前のproductの最終日付へ移る
      // 最初のproductの場合、最後のproductへ移る
      if (focusedRowIndex === 0) {
        setFocusedRowIndex(productLength - 1);
      } else {
        setFocusedRowIndex(rowIndex - 1);
      }
      setFocusedCellIndex(p.numberOfDaysParamsMonth);
    } else if (e.key === 'ArrowLeft' && focusedCellIndex > 0 && !cellEditMode) {
      // 最初の日付の場合、前のproductへ移る
      setFocusedCellIndex((prevIndex) => prevIndex - 1);
    } else if (
      e.key === 'ArrowRight' &&
      p.numberOfDaysParamsMonth === cellIndex &&
      !cellEditMode
    ) {
      // 最終日付時、次のproductへ移る
      setFocusedCellIndex(0);
      // 最終productの場合、最初のproductへ移る
      if (productLength === rowIndex + 1) {
        setFocusedRowIndex(0);
      } else {
        setFocusedRowIndex(rowIndex + 1);
      }
    } else if (
      e.key === 'ArrowRight' &&
      focusedCellIndex < p.numberOfDaysParamsMonth &&
      !cellEditMode
    ) {
      setFocusedCellIndex((prevIndex) => prevIndex + 1);
    }
  };

  const getValue = () => {
    if (actualAmount === '') {
      return '';
    } else if (rowIndex === focusedRowIndex && cellIndex === focusedCellIndex) {
      return actualAmount
        ? actualAmount
        : inputParams?.estimatedAmount
        ? inputParams?.estimatedAmount
        : '';
    } else {
      return actualAmount
        ? cellEditMode
          ? actualAmount
          : addComma(actualAmount)
        : inputParams?.estimatedAmount
        ? cellEditMode
          ? inputParams?.estimatedAmount
          : addComma(inputParams?.estimatedAmount)
        : '';
    }
  };

  useEffect(() => {
    const focusedCurrentId =
      focusedRowIndex.toString() + '-' + focusedCellIndex.toString();
    const focusedInput = document.getElementById(focusedCurrentId);
    focusedInput && focusedInput.focus();

    setCurrentRowIndex(focusedRowIndex);
    setCurrentCellIndex(focusedCellIndex);
  }, [focusedRowIndex, focusedCellIndex]);

  return (
    <>
      {totalTitle === '' ? (
        <S.Wrapper
          id={`${rowIndex}-${cellIndex}`}
          key={`${p.id}-${p.productCode}-${cellIndex}`}
          highLighten={isToday}
          onChange={handleAmount}
          value={getValue()}
          withCheckEnabled={p.productionSchedules[cellIndex]?.checkEnabled}
          onBlur={onBlur}
          type="text"
          pattern="^[1-9][0-9]*$"
          inputMode="numeric"
          autoComplete="off"
          onFocus={() => {
            setFocusedRowIndex(rowIndex);
            setFocusedCellIndex(cellIndex);
          }}
          onKeyDown={(e) => handleArrowKeys(e)}
          onDoubleClick={() => setCellEditMode(true)}
        />
      ) : (
        // 合計、前年同月
        <S.Wrapper
          id={`${rowIndex}-${cellIndex}`}
          key={`${p.id}-${p.productCode}-${cellIndex}`}
          highLighten={isToday}
          readOnly
          value={
            actualAmount === ''
              ? ''
              : actualAmount
              ? addComma(actualAmount)
              : inputParams?.actualAmount
              ? addComma(inputParams?.actualAmount)
              : ''
          }
        />
      )}
    </>
  );
};

export default ProductionScheduleEditable;
