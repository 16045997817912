import React, { useEffect } from 'react';
import * as S from './SemiProductProductionResultMainTable.styles';
import { ReactComponent as AddIcon } from '@assets/icons/add.svg';
import { ReactComponent as PencilIcon } from '@assets/icons/pencil.svg';
import { ReactComponent as DuplicateIcon } from '@assets/icons/duplicate.svg';
import LABEL_OF_AMOUNT_MAP from '@components/modules/common/LabelOfAmountMap/LabelOfAmountMap';
import { factoryLineBgColors } from '@lib/pc/common/type';
import { Todo } from '@lib/common/type';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import { seeFullComment } from '@lib/pc/common/functions';
import { TABLE_HEADER } from './header';

const defaultEditData = {
  detailId: null,
  amount: null,
  cart: null,
  case: null,
  caseId: null,
  checkTime: null,
  comment: '',
  defectiveSemiProductAmount: null,
  employeeIds: [],
  expirationDate: null,
  piece: null,
  plannedAmount: null,
  plannedCase: null,
  plannedPiece: null,
  semiProductId: null,
  semiProductProductionResultId: null,
  storeroomId: null,
};

const valueOfLineBgColors = Object.values(factoryLineBgColors);

type Props = {
  selectedLineIndex: number;
  data: Todo;
  setEditData: Todo;
  setPopUp: (popUp: boolean) => void;
  setUseDuplicateFunc: (useDuplicateFunc: boolean) => void;
  setSemiProductId: (semiProductId: number) => void;
  setScanning: (scanning: boolean) => void;
  setRegisteredSemiProductIds: (registeredSemiProductIds: number[]) => void;
};

const SemiProductProductionResultMainTable = ({
  setScanning,
  selectedLineIndex,
  data,
  setEditData,
  setPopUp,
  setUseDuplicateFunc,
  setSemiProductId,
  setRegisteredSemiProductIds,
}: Props) => {
  if (!data?.lines[selectedLineIndex])
    return (
      <S.CircularIconWrapperOuter>
        <S.CircularIconWrapper>
          <CircularProgress style={{ color: '#64b2f9' }} />
        </S.CircularIconWrapper>
      </S.CircularIconWrapperOuter>
    );

  const onClickDuplicate = (p: Todo, i: number) => {
    setEditData({
      lineName: data?.lines[selectedLineIndex].name,
      lineIndex: selectedLineIndex,
      piecesPerBox:
        data?.lines[selectedLineIndex].semiProductionResultDetails[i]
          .piecesPerBox || 1,
      semiProductId: p.semiProductId,
      semiProductsProcessId: p.semiProductsProcessId,
      storeroomId: p.storeroomId,
      caseId: p.caseId,
      expirationBaseDate: p.expirationBaseDate,
      expirationDate: p.expirationDate,
      employeeIds: p.employeeIds,
      labelOfAmount: p.labelOfAmount,
      piece: null,
      amount: null,
      case: null,
      defectiveSemiProductAmount: null,
      checkTime: null,
      cart: null,
      detailId: null,
      plannedAmount: null,
      plannedCase: null,
      plannedPiece: null,
    });
    setPopUp(false);
    setUseDuplicateFunc(true);
  };

  useEffect(() => {
    const registeredSemiProductIdsAtTable = data?.lines[
      selectedLineIndex
    ]?.semiProductionResultDetails.map((item: Todo) => item['semiProductId']);
    setRegisteredSemiProductIds(registeredSemiProductIdsAtTable);
  }, [data?.lines[selectedLineIndex]?.semiProductionResultDetails.length]);

  return (
    <>
      <S.Wrapper bgColor={valueOfLineBgColors[selectedLineIndex % 10]}>
        <S.Content>
          <S.Table>
            <tbody>
              {/* テーブルヘッダー */}
              <S.THead bgColor={valueOfLineBgColors[selectedLineIndex % 10]}>
                {TABLE_HEADER.map((t) => (
                  <S.THeadCell
                    key={t.key}
                    width={t.width}
                    wordBreak={t.wordBreak}
                    colSpan={t.colSpan}
                  >
                    {t.key}
                  </S.THeadCell>
                ))}
              </S.THead>
              {data &&
                data?.lines[selectedLineIndex]?.semiProductionResultDetails.map(
                  (p: Todo, index: number) => (
                    <React.Fragment
                      key={
                        index + p.detailId + selectedLineIndex + Math.random()
                      }
                    >
                      <S.TRow
                        key={index + p.detailId + Math.random()}
                        bgColor={valueOfLineBgColors[selectedLineIndex]}
                      >
                        {/* 編集 */}
                        <S.Icon
                          rowSpan={1}
                          onClick={() => {
                            setEditData({
                              lineName: data?.lines[selectedLineIndex].name,
                              lineIndex: selectedLineIndex,
                              ...p,
                              piecesPerBox: p.piecesPerBox || 1,
                            });
                            setPopUp(false);
                            setUseDuplicateFunc(false);
                            setScanning(false);
                          }}
                        >
                          <PencilIcon />
                        </S.Icon>
                        {/* 製品名 */}
                        <S.TCell
                          rowSpan={2}
                          width="20.75rem"
                          marginRight={'12px'}
                          borderLeft
                          secondRow
                        >
                          {p.semiProductName}
                        </S.TCell>
                        <S.TCell className="label">
                          予<br />定
                        </S.TCell>
                        <S.TCell
                          className="display-quantity display-quantity__count--case"
                          borderLeft
                          planned={true}
                        >
                          {/* 半製品 ケース */}
                          <div className="display-quantity__inner-wrap">
                            <div className="cell-content">
                              <div className="quantity">
                                <span className="num">{p.plannedCase}</span>
                                <span className="unit">ケース</span>
                              </div>
                              <div className="explanation">
                                x{p.piecesPerBox || 1}
                                <span className="unit">
                                  {p.labelOfAmount
                                    ? LABEL_OF_AMOUNT_MAP[p.labelOfAmount]
                                    : '個'}
                                </span>
                              </div>
                            </div>
                            <div className="symbol">+</div>
                          </div>
                        </S.TCell>
                        <S.TCell
                          className="display-quantity display-quantity__count--quantity"
                          planned={true}
                        >
                          {/* 半製品 個 */}
                          <div className="display-quantity__inner-wrap">
                            <div className="cell-content">
                              <div className="quantity">
                                <span className="num">
                                  {p.plannedPiece ? p.plannedPiece : ''}
                                </span>
                                <span className="unit">
                                  {p.labelOfAmount
                                    ? LABEL_OF_AMOUNT_MAP[p.labelOfAmount]
                                    : '個'}
                                </span>
                              </div>
                            </div>
                            <div className="symbol">=</div>
                          </div>
                        </S.TCell>
                        <S.TCell
                          className="display-quantity display-quantity__count--total"
                          planned={true}
                        >
                          {/* 半製品 合計 */}
                          <div className="display-quantity__inner-wrap">
                            <div className="with-label__inner-wrap">
                              <div className="cell-content">
                                <div className="quantity">
                                  <span className="title">合計</span>
                                  <span className="num">
                                    {p.plannedAmount ? p.plannedAmount : ''}
                                  </span>
                                  <span className="unit">
                                    {p.labelOfAmount
                                      ? LABEL_OF_AMOUNT_MAP[p.labelOfAmount]
                                      : '個'}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </S.TCell>

                        {/* 保管期限の起算日 */}
                        <S.TCell
                          colSpan={1}
                          rowSpan={2}
                          width="100px"
                          borderLeft
                          secondRow
                        >
                          {p.expirationBaseDate &&
                            moment(p.expirationBaseDate).format('YY.MM.DD')}
                        </S.TCell>
                        {/* 保管期限日 */}
                        <S.TCell
                          colSpan={1}
                          rowSpan={2}
                          width="100px"
                          borderLeft
                          secondRow
                        >
                          {p.expirationDate &&
                            moment(p.expirationDate).format('YY.MM.DD')}
                        </S.TCell>

                        <S.TCell
                          className="time-start"
                          rowSpan={2}
                          borderLeft
                          secondRow
                        >
                          {/* 時刻 */}
                          {/* <div className="cell-content"> */}
                          {p.checkTime && moment(p.checkTime).format('LT')}
                          {/* </div> */}
                        </S.TCell>
                        {/* 備考 */}
                        <S.TCell
                          colSpan={3}
                          rowSpan={2}
                          className="packaging-comment"
                          onClick={() => {
                            seeFullComment(index);
                          }}
                          borderLeft
                          secondRow
                        >
                          <div className="cell-content">{p.comment}</div>
                        </S.TCell>
                      </S.TRow>
                      {/* ------------二段目------------ */}
                      <S.TRow
                        key={p.detailId}
                        secondRow
                        bgColor={valueOfLineBgColors[selectedLineIndex]}
                        textColor={
                          p.plannedAmount > 0 &&
                          (p.amount === '' ||
                            p.amount === null ||
                            p.amount === 0)
                            ? true
                            : false
                        }
                      >
                        {/* 複製 */}
                        <S.Icon
                          rowSpan={1}
                          onClick={() => {
                            onClickDuplicate(p, index);
                            setSemiProductId(p.semiProductId);
                            setScanning(false);
                          }}
                          borderLeft
                        >
                          <DuplicateIcon />
                        </S.Icon>
                        <S.TCell className="label">
                          完<br />了
                        </S.TCell>
                        <S.TCell
                          className="display-quantity display-quantity__count--case"
                          borderLeft
                          textColor={
                            p.plannedAmount > 0 &&
                            (p.amount === '' ||
                              p.amount === null ||
                              p.amount === 0)
                              ? true
                              : false
                          }
                        >
                          {/* 半製品 ケース */}
                          <div className="display-quantity__inner-wrap">
                            <div className="cell-content">
                              <div className="quantity">
                                <span className="num">{p.case}</span>
                                <span className="unit">ケース</span>
                              </div>
                              <div className="explanation">
                                x{p.piecesPerBox || 1}
                                <span className="unit">
                                  {p.labelOfAmount
                                    ? LABEL_OF_AMOUNT_MAP[p.labelOfAmount]
                                    : '個'}
                                </span>
                              </div>
                            </div>
                            <div className="symbol">+</div>
                          </div>
                        </S.TCell>
                        <S.TCell className="display-quantity display-quantity__count--quantity">
                          {/* 半製品 個 */}
                          <div className="display-quantity__inner-wrap">
                            <div className="cell-content">
                              <div className="quantity">
                                <span className="num">
                                  {p.piece ? p.piece : ''}
                                </span>
                                <span className="unit">
                                  {p.labelOfAmount
                                    ? LABEL_OF_AMOUNT_MAP[p.labelOfAmount]
                                    : '個'}
                                </span>
                              </div>
                            </div>
                            <div className="symbol">=</div>
                          </div>
                        </S.TCell>
                        <S.TCell className="display-quantity display-quantity__count--total">
                          {/* 半製品 合計 */}
                          <div className="display-quantity__inner-wrap">
                            <div className="with-label__inner-wrap">
                              <div className="cell-content">
                                <div className="quantity">
                                  <span className="title">合計</span>
                                  <span className="num">
                                    {p.amount ? p.amount : ''}
                                  </span>
                                  <span className="unit">
                                    {p.labelOfAmount
                                      ? LABEL_OF_AMOUNT_MAP[p.labelOfAmount]
                                      : '個'}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </S.TCell>
                      </S.TRow>
                      <S.CommentBalloon
                        onClick={() => {
                          seeFullComment(index);
                        }}
                      >
                        <td
                          id={`comment-full-${index}`}
                          colSpan={10}
                          className={'cell-content__balloon'}
                        >
                          <span className="comment-full__title">備考：</span>
                          <br />
                          {p.comment}
                        </td>
                      </S.CommentBalloon>
                    </React.Fragment>
                  )
                )}
              <tr>
                <td colSpan={13}>
                  <S.FlexTableDivRow
                    onClick={() => {
                      setEditData({
                        ...defaultEditData,
                        time: null,
                        lineName: data.lines[selectedLineIndex].name,
                        lineIndex: selectedLineIndex,
                      });
                      setPopUp(false);
                      setUseDuplicateFunc(false);
                      setScanning(false);
                    }}
                  >
                    <AddIcon />
                    追加
                  </S.FlexTableDivRow>
                </td>
              </tr>
            </tbody>
          </S.Table>
        </S.Content>
      </S.Wrapper>
    </>
  );
};

export default SemiProductProductionResultMainTable;
