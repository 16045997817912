import React, { useEffect, useState, useRef } from 'react';
import { isIPad13 } from 'react-device-detect';
import * as S from './SemiProductProductionResultEditModal.styles';
import {
  TABLE_HEADER_TOP,
  TABLE_HEADER_MIDDLE,
  TABLE_HEADER_BOTTOM,
} from './header';
import moment from 'moment';
import { ReactComponent as CopyIcon } from '@assets/icons/copy_from_planned_to_actual.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { Button } from '@components/elements/buttons/Button';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import SemiProductProcessSelect from '@components/modules/common/SemiProductProcessSelect/SemiProductProcessSelect';
import EmployeeModal from '@components/modules/common/EmployeeModal/EmployeeModal';
import StoreroomSelect from '@components/modules/common/StoreroomSelect/StoreroomSelect';
import CaseSelect from '@components/modules/common/CaseSelect/CaseSelect';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import LABEL_OF_AMOUNT_MAP from '@components/modules/common/LabelOfAmountMap/LabelOfAmountMap';
import {
  Case,
  EmployeeData,
  PRODUCT_KIND,
  SemiProductsProcess,
  Storeroom,
  Todo,
  ScannedProductInfo,
} from '@lib/common/type';
import { factoryLineColors } from '@lib/pc/common/type';
import {
  calcPlannedTotalFunc,
  calcTotalFunc,
  shouldDisplayCustomNumPad,
} from '@lib/pc/product_production_result/functions';
import { semiProductIdConfirmation } from '@lib/pc/semi_product_production_result/type';
import CustomNumPad from '@lib/pc/common/num_pad/customNumPad';

type Props = {
  data: Todo;
  date: Date;
  semiProducts: Todo;
  semiProductsProcesses: SemiProductsProcess[];
  scannedSemiProduct: ScannedProductInfo;
  cases: Case[];
  handleClose: () => void;
  handleDelete: (id: number) => void;
  handleSubmit: (data: Todo) => void;
  setEditProductName: (editProductName: string) => void;
  casesPerCart: string;
  setCasesPerCart: (casesPerCart: string) => void;
  selectedLineId: number;
  setSemiProductId: (id: number) => void;
  useDuplicateFunc: boolean;
  setUseDuplicateFunc: (useDuplicateFunc: boolean) => void;
  useScanning: boolean;
  registeredSemiProductIds: number[];
};

const SemiProductProductionResultEditModal = ({
  data,
  date,
  semiProducts,
  semiProductsProcesses,
  scannedSemiProduct,
  cases,
  handleClose,
  handleDelete,
  handleSubmit,
  setEditProductName,
  casesPerCart,
  setCasesPerCart,
  selectedLineId,
  setSemiProductId,
  useDuplicateFunc,
  setUseDuplicateFunc,
  useScanning,
  registeredSemiProductIds,
}: Props) => {
  const [newData, setNewData] = useState(data);
  const [deleteId, setDeleteId] = useState<number | ''>('');
  const [errMsg, setErrMsg] = useState('');
  const [confirmMsg, setConfirmMsg] = useState('');
  const [expiryDays, setExpiryDays] = useState(null);
  const [filteredStorerooms, setFilteredStorerooms] = useState<Storeroom[]>([]);
  const valuesOfFactoryLineColors = Object.values(factoryLineColors);
  // ケース種類
  const [selectedCaseId, setSelectedCaseId] = useState(0);
  // 選択商品のケース種類
  const [filteredCases, setFilteredCases] = useState<Case[]>([]);
  // 商品選択時の1ケースあたりの個数
  const [piecesPerBox, setPiecesPerBox] = useState('');
  // 選択されたsemiProductId
  const [selectedSemiProductId, setSelectedSemiProductId] = useState(0);
  const DUPLICATE_CONFIRM_MSG =
    'すでに同じ半製品が登録済みです。よろしいですか？';

  const checkTimeRef = useRef<HTMLInputElement>(null);
  const completedTimeRef = useRef<HTMLInputElement>(null);
  const expirationDateRef = useRef<HTMLInputElement>(null);
  const expirationBaseDateRef = useRef<HTMLInputElement>(null);
  const processing = useRef(false);
  const singleCaseCheck = cases.map((item: Case) => item['caseId']);
  const singleCheckCaseArr = Array.from(new Set(singleCaseCheck));
  // ケースの種類が１種類の場合のケース名
  const [singleCaseName, setSingleCaseName] = useState('');
  // 未来日の判定
  const isFutureDate = moment(date).isAfter(moment(), 'day');

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNewData({ ...newData, [e.target.name]: e.target.value });

    // iPadOSでクリアボタンを効かせるためのコード
    setTimeout(() => {
      if (checkTimeRef.current) checkTimeRef.current.defaultValue = '';
      if (completedTimeRef.current) completedTimeRef.current.defaultValue = '';
      if (expirationDateRef.current)
        expirationDateRef.current.defaultValue = '';
      if (expirationBaseDateRef.current)
        expirationBaseDateRef.current.defaultValue = '';
    }, 100);
  };

  const handleChangeForNumpad = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewData((prevData: Todo) => {
      const oldData = prevData;
      const newData = { ...oldData, [e.target.name]: e.target.value };

      return newData;
    });
  };

  const calcTotal = (
    e: React.ChangeEvent<HTMLInputElement>,
    casesPerCart: string,
    piecesPerBox: string,
    planned?: boolean
  ) => {
    setNewData((prevData: Todo) => {
      const oldData = prevData;
      const newData = { ...oldData, [e.target.name]: e.target.value };
      if (planned) {
        const calcValue = calcPlannedTotalFunc(
          e,
          newData,
          piecesPerBox || newData.piecesPerBox
        );

        prevData = { ...newData, plannedAmount: calcValue };
      } else {
        const calcValue = calcTotalFunc(
          e,
          newData,
          casesPerCart || newData.casesPerCart,
          piecesPerBox || newData.piecesPerBox
        );
        prevData = { ...newData, amount: calcValue };
      }
      return prevData;
    });
  };

  const onClickDelete = () => {
    deleteId && handleDelete(deleteId);
  };

  const onClickCopy = () => {
    if (newData.amount) {
      setConfirmMsg('実績数量に入力がありますが、予定数量をコピーしますか？');
    } else {
      setNewData({
        ...newData,
        case: newData.plannedCase,
        piece: newData.plannedPiece,
        amount: newData.plannedAmount,
      });
    }
  };

  // 編集画面で使用する従業員データ
  const [employeesAtEdit, setEmployeesAtEdit] = useState<EmployeeData[]>(
    newData?.employeeIds ? newData.employeeIds : []
  );
  // 従業員設定用のmodal画面open;
  const [stackerEmployeeModalActive, setStackerEmployeeModalActive] =
    useState<boolean>(false);

  const handleClickEmployee = () => {
    setStackerEmployeeModalActive(!stackerEmployeeModalActive);
  };

  const cloneDate = (dateObject: Date) => {
    return new Date(dateObject.valueOf());
  };

  // semiProductIdとその確認結果を保持する配列
  const [idConfirmations] = useState<semiProductIdConfirmation[]>([]);

  const checkAndAddId = (id: number) => {
    const existingIndex = idConfirmations.findIndex((item) => item.id === id);
    const isRegistered = registeredSemiProductIds.includes(id);
    const confirmed =
      existingIndex !== -1 ? idConfirmations[existingIndex].confirmed : false;

    if (!confirmed && isRegistered) {
      setConfirmMsg(DUPLICATE_CONFIRM_MSG);
    }
    // 重複登録確認結果に保持していないidの場合、追加設定する
    if (existingIndex === -1) {
      idConfirmations.push({ id, confirmed: false });
    }
  };

  // 半製品重複登録OK処理
  const duplicateSemiProductOk = (id: number) => {
    const existingIndex = idConfirmations.findIndex((item) => item.id === id);
    idConfirmations[existingIndex].confirmed = true;
  };

  const duplicateSemiProductCheck = (e: number) => {
    const process = semiProductsProcesses.find((p) => p.id === Number(e));
    const getSemiProduct = semiProducts.semiProducts.filter(
      (p: { id: number }) => p.id === process?.semiProductId
    );

    checkAndAddId(getSemiProduct[0].id);
    setSelectedSemiProductId(getSemiProduct[0].id);
  };

  useEffect(() => {
    if (scannedSemiProduct && scannedSemiProduct.processId !== null) {
      handleChangeProduct(scannedSemiProduct.processId);
    }
  }, [scannedSemiProduct]);

  const handleChangeProduct = (e: number) => {
    // 同じ商品を登録するか確認チェック
    duplicateSemiProductCheck(e);
    if (e) {
      const process = semiProductsProcesses.find((p) => p.id === Number(e));
      const getProduct = semiProducts?.semiProducts?.filter(
        (p: { id: number }) => p.id === process?.semiProductId
      );
      const filteredCases = cases?.filter(
        (c) =>
          c.productId === process?.semiProductId &&
          c.productKind === PRODUCT_KIND.SEMI_PRODUCT
      );
      filteredCases && setFilteredCases(filteredCases);
      if (filteredCases.length === 0) {
        setErrMsg(
          `この半製品「${getProduct[0].name}」で使用するケースを登録してください。`
        );
      }
      // piecesPerBoxの初期値設定
      setPiecesPerBox(filteredCases[0]?.piecesPerBox.toString() || '1');
      // ケース種類が1つの場合
      if (singleCheckCaseArr.length === 1) {
        const piecesPerBox = String(
          cases.find(
            (c) =>
              c.productKind === PRODUCT_KIND.SEMI_PRODUCT &&
              c.productId === process?.semiProductId
          )?.piecesPerBox || 1
        );
        setPiecesPerBox(piecesPerBox);
      }

      // 保管期限日の初期設定
      setExpiryDays(getProduct[0].expiryDays);
      process && setSemiProductId(process.semiProductId);
      const calcDate = cloneDate(date);
      if (getProduct[0].expiryDays) {
        calcDate.setDate(calcDate.getDate() + Number(getProduct[0].expiryDays));
        setNewData({
          ...newData,
          semiProductId: process?.semiProductId,
          semiProductName: getProduct[0].name,
          expirationBaseDate: moment(date).format('YYYY-MM-DD'),
          expirationDate: moment(calcDate).format('YYYY-MM-DD'),
          semiProductsProcessId: e,
          caseId: filteredCases[0]?.caseId,
          labelOfAmount: getProduct[0].labelOfAmount,
        });
        setCasesPerCart(getProduct[0].casesPerCart || 1);
      } else {
        setNewData({
          ...newData,
          semiProductId: process?.semiProductId,
          semiProductName: getProduct[0].name,
          expirationBaseDate: moment(date).format('YYYY-MM-DD'),
          expirationDate: '',
          semiProductsProcessId: e,
          caseId: filteredCases[0]?.caseId,
          labelOfAmount: getProduct[0].labelOfAmount,
        });
        setCasesPerCart(getProduct[0].casesPerCart || 1);
      }
    } else {
      setNewData({
        ...newData,
        semiProductId: '',
        semiProductName: '',
        expirationBaseDate: '',
        expirationDate: '',
        semiProductsProcessId: '',
        caseId: '',
        labelOfAmount: '',
      });
      setCasesPerCart('');
    }
  };

  const handleChangeStoreroom = (e: number) => {
    setNewData({
      ...newData,
      storeroomId: e,
    });
  };

  const productRequiredCheck = (newData: Todo) => {
    if (processing?.current) return;

    processing.current = true;

    setTimeout(() => {
      processing.current = false;
    }, 3000);

    if (!newData.semiProductId) {
      setErrMsg('製品名を選択してください。');
    } else if (newData?.storeroomId === 0 || newData?.storeroomId === null) {
      setErrMsg('在庫室を選択してください。');
    } else if (newData?.storeroomId === undefined) {
      setErrMsg('この半製品が使用する在庫室を登録してください。');
    } else if (newData?.caseId === 0 || newData?.caseId === null) {
      setErrMsg('ケースの種類を選択してください。');
    } else if (
      (newData.plannedQuantity &&
        newData.plannedQuantity.toString().length > 10) ||
      (newData.completedQuantity &&
        newData.completedQuantity.toString().length > 10)
    ) {
      setErrMsg('予定数量、完了数量の合計は、10桁以内で入力してください。');
    } else if (isFutureDate && newData.amount) {
      setErrMsg('翌日以降の実績数量は入力できません。');
    } else if (isFutureDate && newData.defectiveSemiProductAmount) {
      setErrMsg('翌日以降の不良数は入力できません。');
    } else {
      handleSubmit(newData);
    }
  };

  const handleChangeExpirationBaseDate = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const calcDate = new Date(e.target.value);
    if (expiryDays) {
      calcDate.setDate(calcDate.getDate() + Number(expiryDays));
      setNewData({
        ...newData,
        expirationBaseDate: e.target.value,
        expirationDate: moment(calcDate).format('YYYY-MM-DD'),
      });
    } else {
      setNewData({
        ...newData,
        expirationBaseDate: e.target.value,
        expirationDate: e.target.value,
      });
    }
    // iPadOSでクリアボタンを効かせる
    setTimeout(() => {
      if (expirationBaseDateRef.current)
        expirationBaseDateRef.current.defaultValue = '';
      if (expirationDateRef.current)
        expirationDateRef.current.defaultValue = '';
    }, 100);
  };

  // ケース種類の選択
  const setSelectedCase = (e: number) => {
    const filtered = filteredCases && filteredCases.find((c) => c.caseId === e);
    setNewData({
      ...newData,
      caseId: e,
      piecesPerBox: filtered?.piecesPerBox || '1',
    });
  };

  // iPadOS、初期表示時クリアボタンを効かせるためのコード
  useEffect(() => {
    setTimeout(() => {
      if (checkTimeRef.current) checkTimeRef.current.defaultValue = '';
      if (expirationDateRef.current)
        expirationDateRef.current.defaultValue = '';
      if (expirationBaseDateRef.current)
        expirationBaseDateRef.current.defaultValue = '';
    }, 100);
    // 過去情報コピー機能使用時、ケースの絞り込み
    const filteredCases = cases?.filter(
      (c) =>
        c.productId === newData?.semiProductId &&
        c.productKind === PRODUCT_KIND.SEMI_PRODUCT
    );
    filteredCases && setFilteredCases(filteredCases);
  }, []);

  useEffect(() => {
    setNewData({
      ...newData,
      employeeIds: employeesAtEdit.map((e) => e.id),
    });
  }, [employeesAtEdit]);

  useEffect(() => {
    if (data.detailId) {
      const getProduct = semiProducts?.semiProducts?.find(
        (p: { id: number }) => p.id === data.semiProductId
      );
      setExpiryDays(getProduct.expiryDays);
    }
  }, [data]);

  useEffect(() => {
    const filteredProcesses = semiProductsProcesses.filter(
      (p) => p.id === newData?.semiProductsProcessId
    );
    setFilteredStorerooms(filteredProcesses[0]?.storerooms);
    if (!newData.detailId) {
      // 在庫室の初期値を設定
      setNewData({
        ...newData,
        storeroomId: filteredProcesses[0]?.storerooms[0]?.id,
      });
    }
  }, [newData?.semiProductsProcessId]);

  useEffect(() => {
    const selectedCase = filteredCases?.find(
      (c) => c.caseId === selectedCaseId
    );
    setPiecesPerBox(
      selectedCase?.piecesPerBox.toString()
        ? selectedCase?.piecesPerBox.toString()
        : newData?.piecesPerBox
        ? newData?.piecesPerBox
        : '1'
    );
  }, [selectedCaseId]);

  useEffect(() => {
    if (singleCheckCaseArr.length === 1) {
      const findCase = cases?.find(
        (c: Case) => c.caseId === singleCheckCaseArr[0]
      );
      findCase && setSingleCaseName(findCase.caseName);
    }
  }, [singleCheckCaseArr]);

  return (
    <>
      <S.Wrapper open={data}>
        <S.Line bgColor={valuesOfFactoryLineColors[data.lineIndex % 10]}>
          {newData?.lineName}
        </S.Line>
        <S.Table>
          <S.OperationType>
            {useDuplicateFunc ? '複製' : newData.detailId ? '編集' : '新規作成'}
          </S.OperationType>
          <tbody>
            {/***** 上段header *****/}
            <S.THead>
              {TABLE_HEADER_TOP.map((t, i: number) => (
                <S.THeadCell
                  key={t.key + i}
                  width={t.width}
                  wordBreak={t.wordBreak}
                  colSpan={t.colSpan}
                  borderLeft={t.borderLeft}
                  borderRight={t.borderRight}
                  borderTop={t.borderTop}
                >
                  {t.key}
                </S.THeadCell>
              ))}
              {/* 削除 */}
              <S.TCell
                rowSpan={6}
                width="2.6rem"
                className="delete-btn"
                background={newData.detailId ? 'white' : ''}
                useDuplicateFunc={useDuplicateFunc}
                borderTop
              >
                {newData.detailId && (
                  <S.IconButton onClick={() => setDeleteId(newData.detailId)}>
                    <DeleteIcon />
                  </S.IconButton>
                )}
              </S.TCell>
            </S.THead>
            {/***** 上段 *****/}
            <S.TRow>
              {/* 製品名 */}
              <S.TCell rowSpan={1} align="center" padding="0 0 2px 8px">
                <SemiProductProcessSelect
                  semiProductsProcesses={semiProductsProcesses
                    .filter((p) => p.factoryLineId === selectedLineId)
                    .filter((p) => p.factoryLineIds.includes(selectedLineId))}
                  handleChangeProduct={handleChangeProduct}
                  setEditProductName={setEditProductName}
                  scannedSemiProduct={scannedSemiProduct}
                  value={newData?.semiProductsProcessId}
                  disabled={
                    newData?.amount > 0 ||
                    newData?.detailId !== null ||
                    useDuplicateFunc
                  }
                />
              </S.TCell>

              {/* 半製品 予定数量 */}
              <S.TCell
                width="7rem"
                className="display-quantity"
                borderLeft={true}
              >
                <div className="display-quantity__inner-wrap">
                  <div className="cell-content">
                    <span className="input-label">ケース</span>
                    <S.Input
                      type="text"
                      pattern="^[1-9][0-9]*$"
                      inputMode="numeric"
                      className="plannedCase"
                      name="plannedCase"
                      id="plannedCase"
                      value={newData?.plannedCase ?? ''}
                      onChange={(e) => {
                        if (e.target.value.match(/^[0-9]*$/)) {
                          calcTotal(e, casesPerCart, piecesPerBox, true);
                        }
                      }}
                      autoComplete="off"
                      align="right"
                      padding="0 30px 0 7px"
                      width="7rem"
                      fontSize="24px"
                      disabled={
                        newData?.caseId === null || piecesPerBox === '1'
                      }
                    />
                    <span className="unit">
                      x{piecesPerBox}
                      {newData?.labelOfAmount
                        ? LABEL_OF_AMOUNT_MAP[newData.labelOfAmount]
                        : '個'}
                    </span>
                  </div>
                  <div className="symbol">+</div>
                </div>
                {shouldDisplayCustomNumPad(
                  newData.semiProductsProcessId,
                  isIPad13,
                  useScanning
                ) && (
                  <CustomNumPad
                    right="1.1rem"
                    width="110px"
                    name="plannedCase"
                    value={newData?.plannedCase ? newData?.plannedCase : ''}
                    onChange={(e) => {
                      if (e.target.value.match(/^[0-9]*$/)) {
                        calcTotal(e, casesPerCart, piecesPerBox, true);
                      }
                    }}
                  />
                )}
              </S.TCell>
              {/* 個 */}
              <S.TCell width="9rem" className="display-quantity">
                <div className="display-quantity__inner-wrap">
                  <div className="cell-content">
                    <span className="input-label">
                      {newData?.labelOfAmount
                        ? LABEL_OF_AMOUNT_MAP[newData.labelOfAmount]
                        : '個'}
                    </span>
                    <S.Input
                      type="text"
                      pattern="^[1-9][0-9]*$"
                      inputMode="numeric"
                      name="plannedPiece"
                      className="plannedPiece"
                      id="plannedPiece"
                      value={newData?.plannedPiece ? newData?.plannedPiece : ''}
                      onChange={(e) => {
                        if (e.target.value.match(/^[0-9]*$/)) {
                          calcTotal(e, casesPerCart, piecesPerBox, true);
                        }
                      }}
                      autoComplete="off"
                      align="right"
                      padding="0 20px 0 7px"
                      width="7rem"
                      fontSize="24px"
                      disabled={newData?.caseId === null}
                    />
                  </div>
                  <div className="symbol">=</div>
                </div>
                {shouldDisplayCustomNumPad(
                  newData.semiProductsProcessId,
                  isIPad13,
                  useScanning
                ) && (
                  <CustomNumPad
                    right="1.7rem"
                    width="110px"
                    name="plannedPiece"
                    value={newData?.plannedPiece ? newData?.plannedPiece : ''}
                    onChange={(e) => {
                      if (e.target.value.match(/^[0-9]*$/)) {
                        calcTotal(e, casesPerCart, piecesPerBox, true);
                      }
                    }}
                  />
                )}
              </S.TCell>
              {/* 合計 */}
              <S.TCell
                className="display-quantity display-quantity--total"
                padding="0 0 0 15px"
                width="9rem"
                planned={true}
              >
                <div className="display-quantity__inner-wrap">
                  <span className="input-label input-label__sum">合計</span>
                  <div className="inner inner__sum">
                    {newData?.plannedAmount ? newData?.plannedAmount : ''}
                    <span className="unit">
                      {newData?.labelOfAmount
                        ? LABEL_OF_AMOUNT_MAP[newData.labelOfAmount]
                        : '個'}
                    </span>
                  </div>
                </div>
              </S.TCell>
              {/* コピーボタン */}
              <S.TCell borderRight>
                {newData.plannedAmount > 0 && (
                  <S.IconButton onClick={() => onClickCopy()}>
                    <CopyIcon />
                  </S.IconButton>
                )}
              </S.TCell>
              {/* 時刻 */}
              <S.TCell colSpan={1} width="5.1rem">
                <S.TimeInput
                  ref={checkTimeRef}
                  type="time"
                  name="checkTime"
                  id="checkTime"
                  value={
                    (newData?.checkTime && newData?.checkTime.length > 5
                      ? moment(newData?.checkTime).format('LT')
                      : newData?.checkTime) || ''
                  }
                  onChange={handleChange}
                />
              </S.TCell>
              <S.TCell />
            </S.TRow>
            {/***** 中段header *****/}
            <S.THead>
              {TABLE_HEADER_MIDDLE.map((t) => (
                <S.THeadCell
                  key={t.key}
                  wordBreak={t.wordBreak}
                  colSpan={t.colSpan}
                  borderLeft={t.borderLeft}
                  borderRight={t.borderRight}
                >
                  {t.key}
                </S.THeadCell>
              ))}
            </S.THead>
            <S.TRow>
              {/* ケースの種類 */}
              <S.TCell width="64px" align="center">
                {singleCaseName && singleCaseName ? (
                  <S.FakeCaseSelect className="fake-select">
                    {singleCaseName && singleCaseName}
                  </S.FakeCaseSelect>
                ) : (
                  <CaseSelect
                    cases={filteredCases.length > 0 ? filteredCases : cases}
                    value={newData.caseId ? newData.caseId : selectedCaseId}
                    setSelectedCaseId={setSelectedCaseId}
                    onChange={setSelectedCase}
                    noLabel={true}
                    height={'64px'}
                    width={'21.5rem'}
                    marginLeft={'0'}
                    marginTop={'0'}
                    menuMarginTop={'28px'}
                    menuListMaxHeight={'195px'}
                    fontSize={'20px'}
                    broaden={true}
                    disabled={
                      newData.semiProductId === null ||
                      (newData.detailId &&
                        data.amount === 0 &&
                        newData.amount > 0) ||
                      (newData.detailId && data.amount > 0) ||
                      (!newData.detailId && newData.amount > 0)
                    }
                  />
                )}
              </S.TCell>
              {/* 半製品数量 */}
              {/* ケース */}
              <S.TCell
                width="7rem"
                className="display-quantity"
                borderLeft={true}
              >
                <div className="display-quantity__inner-wrap">
                  <div className="cell-content">
                    <span className="input-label">ケース</span>
                    <S.Input
                      type="text"
                      pattern="^[1-9][0-9]*$"
                      inputMode="numeric"
                      name="case"
                      id="case"
                      value={newData?.case ?? ''}
                      onChange={(e) => {
                        if (e.target.value.match(/^[0-9]*$/)) {
                          calcTotal(e, casesPerCart, piecesPerBox);
                        }
                      }}
                      autoComplete="off"
                      align="right"
                      padding="0 30px 0 7px"
                      width="7rem"
                      fontSize="24px"
                      disabled={
                        newData?.caseId === null || piecesPerBox === '1'
                      }
                    />
                    <span className="unit">
                      x{piecesPerBox}
                      {newData?.labelOfAmount
                        ? LABEL_OF_AMOUNT_MAP[newData.labelOfAmount]
                        : '個'}
                    </span>
                  </div>
                  <div className="symbol">+</div>
                </div>
                {shouldDisplayCustomNumPad(
                  newData.semiProductsProcessId,
                  isIPad13,
                  useScanning
                ) && (
                  <CustomNumPad
                    right="1.1rem"
                    width="110px"
                    name="case"
                    value={newData?.case ? newData?.case : ''}
                    onChange={(e) => {
                      if (e.target.value.match(/^[0-9]*$/)) {
                        calcTotal(e, casesPerCart, piecesPerBox);
                      }
                    }}
                  />
                )}
              </S.TCell>
              {/* 個 */}
              <S.TCell width="9rem" className="display-quantity">
                <div className="display-quantity__inner-wrap">
                  <div className="cell-content">
                    <span className="input-label">
                      {newData?.labelOfAmount
                        ? LABEL_OF_AMOUNT_MAP[newData.labelOfAmount]
                        : '個'}
                    </span>
                    <S.Input
                      type="text"
                      pattern="^[1-9][0-9]*$"
                      inputMode="numeric"
                      name="piece"
                      id="piece"
                      value={newData?.piece ? newData?.piece : ''}
                      onChange={(e) => {
                        if (e.target.value.match(/^[0-9]*$/)) {
                          calcTotal(e, casesPerCart, piecesPerBox);
                        }
                      }}
                      autoComplete="off"
                      align="right"
                      padding="0 20px 0 7px"
                      width="7rem"
                      fontSize="24px"
                      disabled={newData?.caseId === null}
                    />
                  </div>
                  <div className="symbol">=</div>
                </div>
                {shouldDisplayCustomNumPad(
                  newData.semiProductsProcessId,
                  isIPad13,
                  useScanning
                ) && (
                  <CustomNumPad
                    right="1.7rem"
                    width="110px"
                    name="piece"
                    value={newData?.piece ? newData?.piece : ''}
                    onChange={(e) => {
                      if (e.target.value.match(/^[0-9]*$/)) {
                        calcTotal(e, casesPerCart, piecesPerBox);
                      }
                    }}
                  />
                )}
              </S.TCell>
              {/* 合計 */}
              <S.TCell
                className="display-quantity display-quantity--total"
                padding="0 0 0 15px"
                width="9rem"
              >
                <div className="display-quantity__inner-wrap">
                  <span className="input-label input-label__sum">合計</span>
                  <div className="inner inner__sum">
                    {newData?.amount ? newData?.amount : ''}
                    <span className="unit">
                      {newData?.labelOfAmount
                        ? LABEL_OF_AMOUNT_MAP[newData.labelOfAmount]
                        : '個'}
                    </span>
                  </div>
                </div>
              </S.TCell>
              {/* 不良数 */}
              <S.TCell width="7rem" borderRight={true}>
                <div className="display-quantity__inner-wrap">
                  <div className="cell-content">
                    <S.Input
                      type="text"
                      pattern="^[1-9][0-9]*$"
                      inputMode="numeric"
                      name="defectiveSemiProductAmount"
                      id="defectiveSemiProductAmount"
                      value={newData?.defectiveSemiProductAmount ?? ''}
                      onChange={(e) => {
                        if (e.target.value.match(/^[0-9]*$/)) {
                          handleChange(e);
                        }
                      }}
                      autoComplete="off"
                      align="right"
                      padding="0 20px 0 7px"
                      width="100%"
                      fontSize="24px"
                    />
                    <span className="unit">
                      {newData?.labelOfAmount
                        ? LABEL_OF_AMOUNT_MAP[newData.labelOfAmount]
                        : '個'}
                    </span>
                  </div>
                </div>
                {shouldDisplayCustomNumPad(
                  newData.semiProductsProcessId,
                  isIPad13,
                  useScanning
                ) && (
                  <CustomNumPad
                    right="0.1rem"
                    width="100px"
                    name="defectiveSemiProductAmount"
                    value={
                      newData?.defectiveSemiProductAmount
                        ? newData?.defectiveSemiProductAmount
                        : ''
                    }
                    onChange={(e) => {
                      if (e.target.value.match(/^[0-9]*$/)) {
                        handleChangeForNumpad(e);
                      }
                    }}
                  />
                )}
              </S.TCell>
              {/* 担当者 */}
              <S.TCell
                colSpan={2}
                width={'9.375rem'}
                background={'#F8F7F4'}
                fontSize={'14px'}
                padding="0 15px 0 0.25rem"
                onClick={() => {
                  handleClickEmployee();
                }}
              >
                <S.EmployeeWrapper>
                  {employeesAtEdit &&
                    employeesAtEdit.map((e: EmployeeData, i: number) => {
                      return (
                        <div key={i + e.code + e.name}>
                          {e.code}
                          {e.name && ':'}
                          {e.name}
                        </div>
                      );
                    })}
                </S.EmployeeWrapper>
              </S.TCell>
            </S.TRow>
            {/***** 下段header *****/}
            <S.THead>
              {TABLE_HEADER_BOTTOM.map((t, i: number) => (
                <S.THeadCell
                  key={t.key + i}
                  wordBreak={t.wordBreak}
                  colSpan={t.colSpan}
                  borderLeft={t.borderLeft}
                  borderTop={t.borderTop}
                  borderRight={t.borderRight}
                >
                  {t.key}
                </S.THeadCell>
              ))}
            </S.THead>
            <S.TRow>
              {/* 在庫室 */}
              <S.TCell colSpan={1} background="#F8F7F4" fontSize={'14px'}>
                <StoreroomSelect
                  menuShouldBlockScroll
                  menuShouldScrollIntoView
                  data={filteredStorerooms}
                  value={newData?.storeroomId}
                  disabled={
                    filteredStorerooms === undefined ||
                    filteredStorerooms?.length === 0 ||
                    (newData.detailId && data.amount > 0)
                  }
                  onChange={handleChangeStoreroom}
                  width={'21.5rem'}
                  height={'64px'}
                  fontSize={'20px'}
                  menuTop={'-8px'}
                  broaden={true}
                />
              </S.TCell>
              {/* 保管期限の起算日 */}
              <S.TCell colSpan={2} fontSize={'16px'} borderLeft={true}>
                <S.Input
                  ref={expirationBaseDateRef}
                  type="date"
                  name="expirationBaseDate"
                  id="expirationBaseDate"
                  value={newData?.expirationBaseDate || ''}
                  onChange={(e) => handleChangeExpirationBaseDate(e)}
                  pattern="\d{4}-\d{2}-\d{2}"
                  autoComplete="off"
                  height={'4rem'}
                  width={'242px'}
                  max="9999-12-31"
                />
              </S.TCell>
              {/* 保管期限日 */}
              <S.TCell colSpan={2} fontSize={'16px'} borderRight={true}>
                <S.Input
                  ref={expirationDateRef}
                  type="date"
                  name="expirationDate"
                  id="expirationDate"
                  value={newData?.expirationDate || ''}
                  onChange={handleChange}
                  pattern="\d{4}-\d{2}-\d{2}"
                  autoComplete="off"
                  height={'4rem'}
                  width={'242px'}
                  max="9999-12-31"
                />
              </S.TCell>
              {/* 備考 */}
              <S.TCell colSpan={2} width="8rem">
                <S.TextareaPackagingComment
                  name="comment"
                  id="comment"
                  value={newData?.comment ?? ''}
                  onChange={handleChange}
                  autoComplete="off"
                  className="packaging-comment"
                />
              </S.TCell>
            </S.TRow>
          </tbody>
        </S.Table>
        <S.ButtonWrapper>
          <S.ButtonContainer>
            <Button borderWidth={1} outlined onClick={handleClose}>
              キャンセル
            </Button>
            <Button
              onClick={() => {
                productRequiredCheck(newData);
              }}
            >
              保存
            </Button>
          </S.ButtonContainer>
        </S.ButtonWrapper>
      </S.Wrapper>
      {deleteId && (
        <S.DeleteModalWrapper>
          <S.DeleteModalTextContainer>
            <span>削除後は元に戻すことが出来ません。</span>
            <span>本当に削除しますか？</span>
          </S.DeleteModalTextContainer>
          <S.DeleteModalButtonContainer>
            <Button padding="md" outlined onClick={() => setDeleteId('')}>
              キャンセル
            </Button>
            <Button
              padding="md"
              icon={<DeleteIcon />}
              outlined
              onClick={onClickDelete}
            >
              削除
            </Button>
          </S.DeleteModalButtonContainer>
        </S.DeleteModalWrapper>
      )}
      {errMsg && (
        <S.DeleteModalWrapper width="30%">
          <S.DeleteModalTextContainer>
            <span>{errMsg}</span>
          </S.DeleteModalTextContainer>
          <S.DeleteModalButtonContainer>
            <Button
              padding="md"
              outlined
              onClick={() => {
                setErrMsg('');
                setUseDuplicateFunc(false);
              }}
            >
              OK
            </Button>
          </S.DeleteModalButtonContainer>
        </S.DeleteModalWrapper>
      )}
      {(deleteId || errMsg || confirmMsg) && <Overlay zIndex={10000} dark />}
      {stackerEmployeeModalActive && (
        <Overlay handleClick={() => setStackerEmployeeModalActive(false)} />
      )}
      <EmployeeModal
        width="5.05rem"
        employeeRole="production_result"
        employeesAtEdit={employeesAtEdit}
        setEmployeesAtEdit={setEmployeesAtEdit}
        EmployeeModalActive={stackerEmployeeModalActive}
        setEmployeeModalActive={setStackerEmployeeModalActive}
      />
      <ConfirmPopUp
        fromPc={true}
        middleOfPcScreen={true}
        confirmMsg={confirmMsg}
        zIndex={10001}
        handleOk={() => {
          if (confirmMsg === DUPLICATE_CONFIRM_MSG) {
            duplicateSemiProductOk(selectedSemiProductId);
          } else {
            setNewData({
              ...newData,
              case: newData.plannedCase,
              piece: newData.plannedPiece,
              amount: newData.plannedAmount,
            });
          }
          setConfirmMsg('');
        }}
        handleCancel={() => setConfirmMsg('')}
      />
    </>
  );
};

export default SemiProductProductionResultEditModal;
