import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as S from './ShippingGroupCreateWrapper.styles';
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import ShippingGroupContents from '@components/modules/pc/settings/shipping_group_setting/ShippingGroupEdit/ShippingGroup/ShippingGroupContents';
import { createdShippingGroupData } from '@components/modules/pc/settings/shipping_group_setting/ShippingGroupCreate/default';
import { ShippingGroupState } from '@lib/pc/settings/shipping_group_setting/type';

const ShippingGroupCreateWrapper = () => {
  const { state } = useLocation<ShippingGroupState>();
  const history = useHistory();

  const defaultNewData = {
    id: 0,
    code: '',
    name: '',
    shippingTime: '',
  };

  const [newData, setNewData] = useState(defaultNewData);

  // 削除確認メッセージ
  const [confirmMsg, setConfirmMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [enableToSave, setEnableToSave] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  // 確認メッセージhandleOk判定用:一覧画面へ移動か、別タブ表示か。
  const [goBack, setGoBack] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [updatePopUp, setUpdatePopUp] = useState(false);

  const [createdData, setCreatedData] = useState(createdShippingGroupData);

  const ShippingGroupSettingTabs = ['出荷先グループ', '出荷先'];

  // 一覧画面へ戻る
  const onClickGoBack = () => {
    if (isChanged) {
      setGoBack(true);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      history.push({
        pathname: `/pc/settings/shipping_group_setting`,
        state: state,
      });
    }
  };

  const handleClickTab = (i: number) => {
    setGoBack(false);
    setEnableToSave(false);
    if (createdData.id === 0) {
      setErrMsg('はじめに出荷先ブループを登録してください。');
    } else if (isChanged) {
      setSelectedTabId(i);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      setSelectedTabIndex(i);
      history.push(
        `/pc/settings/shipping_group_setting_edit/${createdData.id}`,
        {
          ...state,
          id: createdData.id,
          name: createdData.name,
          code: createdData.code,
          shippingTime: createdData.shippingTime,
          stateSelectedTabId: i,
        }
      );
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    // ここで一覧画面へ戻るか別タブを表示するかの判断が必要
    if (goBack) {
      history.push({
        pathname: `/pc/settings/shipping_group_setting`,
        state: {
          ...state,
          messageKind: '',
          productName: '',
          stateSelectedTabId: 0,
        },
      });
    } else {
      setSelectedTabIndex(selectedTabId);
    }
    setIsChanged(false);
    setConfirmMsg('');
  };
  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  return (
    <>
      <S.Wrapper>
        <S.TabWrapper>
          {ShippingGroupSettingTabs.map((d: string, i: number) => (
            <div key={d}>
              <S.TabArea>
                <S.Tab
                  key={d}
                  tabCount={ShippingGroupSettingTabs.length}
                  active={i == selectedTabIndex}
                  onClick={() => {
                    handleClickTab(i);
                  }}
                >
                  {d}
                </S.Tab>
              </S.TabArea>
            </div>
          ))}
        </S.TabWrapper>

        <DndProvider backend={HTML5Backend}>
          {/* 出荷先グループ */}
          {selectedTabIndex === 0 && (
            <ShippingGroupContents
              newData={newData}
              setNewData={setNewData}
              enableToSave={enableToSave}
              setEnableToSave={setEnableToSave}
              setIsChanged={setIsChanged}
              onClickGoBack={onClickGoBack}
              updating={updating}
              setUpdating={setUpdating}
              setUpdatePopUp={setUpdatePopUp}
              createdData={createdData}
              setCreatedData={setCreatedData}
            />
          )}
        </DndProvider>
      </S.Wrapper>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <UpdatePopUp
        popUp={updatePopUp}
        taskKind={'messageOnly'}
        messageKind={'update'}
        handleClose={() => setUpdatePopUp(false)}
      />
      {updatePopUp && <Overlay handleClick={() => setUpdatePopUp(false)} />}
    </>
  );
};

export default ShippingGroupCreateWrapper;
