import { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import * as S from './TableWithFilter.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as CautionIcon } from '@assets/icons/caution.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/check_square_24px.svg';
import { ReactComponent as DuplicateIcon } from '@assets/icons/duplicate.svg';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import ProductImg from '@assets/images/product-img.jpg';
import DeleteConfirmModal from '@components/modules/pc/settings/common/DeleteConfirmModal/DeleteConfirmModal';
import DeleteErrorModal from '@components/modules/pc/settings/common/DeleteErrorModal/DeleteErrorModal';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import useDeleteProduct from '@lib/pc/settings/product_master/useDeleteProduct';
import type { Storeroom, Tag, Todo } from '@lib/common/type';
import { ProductMasterSettingState } from '@lib/pc/settings/product_master_setting/type';
import {
  Material,
  MaterialArray,
} from '@lib/pc/settings/material_master_setting/type';
import { tagSearchForMaterial } from '@lib/pc/settings/material_master_setting/functions';

type Props = {
  materials: Todo;
  refetch: () => void;
  editData: Todo;
  setEditData: (editData: Todo) => void;
  popUp: boolean;
  setPopUp: (popUp: boolean) => void;
  messageKind: string;
  setMessageKind: (messageKind: string) => void;
  productName: string;
  setProductName: (productName: string) => void;
  searchWord: string;
  setSearchWord: (searchWord: string) => void;
  selectedTags: Todo[];
  setSelectedTags: (selectedTags: Todo[]) => void;
  selectedStorerooms: Storeroom[];
  handleSuccessEditSubmit: () => void;
  tagMenuActive: boolean;
  setTagMenuActive: (tagMenuActive: boolean) => void;
};

const TableWithFilter = ({
  materials,
  refetch,
  editData,
  setEditData,
  popUp,
  setPopUp,
  messageKind,
  setMessageKind,
  productName,
  setProductName,
  searchWord,
  setSearchWord,
  selectedTags,
  setSelectedTags,
  selectedStorerooms,
  handleSuccessEditSubmit,
  tagMenuActive,
  setTagMenuActive,
}: Props) => {
  const { state } = useLocation<ProductMasterSettingState>();
  const history = useHistory();
  // タグの検索用にfilter使用
  const selectedTagIds = selectedTags
    ? selectedTags.map((item: Tag) => item['id'])
    : [];

  const [stateScrollPosition, setScrollPosition] = useState<number | null>(
    null
  );

  // 編集、削除メニューの表示
  const [editMenuOpen, setEditMenuOpen] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteError, setDeleteError] = useState(false);

  const MaterialNameArr = materials
    ? materials.map((m: Material) => ({
        id: m.id,
        name: m.name,
        nameAbbreviation: m.nameAbbreviation,
        nameKana: m.nameKana,
        piecesPerBox: m.piecesPerBox,
        piecesPerUnit: m.piecesPerUnit,
        materialCode: m.materialCode,
        tagIds: m.tagIds,
        materialType: m.materialType,
        materialTypeLabel: m.materialTypeLabel === '原材料' ? '原' : '資',
        materialTypeValue: m.materialTypeValue,
        expiryDays: m.expiryDays,
        labelOfAmount: m.labelOfAmount,
        labelOfAmountValue: m.labelOfAmountValue,
        labelOfUnit: m.labelOfUnit,
        labelOfUnitValue: m.labelOfUnitValue,
        orderType: m.orderType,
        orderTypeLabel: m.orderTypeLabel,
        orderUnit: m.orderUnit,
        orderUnitValue: m.orderUnitValue,
        alertThreshold: m.alertThreshold,
        hidden: m.hidden,
        imageUrl: m.imageUrl,
        storeroomIds: m.storeroomIds,
        defaultOrderAmount: m.defaultOrderAmount,
      }))
    : [];

  const handleDeleteProduct = () => {
    deleteProduct.mutate();
    setMessageKind('delete');
    setProductName(editData?.product?.name);
  };

  const handleProductDeleteSuccess = () => {
    handleSuccessEditSubmit();
    refetch();
    setDeleteConfirm(false);
    setMessageKind('delete');
    setPopUp(!popUp);
  };

  const deleteProduct = useDeleteProduct(
    editData?.product?.id,
    handleProductDeleteSuccess,
    setDeleteError,
    setDeleteConfirm
  );

  const onClickDuplicate = async (m: MaterialArray) => {
    const data = { ...m, id: null, imageUrl: null };
    history.push(`/pc/settings/material_master_setting_create`, {
      ...productMasterPickingState,
      stateScrollPosition: stateScrollPosition,
      stateSearchWord: searchWord,
      stateSelectedTags: selectedTags,
      editData: data,
    });
  };

  useEffect(() => {
    if (state === undefined) {
      document?.getElementById('table-contents')?.scrollTo(0, 0);
      setSearchWord('');
      setSelectedTags([]);
    } else {
      const { stateScrollPosition, stateSearchWord, stateSelectedTags } = state;
      setSearchWord(stateSearchWord);
      setSelectedTags(stateSelectedTags);
      document
        ?.getElementById('table-contents')
        ?.scrollTo(0, stateScrollPosition);
    }
  }, [state]);

  const productMasterPickingState = {
    stateScrollPosition: stateScrollPosition,
    stateSearchWord: searchWord,
    stateSelectedTags: selectedTags,
  };

  useEffect(() => {
    refetch();
  }, [selectedStorerooms, materials?.length]);

  // スクロール位置保存
  document?.getElementById('table-contents')?.addEventListener('scroll', () => {
    const currentScrollPosition =
      document?.getElementById('table-contents')?.scrollTop;
    if (currentScrollPosition) {
      setScrollPosition(currentScrollPosition);
    }
  });

  const FilterFunctionIcon = () => {
    return (
      <S.ListLeftHeadCell
        onClick={() => setTagMenuActive(!tagMenuActive)}
        isBlue={searchWord !== '' || selectedTags.length > 0}
      >
        <span className="filter-product">
          {searchWord !== '' || selectedTags.length > 0 ? (
            <FilteredIcon />
          ) : (
            <Filter isBlue={searchWord !== '' || selectedTags.length > 0} />
          )}
        </span>
      </S.ListLeftHeadCell>
    );
  };

  return (
    <>
      <S.ProductRow className="header">
        <div className="duplicate header">複製</div>
        <div>
          <div className="material header">資/原</div>
          <div className="img header">画像</div>
          <div className="code header">コード</div>
          <S.HeaderName
            isBlue={searchWord !== '' || selectedTags.length > 0}
            onClick={() => {
              setTagMenuActive(!tagMenuActive);
            }}
          >
            名称
            <FilterFunctionIcon />
          </S.HeaderName>
          <div className="name-abbreviation">名称（略称）</div>
          <div className="appreciation-period-days">{`使用期限\n日数`}</div>
          <div className="default-order-amount">基準発注数</div>
          <div className="alert-threshold">在庫下限閾値</div>
          <div className="icon">非表示</div>
          <div className="icon">{`確認\n事項`}</div>
        </div>
      </S.ProductRow>
      <S.Wrapper id="table-contents">
        {MaterialNameArr ? (
          MaterialNameArr.filter(
            (m: MaterialArray) =>
              m.name.includes(searchWord) ||
              m.nameAbbreviation?.includes(searchWord) ||
              m.nameKana?.includes(searchWord) ||
              m.materialCode?.toString().includes(searchWord)
          )
            .filter((m: MaterialArray) =>
              tagSearchForMaterial(m, selectedTagIds)
            )
            .map((m: MaterialArray) => {
              return (
                <S.ProductRow className="hover-effect" key={m.id}>
                  {/* コピー */}
                  <div
                    className="duplicate"
                    onClick={() => {
                      onClickDuplicate(m);
                    }}
                  >
                    <DuplicateIcon />
                  </div>
                  {/* 編集エリア */}
                  <div
                    onClick={() => {
                      setEditData(m);
                      history.push(
                        `/pc/settings/material_master_setting_edit/${m.id}`,
                        {
                          ...productMasterPickingState,
                          stateScrollPosition: stateScrollPosition,
                          stateSearchWord: searchWord,
                          stateSelectedTags: selectedTags,
                        }
                      );
                    }}
                  >
                    {/* 資材/原材料 */}
                    <S.MaterialTypeDivWrapper>
                      <S.MaterialTypeDiv materialKind={m.materialTypeLabel}>
                        {m.materialTypeLabel}
                      </S.MaterialTypeDiv>
                    </S.MaterialTypeDivWrapper>

                    {/* 画像 */}
                    <div className="img">
                      <img
                        className="product-image"
                        src={m.imageUrl || ProductImg}
                      />
                    </div>
                    {/* 管理コード */}
                    <div className="code">{m.materialCode}</div>
                    <div className="name">{m.name}</div>
                    <div className="name-abbreviation">
                      {m.nameAbbreviation}
                    </div>
                    {/* 使用期限日数 */}
                    <div className="appreciation-period-days">
                      {m.expiryDays}
                    </div>
                    {/* 基準発注数 */}
                    <div className="default-order-amount">
                      {m.defaultOrderAmount && m.defaultOrderAmount}
                      {m.defaultOrderAmount && m.orderUnit}
                    </div>
                    {/* 在庫下限閾値 */}
                    <div className="alert-threshold">
                      {m.alertThreshold}
                      <span>{m.alertThreshold && m.labelOfAmountValue}</span>
                    </div>
                    {/* 非表示 */}
                    <div className="icon">{m.hidden ? <CheckIcon /> : ''}</div>
                    {/* 確認事項 */}
                    <div className="icon">
                      {m.storeroomIds?.length === 0 ? <CautionIcon /> : ''}
                    </div>
                  </div>
                </S.ProductRow>
              );
            })
        ) : (
          <S.CircularIconWrapper>
            <CircularProgress style={{ color: '#64b2f9' }} />
          </S.CircularIconWrapper>
        )}

        {editMenuOpen && (
          <Overlay handleClick={() => setEditMenuOpen(!editMenuOpen)} />
        )}
        {deleteConfirm && (
          <DeleteConfirmModal
            name={editData.product.name}
            onClickCancel={() => {
              setEditData(null);
              setDeleteConfirm(false);
            }}
            onClickDelete={() => handleDeleteProduct()}
          />
        )}
        {deleteError && (
          <DeleteErrorModal onClickOK={() => setDeleteError(false)} />
        )}
      </S.Wrapper>
      <UpdatePopUp
        popUp={popUp}
        taskKind={'productMaster'}
        name={productName}
        handleClose={() => {
          setPopUp(false);
          setMessageKind('');
          setProductName('');
          history.push({
            pathname: `/pc/settings/product_master_setting`,
            state,
          });
        }}
        messageKind={messageKind}
      />
    </>
  );
};

export default TableWithFilter;
