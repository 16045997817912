import * as S from './PickingSearchForm.styles';
import { ReactComponent as SearchIcon } from '@assets/icons/mobile/search.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close_24px.svg';
import { ReactComponent as FilterIcon } from '@assets/icons/filter_list.svg';
import { ReactComponent as FilteredIcon } from '@assets/icons/mobile/filtered.svg';
import { InputWithIcon } from '@components/elements/inputs/InputWithIcon/InputWithIcon';

type Props = {
  searchChar: string;
  setSearchChar: (searchChar: string) => void;
  statePickingStatus: number[];
  statusActive: boolean;
  setStatusActive: (statusActive: boolean) => void;
};

const PickingSearchForm = ({
  searchChar,
  setSearchChar,
  statePickingStatus,
  statusActive,
  setStatusActive,
}: Props) => {
  return (
    <S.SearchWrapper>
      <S.SearchConditionInput
        placeholder="出荷先グループで検索"
        onChange={(event) => setSearchChar(event.target.value)}
        value={searchChar}
      />
      {searchChar ? (
        <S.Icon onClick={() => setSearchChar('')}>
          <CloseIcon />
        </S.Icon>
      ) : (
        <S.Icon>
          <SearchIcon />
        </S.Icon>
      )}

      <S.ListLeftHeadCell onClick={() => setStatusActive(!statusActive)}>
        {statePickingStatus.length ? (
          <InputWithIcon
            placeholder="絞り込み中"
            icon={<FilteredIcon />}
            onChange={() => undefined}
            background={'#F8F7F4'}
            placeholderColor={'#2BA9D1'}
            placeholderFontWeight={'bold'}
            placeholderFontSize={'14px'}
            onClick={() => setStatusActive(!statusActive)}
          />
        ) : (
          <InputWithIcon
            placeholder="ステータス"
            icon={<FilterIcon />}
            onChange={() => undefined}
            background={'#F8F7F4'}
            placeholderFontWeight={'bold'}
            placeholderFontSize={'14px'}
            onClick={() => {
              setStatusActive(!statusActive);
            }}
          />
        )}
      </S.ListLeftHeadCell>
    </S.SearchWrapper>
  );
};

export default PickingSearchForm;
