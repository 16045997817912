/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';

export const isSameDayWithOutTime = (d1: Date, d2: Date) => {
  return moment(d1).isSame(d2, 'day');
};

export const generateDateArray = (num: number, date = moment()) => {
  return [...Array(num)].map((_, i) => {
    const today = new Date();
    const d = moment(date).toDate();
    d.setDate(d.getDate() + (i + 1 - num));
    return {
      productionDate: d,
      isToday: isSameDayWithOutTime(d, today),
    };
  });
};

export const generateDateArrayForModal = (num: number, date = moment()) => {
  return [...Array(num + 1)].map((_, i) => {
    const selectedDay = new Date(moment(date).toDate());
    const d = moment(date).toDate();
    d.setDate(1 + i);
    return {
      productionDate: d,
      isToday: isSameDayWithOutTime(d, selectedDay),
    };
  });
};

export const generateMonthlyDateArray = (
  monthlyDateNum: number,
  date = moment(),
  index: number,
  plusIndex: number
) => {
  return [...Array(monthlyDateNum + plusIndex)].map((_, i) => {
    const d = moment(date).toDate();
    d.setDate(1 + i);
    return {
      productionDate: d,
      isToday: i === index && i <= monthlyDateNum,
    };
  });
};

export const addComma = (val: number | null) => {
  if (val === null) return;

  return ('' + val).replace(/(\d)(?=(\d\d\d)+$)/g, '$1,');
};

// グラフ横のY軸
export const convertActualOrSafetyStockBarData = (data: any) => {
  const newPackagedData = data.map((p: any, i: number) => ({
    x: i,
    y: Math.max(p.amount, p.safetyStocks, 10),
  }));

  return newPackagedData;
};

// 在庫数合計
export const convertStockBarData = (data: any) => {
  const newPackagedData = data.map((p: any, i: number) => ({
    x: i + 1,
    y: p.amount ? p.amount : 0,
  }));

  return newPackagedData;
};

// 理想在庫数
export const convertArrToBarData = (arr: any[]) => {
  return arr.map((a: any, i: number) => {
    return {
      id: i,
      x: i + 1,
      y: a.safetyStocks ? a.safetyStocks : 0,
    };
  });
};

export const convertDataForTable = (arr: any) => {
  const totals = arr.map((stock: any) => ({
    key: stock.date,
    value: stock.amount_detail
      ? [
          ...stock.amount_detail.map((amount: any) => amount.total).reverse(),
          // stock.amount,
        ]
      : [...Array(7).fill('')],
  }));

  return totals;
};

export const convertDataForTotalRow = (arr: any) => {
  const sum = arr.map((a: any) => {
    return a.packagedProductDetail.reduce((d: any, newD: any) => {
      return d + newD.total;
    }, 0);
  });
  return sum;
};

// 払出状況のグラフ横のY軸
export const convertStockAllocationData = (data: any) => {
  const newPackagedData =
    data &&
    data.map((p: any, i: number) => ({
      x: i,
      y: Math.max(p.totalAmount, 10),
    }));

  return newPackagedData;
};

// 在庫払出数合計
export const convertStockAllocationBarData = (data: any) => {
  const totals =
    data &&
    data.map((p: any, i: number) => ({
      x: i + 1,
      y: p.totalAmount ? Number(p.totalAmount) : 0,
    }));

  return totals;
};

export const changeWidth = (num: number) => {
  let width = 2245;
  switch (num) {
    case 27:
      width = 2035;
      break;

    case 28:
      width = 2105;
      break;

    case 29:
      width = 2175;
      break;
  }
  return width;
};
