import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 100vw;
  height: calc(100vh - 143px);
  overflow: scroll;
  position: relative;
  padding-bottom: 150px;
`;

// 商品の絞り込み
export const ListLeftHeadCell = styled.div<{
  invalid?: boolean;
  isBlue?: boolean;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  cursor: pointer !important;
  height: 52px;
  position: -webkit-sticky; /* for Safari */
  display: flex;
  justify-content: space-between;
  color: ${(props) => (props.isBlue ? props.theme.palette.blue : '#9ca3af')};

  display: flex;
  flex-direction: column;

  > span {
    display: flex;
    font-size: 16px;
    padding-top: 15px;
    vertical-align: middle;
    > svg {
      display: inline;
      width: 24px;
      height: 24px;
    }
    .filter_text {
      padding-top: 5px;
      padding-left: 6px;
    }
  }

  :nth-child(2) {
    > div > input {
      cursor: pointer;
    }
  }
`;

export const ProductRow = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  height: 45px;
  align-items: center;
  border-bottom: solid 1px #e5e1dd;
  padding: 0 20px;
  &.header {
    background-color: #f8f7f4;
    cursor: default;
  }
  &.hover-effect {
    &:hover {
      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
      border: solid 1px #e5e1dd;
    }
  }
  div {
    padding: 0 12px;
    display: flex;
    align-items: center;
    /* 複製 */
    &.duplicate {
      width: 70px;
      justify-content: center;
    }
    div {
      /* 画像 */
      &.img {
        position: relative;
        width: 40px;
        padding: 0;
        margin: 4px 0;
        justify-content: center;

        &.header {
          margin-left: 8px;
        }

        &:before {
          content: '';
          display: block;
          padding-top: 100%;
        }

        .product-image {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: contain;
        }
      }
      &.code {
        width: 170px;
        word-break: break-all;
      }
      &.name {
        width: 280px;
        height: 19px;
        justify-content: space-between;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }
      &.name-abbreviation {
        width: 200px;
        height: 19px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }
      &.is-discontinued-hidden {
        width: 100px;
        justify-content: center;
      }
      &.jan-code {
        width: 180px;
      }
      &.confirmation-title {
        width: 100px;
        font-size: 14px;
        &--pencil {
          width: 60px;
          /* padding-right: 45px; */
          justify-content: center;
        }
      }
      &.confirmation {
        width: 100px;
        font-size: 14px;
        padding-left: 35px;
        &--pencil {
          width: 60px;
          justify-content: center;
        }
      }
      &.appreciation-period-days {
        width: 100px;
        text-align: center;
        justify-content: center;
        &.header {
          font-size: 14px;
          white-space: pre-wrap;
        }
      }
    }
  }
`;

export const HeaderName = styled.div<{ isBlue: boolean }>`
  color: ${(props) => (props.isBlue ? props.theme.palette.blue : '')};
  width: 280px;
  display: flex;
  cursor: pointer;
`;

export const CircularIconWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
