import * as S from './SelectedTagList.styles';
import { ReactComponent as CircleCloseBlueIcon } from '@assets/icons/mobile/circle_close_blue.svg';
import { ReactComponent as PlusBlueIcon } from '@assets/icons/mobile/plus_blue.svg';
import { ReactComponent as TagBlueIcon } from '@assets/icons/mobile/tag_blue.svg';
import type { Tag } from '@lib/common/type';

type Props = {
  tags: Tag[];
  setOpenTagModal: () => void;
  clearAll: () => void;
  clear: (id: number) => void;
};

const SelectedTagList = ({ tags, setOpenTagModal, clear }: Props) => (
  <>
    {tags.map((tag) => (
      <S.TagItem key={tag.id}>
        <S.ListItemInner>
          <S.TagDeleteButton onClick={() => clear(tag.id)}>
            <CircleCloseBlueIcon />
          </S.TagDeleteButton>
          <S.TagName>{tag.name}</S.TagName>
        </S.ListItemInner>
      </S.TagItem>
    ))}
    <S.AddTagLink onClick={() => setOpenTagModal()}>
      <S.ListItemInner isAddTag>
        <S.PlusIcon>
          <PlusBlueIcon />
        </S.PlusIcon>
        <S.TagIcon>
          <TagBlueIcon />
        </S.TagIcon>
      </S.ListItemInner>
    </S.AddTagLink>
  </>
);
export default SelectedTagList;
